import React, { Component } from 'react';
import { View, Text, TextInput, ScrollView, Platform, Dimensions, Image, Button } from 'react-native';
import moment from 'moment';
import styles from '../../style/orderdetail';
import globalStyles from '../../style/global';
import { TouchableOpacity } from 'react-native-gesture-handler';
import downArrow from '../../../assets/down_arrow.png';
// import  '../../../css/gp_icons';
// import style from '../../../css/gp_icons';
import upArrow from '../../../assets/up_arrow.png';
// import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import datestyle from'react-datetime-picker/dist/DateTimePicker.css'
// import DatePicker from 'react-datepicker'
export default class InformationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      lessMore: false,
      value: new Date(),
      // isDatePickerVisible: false,
      // pickedDate:new Date(),
      pickedDate: new Date(),
      //pickedDate:new Date(),
      startDate: "",
    };
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  contentLess = () => {
    this.setState({ lessMore: !this.state.lessMore })
  }
  componentDidMount() {
    // console.log("basic info", this.props.basicinfo.order_placed_time)
    window.addEventListener('resize', this.updateDimensions)
    //  this.setState({pickedDate:this.props.basicinfo.order_placed_time})
    // console.log("chnage date formate", moment(this.props.basicinfo.order_placed_time).format('ddd MMM DD YYYY HH:mm:ss'))
    // this.setState({pickedDate:moment(this.props.basicinfo.order_placed_time).format('ddd MMM DD YYYY HH:mm:ss')})
    this.setState({ pickedDate: new Date(this.props.basicinfo.order_placed_time) })
    // console.log("Cgheck new date", new Date(this.props.basicinfo.order_placed_time));
  }
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions)
  }

  setDate = (date) => {
    // console.log("change date", date)
    this.setState({ pickedDate: date })
    this.props.handleChange('order_placed_time', date)
  }

  render() {
    let basicinfo = this.props && this.props.basicinfo
    // console.log("basic", basicinfo);
    return (
      <ScrollView style={styles.itemDetailContainer}>

        <View
          style={{
            backgroundColor: "#000",
            height: 20,
            width: "100%",
            shadowColor: "#000",
            shadowOffset: {
              width: 1,
              height: 10,
            },
            shadowOpacity: 3,
            shadowRadius: 10,
            elevation: 5,
          }}
        ></View>
        <View style={{}}>
          {basicinfo?.increment_id !== '' ? (
            <Text
              style={{
                
                fontSize: 22,
                fontWeight: "bold",
                paddingHorizontal: 15,
                fontFamily: "Poppins_700Bold",
                "background": "-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)",  "WebkitTextFillColor": "transparent","webkitBackgroundClip": "text",
              }}
            >
              Order # {basicinfo.increment_id}
            </Text>
          ) : (
            <Text
              style={{
                color: "#67869B",
                fontSize: 22,
                fontWeight: "bold",
                paddingHorizontal: 15,
                fontFamily: "Poppins_700Bold",
              }}
            >
              no id
            </Text>
          )}
        </View>
        <View
          style={{
            alignSelf: "flex-end",
            justifyContent: "center",
            alignItems: "center",

            flexDirection: "row",
            // borderWidth: 1
          }}
         > 

          <TouchableOpacity
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#336599",
              height: 30,
              shadowColor: "#2bfac3",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
              // box-shadow: rgb(43 250 195) 0px 1px 3px;
            }}
            onPress={() => this.props.updateBasicInfo()}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Save & Close
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props.back()}
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#494848",
              height: 30,
              shadowColor: "#e853fa",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
            }}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
        {this.state.windowWidth >= 900 ?
          <>
            {
              basicinfo &&
              <>
                <View style={{
                  // overflow: "scroll",
                  paddingHorizontal: "1%",
                  backgroundColor: "rgba(104, 135, 156, 0.4)",
                  borderRadius: 10,
                  marginHorizontal: "1%",
                  marginTop: 20,
                  marginBottom: 10,

                  // borderWidth: 1,
                  // borderColor: "#50708b",
                  shadowColor: "#50708b",
                  shadowOffset: { width: 1, height: 1 },
                  shadowRadius: 10,
                }}>
                  <View style={globalStyles.m_10, { flexDirection: 'row' }}>
                    <View style= {{ width: '50%' }}>
                      <Text style={globalStyles.py_10, { fontFamily: 'Poppins_600SemiBold',color: "#dbdada", fontSize: 20, marginTop: 10 }}>Order Number</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, { backgroundColor: "#dbdada", width: '90%' }]}
                        onChangeText={(text) => this.props.handleChange("increment_id", text)}
                        value={basicinfo.increment_id} />
                    </View>
                    {/* <View style={{backgroundColor:"red",height:20,alignItems: "center",justifyContent: "center"}}>
                    </View> */}
                    <View  style={{ width: '50%' }}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20, marginTop: 10 }}>Scanning Completed</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width:'95%' }]}
                        onChangeText={(text) => this.props.handleChange("scan_start_time", text)}
                        // value={basicinfo.scan_start_time} 
                        value={moment(basicinfo.scan_start_time).format('MMMM Do YYYY, h:mm:ss a')} />
                    </View>
                  </View>
                  {/* End here */}
                  {/* second row */}

                  <View style={globalStyles.m_10, { flexDirection: 'row', zIndex: '9', alignItems: 'center', justifyContent: 'space-between' }}>
                    <View style= {{ width: '47%' }}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Order Date</Text>
                      <View style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada", width: '95%', padding: '6px'}]}>
                        {/* <DatePicker  
                        onChange={(date) => {this.setDate(date)}}
                        value={this.state.pickedDate} 
                        isClockOpen={true}
                        showTimeSelect={true}
                        timeFormat="HH:mm"
                      /> */}
                      
                      <DateTimePicker
                       style={{datestyle}}
                       onChange={(date) => {this.setDate(date)}}
                       value={this.state.pickedDate}
                       amPmAriaLabel={"Select AM/PM"}
                       format="MM-dd-yyyy h:mm aa"
                      />
                  </View>
                </View>
                    <View style={{ width: '50%' }}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Source ID</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada", width: '95%' }]}
                        onChangeText={(text) => this.props.handleChange("store_id", text)}
                        value={basicinfo.store_id} />
                    </View>
                  </View>
                  {/* end here */}
                  {/* Third Block */}
                  <View style={globalStyles.m_10, { flexDirection: 'row' }}>
                  <View style={{width: '50%'}}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Customer Order Data 1</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width: '90%' }]}
                        onChangeText={(text) => this.props.handleChange("custom_field_one", text)}
                        value={basicinfo.custom_field_one}
                      />
                    </View>
                    <View style={{ width: '50%' }}>
                      <Text style={globalStyles.py_10, { fontFamily: 'Poppins_600SemiBold',color: "#dbdada", fontSize: 20 }}>Customer Order Data 2</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada" ,width: '95%'}]}
                        onChangeText={(text) => this.props.handleChange("custom_field_two", text)}
                        value={basicinfo.custom_field_two}
                      />
                    </View>
                  </View>
                  {/* End third block */}
                  <Text style={{fontFamily: 'Poppins_600SemiBold', textAlign: "center", color: "#dbdada", fontSize: 20, marginTop: 10 }}>Customer Details</Text>
                  <View style={globalStyles.m_10, { flexDirection: 'row', marginTop: 20 }}>
                    <View style= {{ width: '50%' }}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>First Name</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada" ,width:'90%'}]}
                        onChangeText={(text) => this.props.handleChange("firstname", text)}
                        value={basicinfo.firstname}
                      />
                    </View>
                    <View style={{ width: '50%' }}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Last Name</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width: '95%' }]}
                        onChangeText={(text) => this.props.handleChange("lastname", text)}
                        value={basicinfo.lastname}
                      />
                    </View>

                  </View>
                  {/* Fourth block */}
                  <View style={globalStyles.m_10, { flexDirection: 'row' }}>
                    <View style={{width:'50%'}}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Email</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width:'90%' }]}
                        onChangeText={(text) => this.props.handleChange("email", text)}
                        value={basicinfo.email}
                      />
                    </View>
                    <View style={{ width:'50%' }}>
                      <Text style={globalStyles.py_10, { fontFamily: 'Poppins_600SemiBold',color: "#dbdada", fontSize: 20 }}>Company</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width: '95%' }]}
                        onChangeText={(text) => this.props.handleChange("company", text)}
                        value={basicinfo.company}
                      />
                    </View>
                  </View>
                  {/* end fourth */}
                  {/* fifth block */}
                  <View style={globalStyles.m_10, { flexDirection: 'row' }}>
                    <View style={{width: '50%'}}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>City</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada" ,width:'90%'}]}
                        onChangeText={(text) => this.props.handleChange("city", text)}
                        value={basicinfo.city}
                      />
                    </View>
                    <View style={{ width:'50%' }}>
                      <Text style={globalStyles.py_10, { fontFamily: 'Poppins_600SemiBold',color: "#dbdada", fontSize: 20 }}>State</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width:'95%' }]}
                        onChangeText={(text) => this.props.handleChange("state", text)}
                        value={basicinfo.state}
                      />
                    </View>
                  </View>
                  {/* end fifth */}
                  {/* six block start */}
                  <View style={globalStyles.m_10, { flexDirection: 'row' }}>
                    <View style={{ width:'50%'}}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Address 1</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width: '90%' }]}
                        onChangeText={(text) => this.props.handleChange("address_1", text)}
                        value={basicinfo.address_1}
                      />
                    </View>
                    <View style={{ width: '50%' }}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Address 2</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada" ,width:'95%'}]}
                        onChangeText={(text) => this.props.handleChange("address_2", text)}
                        value={basicinfo.address_2}
                      />
                    </View>
                  </View>
                  {/* end six block */}
                  {/* seven block start */}
                  <View style={globalStyles.m_10, { flexDirection: 'row' }}>
                    <View style={{width: '50%'}}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Postal Code</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width: '90%' }]}
                        onChangeText={(text) => this.props.handleChange("postcode", text)}
                        value={basicinfo.postcode}
                      />
                    </View>
                    <View style={{ width: '50%'}}>
                      <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Country</Text>
                      <TextInput
                        style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada",width: '95%' }]}
                        onChangeText={(text) => this.props.handleChange("country", text)}
                        value={basicinfo.country}
                      />
                    </View>
                  </View>
                  {/* end seven block */}

                </View>
              </>
            }
          </>
          :
          <>
            <View style={{
              // overflow: "scroll",
              paddingHorizontal: "1%",
              backgroundColor: "rgba(104, 135, 156, 0.4)",
              borderRadius: 10,
              marginHorizontal: "1%",
              marginTop: 20,
              marginBottom: 10,
              position: "relative",
              zIndex:9,

              // borderWidth: 1,
              // borderColor: "#50708b",
              shadowColor: "#50708b",
              shadowOffset: { width: 1, height: 1 },
              shadowRadius: 10,
            }}>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20, marginTop: 10 }}>Order Number</Text>
                <TextInput
                  style={[styles.roundedBox, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("increment_id", text)}
                  value={basicinfo.increment_id} />
              </View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20, marginTop: 10 }}>Scanning Completed</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("scan_start_time", text)}
                  value={moment(basicinfo.scan_start_time).format('MMMM Do YYYY, h:mm:ss a')} />
              </View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20, marginTop: 10 }}>Order Date</Text>
                <View style={[styles.roundedBox, styles.inputBoxWidth, globalStyles.bgWhite, { backgroundColor: "#dbdada", width: '95%', padding: '6px' }]}>
                  <DateTimePicker

                    onChange={(date) => { this.setDate(date) }}
                    value={this.state.pickedDate}
                    amPmAriaLabel={"Select AM/PM"}
                    format="MM-dd-yyyy h:mm aa"
                  />
                </View>
              </View>
              {/* <View>
                <Text style={globalStyles.py_10, { color: "#dbdada", fontSize: 20 }}>Order Date</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.setState({ text })}
                  value={moment(basicinfo.order_placed_time).format('MMMM Do YYYY, h:mm:ss a')} />
                    
              </View> */}

            </View>
            <View style={{
              // overflow: "scroll",
              paddingHorizontal: "1%",
              backgroundColor: "rgba(104, 135, 156, 0.4)",
              borderRadius: 10,
              marginHorizontal: "1%",
              marginTop: 20,
              marginBottom: 10,

              // borderWidth: 1,
              // borderColor: "#50708b",
              shadowColor: "#50708b",
              shadowOffset: { width: 1, height: 1 },
              shadowRadius: 10,
            }}>
              <View
                style={{
                  alignSelf: "flex-end",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  // borderWidth: 1
                }}
              >
                <TouchableOpacity onPress={() => this.contentLess()}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ color: 'white', fontSize: 20, marginRight: 10,fontFamily: 'Poppins_600SemiBold', }}>{this.state.lessMore ? 'less' : 'More'}</Text>
                    <Image style={{ width: 25, height: 20 }} source={this.state.lessMore ? upArrow : downArrow} />
                  </View>
                </TouchableOpacity>
              </View>
              {/* <View>
                <View>
                  <Button onPress={this.showDatepicker} title="Show date picker!" />
                </View>
                {
                  this.show && (
                    <DateTimePicker
                      testID="dateTimePicker"
                      timeZoneOffsetInMinutes={0}
                      value={this.state.date}
                      mode={this.state.mod}
                      is24Hour={true}
                      display="default"
                      onChange={onChange}
                    />
                  )
                }
              </View > */}
              {this.state.lessMore &&
                <View>
                  <View>
                    <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Source ID</Text>
                    <TextInput
                      style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                      onChangeText={(text) => this.props.handleChange("store_id", text)}
                      value={basicinfo.store_id} />
                  </View>
                  <View>
                    <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Customer Order Data 1</Text>
                    <TextInput
                      style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                      onChangeText={(text) => this.props.handleChange("custom_field_one", text)}
                      value={basicinfo.custom_field_one}
                    />
                  </View>
                  <View>
                    <Text style={globalStyles.py_10, { fontFamily: 'Poppins_600SemiBold',color: "#dbdada", fontSize: 20 }}>Customer Order Data 2</Text>
                    <TextInput
                      style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                      onChangeText={(text) => this.props.handleChange("custom_field_two", text)}
                      value={basicinfo.custom_field_two}
                    />
                  </View>
                </View>
              }
            </View>
            <View style={{
              // overflow: "scroll",
              paddingHorizontal: "1%",
              backgroundColor: "rgba(104, 135, 156, 0.4)",
              borderRadius: 10,
              marginHorizontal: "1%",
              marginTop: 20,
              marginBottom: 10,

              // borderWidth: 1,
              // borderColor: "#50708b",
              shadowColor: "#50708b",
              shadowOffset: { width: 1, height: 1 },
              shadowRadius: 10,
            }}>
              <View><Text style={{fontFamily: 'Poppins_600SemiBold', textAlign: "center", color: "#dbdada", fontSize: 20, marginTop: 10 }}>Customer Details</Text></View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20,}}>First Name</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("firstname", text)}
                  value={basicinfo.firstname}
                />
              </View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Last Name</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("lastname", text)}
                  value={basicinfo.lastname}
                />
              </View>

              <View>
                <Text style={globalStyles.py_10, { fontFamily: 'Poppins_600SemiBold',color: "#dbdada", fontSize: 20 }}>Email</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("email", text)}
                  value={basicinfo.email}
                />
              </View>
              <View >
                <Text style={globalStyles.py_10, { fontFamily: 'Poppins_600SemiBold',color: "#dbdada", fontSize: 20 }}>Company</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("company", text)}
                  value={basicinfo.company}
                />
              </View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>City</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("city", text)}
                  value={basicinfo.city}
                />
              </View>
              <View>
                <Text style={globalStyles.py_10, { fontFamily: 'Poppins_600SemiBold',color: "#dbdada", fontSize: 20 }}>State</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("state", text)}
                  value={basicinfo.state}
                />
              </View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Address 1</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("address_1", text)}
                  value={basicinfo.address_1}
                />
              </View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Address 2</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("address_2", text)}
                  value={basicinfo.address_2}
                />
              </View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Postal Code</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("postcode", text)}
                  value={basicinfo.postcode}
                />
              </View>
              <View>
                <Text style={globalStyles.py_10, {fontFamily: 'Poppins_600SemiBold', color: "#dbdada", fontSize: 20 }}>Country</Text>
                <TextInput
                  style={[styles.roundedBox, globalStyles.bgWhite, { backgroundColor: "#dbdada" }]}
                  onChangeText={(text) => this.props.handleChange("country", text)}
                  value={basicinfo.country}
                />
              </View>
            </View>
          </>
        }

        {/* for save and close button */}
        <View
          style={{
            alignSelf: "flex-end",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 40,
            flexDirection: "row",
            // borderWidth: 1
          }}
        >
{/* 
          <TouchableOpacity
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#336599",
              height: 30,
              shadowColor: "#2bfac3",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
              // box-shadow: rgb(43 250 195) 0px 1px 3px;
            }}
            onPress={() => this.props.updateBasicInfo()}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Save & Close
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props.back()}
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#494848",
              height: 30,
              shadowColor: "#e853fa",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
            }}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity> */}
        </View>
      </ScrollView>

    );

  }
}
