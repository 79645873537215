
import React, { Component } from 'react';
import { View, Text, TextInput, ImageBackground, Image, Dimensions, TouchableOpacity, Platform, ScrollView } from 'react-native';
import placeholder from "../../../assets/placeholder.png";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

let update = false
export default class ScanImageComponent extends React.Component{
    constructor(props){
        super(props)
        this.state={
            Imageshow:[]
        }
    }
    componentDidUpdate(preProp,presta){
        // update = true
        // console.log("update pros here---->>",preProp,presta,this.props)
        if(update === true){
            update = false
            this.checkImage(this.props.img)
        }
    }
    // UNSAFE_componentWillReceiveProps(nextprops){
    //     console.log("next--props here",nextprops)
    //     this.checkImage(nextprops)
    // }
    componentDidMount(){
        // update = true
        // console.log("here props of scanned images",this.props)
        this.checkImage(this.props.img)
    }
    componentWillUnmount(){
        
        this.setState({Imageshow:[]})
        // console.log("component will mount call")
    }
    
    checkImage=async(img)=>{
    //   const access_token = await AsyncStorage.getItem("access_token")        
    axios({
        method: 'get',
        url: `${img}`, 
      }).then((res)=>{
            let imgs = {
                img : res.config.url,
                status : res.status
            }
            
            if(this.state.Imageshow.includes(imgs)){
                update = false
            }else{
                this.setState({Imageshow : [this.state.Imageshow,imgs]})
                // imgarr.push(imgs)
                update = false
            }
            // if(res.status == 404){

                
                   
            //     imgarr.push(<Image style={{ height: "90%", width: "90%", resizeMode: 'contain', borderRadius: 10 }} source={placeholder}/>)
    
                    
            // }else{
            //     console.log("000000",res.url)
            //     imgarr.push(<Image style={{ height: "90%", width: "90%", resizeMode: 'contain', borderRadius: 10 }} source={{uri : res.url}}/>)

            // }
            // console.log("--------------->>>>>>res",res)
        }).catch((err)=>{
            let imgs = {
                img : img,
                status : 200
            }
            if(this.state.Imageshow.includes(imgs)){
                update = false
            }else{
                this.setState({Imageshow : [this.state.Imageshow,imgs]})
                // imgarr.push(imgs)
                update = false
            }
            console.clear()
        })
        update = true
        
    }
    render(){

        // console.log("stagteee",this.state.Imageshow,this.ss)
        return(
            // imgarr.map((imgg)=>{
            //     return imgg
            // })
            
             this.state.Imageshow.map((im,index)=>{
                 
                 if(im.status === 404){

                     return(
                         <Image style={{ height: "100%", width: "90%",marginTop:-50,resizeMode: 'contain', borderRadius: 10 }} source={placeholder}/>
                     )
                 }else{
                    return(
                        <Image style={{ height: "100%", width: "90%",marginTop:-50,resizeMode: 'contain', borderRadius: 10 }} source={{uri : im.img}}/>
                    ) 
                 }
             })

            
        )
    }
    
}