import React, { Component } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Dimensions,
} from "react-native";
import styles from "../../style/orderdetail";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus ,faReply} from '@fortawesome/free-solid-svg-icons'
import { UpdateOrderQuantity } from "../../actions/orderActions";
import restart from "../../../assets/1024x1024.png";
import { FlatGrid, SectionGrid } from "react-native-super-grid";
import { Entypo } from "@expo/vector-icons";
import { Poppins_300Light } from "@expo-google-fonts/poppins";
import { isLoaded } from "expo-font";
import { LinearGradient } from "expo-linear-gradient";
// import { faPlus,faReply} from '@fortawesome/free-solid-svg-icons'
// const windowWidth = Dimensions.get("window").width;
import { FlatList } from "react-native";
import AddItemToOrder from "./add_item_to_order";
// import ModalDropdown from 'react-native-modal-dropdown';

class ItemsDetail extends Component {
  constructor(props) {
    // let self = this;ƒ
    super(props);
    this.state = {
      items: [],
      test: [],
      unTouch: true,
      loading: true,
      editable: Number,
      instruction: false,
      windowWidth: 0,
      // selectedId
      order_id: "",
      addItem: false,
      itemadd: false,
    };
    window.addEventListener("resize", this.update);
  }
  componentDidUpdate() {
    // console.log("item detail props", this.props);

    // if (
    //   this.state.items !=
    //   this.props?.items?.items &&
    //   this.state.order_id !=
    //     this.props?.items?.basicinfo?.increment_id
    // ) {
    //   this.setState({
    //     items: this.props.items?.items,
    //     order_id: this.props.items?.basicinfo?.increment_id,
    //   });
    // }

    //     if(this.state.items?.length) {
    //       console.log("aaaaaàaa", this.state.items)
    //  let sorted = this.state.items.sort((a, b) => Date.parse(a.iteminfo?.updated_at) - Date.parse(b.iteminfo?.updated_at))
    //  console.log("bbbbbbbbbbb",sorted)
    // }

    if (
      this.state.items != this.props.propsData?.order?.order?.items ||
      this.state.order_id !=
      this.props.propsData?.order?.order?.basicinfo?.increment_id
    ) {
      this.setState({
        items: this.props.items?.items,
        order_id: this.props.propsData?.order?.order?.basicinfo?.increment_id,
        itemadd: false,
      });
    }
  }
  componentDidMount() {
    this.secondTextInput = React.createRef();
    // console.log("item deta", this.props);

    if (
      this.props.propsData.order &&
      this.props.propsData.order?.order &&
      this.props.propsData.order?.order?.items &&
      this.props.propsData.order?.order?.basicinfo &&
      this.props.propsData.order?.order?.basicinfo?.length &&
      this.props.propsData.order?.order?.items.length
    ) {
      this.setState({
        items: this.props.propsData.order.order.items,
        order_id: this.props.propsData?.order?.order?.basicinfo?.increment_id,
      });
      if (this.state.items.length != 0) {
        this.setState({ loading: false });
      }
    }

    // if (this.props.propsData.order?.order?.items?.length != this.state.items) {
    //   this.setState({
    //     items: this.props.propsData.order?.order?.items,
    //   });
    //   console.log("I was here")
    // }
    // if (
    //   this.props.items &&
    //     this.props.items?.items &&
    //     this.props.items?.basicinfo &&
    //     this.props.items?.items?.length &&
    //     this.props.items?.basicinfo?.increment_id.length
    //      ||
    //   this.state.items != this.props.items.items
    // ) {
    //   // console.log("*********************");
    //   // console.log("this.props.items.items", this.props.items?.basicinfo?.increment_id);
    //   // console.log("*********************");
    //   this.setState({
    //     items: this.props.items?.items,
    //     order_id: this.props.items?.basicinfo?.increment_id,
    //   });
    //   // this.setState({ loading: false });
    //   // setState(this.props.items.items);
    //   // setLoad(true);
    // }
    this.update();
  }
  update = () => {
    // console.log("avc")
    this.setState({
      windowWidth: window.innerWidth,
    });
  };
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  // changeEdit = () => {
  //   this.setState({ editable:  });
  // };

  render() {
    // let content = this.props.items && this.props.items.items;
    // console.log("items from details", this.state.items[0].iteminfo.order_id);
    // console.log("items from details", this.state.items);
    // if(this.state?.items?.length)
    // {
    //   console.log("items from details",  this.state?.items[0]?.iteminfo?.order_id);
    // }
    // console.log("items from details", this.props.items.items);

    return (
      <ScrollView
        style={styles.itemDetailContainer}
        horizontal={false}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        {Platform.OS === "web" ? (
          // <React.Fragment>
          //   <View style={{ flex: 1, flexDirection: "row" }}>
          //     <View
          //       style={{
          //         flex: 2,
          //         flexDirection: "row",
          //         justifyContent: "flex-start",
          //         margin: 5,
          //         marginLeft: 10,
          //       }}
          //     >
          //       <TouchableOpacity
          //         onPress={() => this.props.addOrderItem(items)}
          //         style={{
          //           marginRight: 10,
          //           borderWidth: 1,
          //           borderStyle: "solid",
          //           borderColor: "#336597",
          //           borderRadius: 30,
          //           backgroundColor: "#336599",
          //         }}
          //       >
          //         <Text
          //           style={{ color: "white", padding: 10, fontWeight: "bold" }}
          //         >
          //           Add Item
          //         </Text>
          //       </TouchableOpacity>
          //       <TouchableOpacity
          //         onPress={() => this.props.removeItems()}
          //         style={
          //           this.props.removeIds && this.props.removeIds.length > 0
          //             ? {
          //                 marginRight: 10,
          //                 borderWidth: 1,
          //                 borderStyle: "solid",
          //                 borderColor: "#336597",
          //                 borderRadius: 30,
          //                 backgroundColor: "#961F1F",
          //               }
          //             : {
          //                 marginRight: 10,
          //                 borderWidth: 1,
          //                 borderStyle: "solid",
          //                 borderColor: "#336597",
          //                 borderRadius: 30,
          //                 backgroundColor: "#c48787",
          //               }
          //         }
          //       >
          //         <Text
          //           style={{ color: "white", padding: 10, fontWeight: "bold" }}
          //         >
          //           Remove selected items
          //         </Text>
          //       </TouchableOpacity>
          //     </View>
          //     <View
          //       style={{
          //         flex: 2,
          //         flexDirection: "row",
          //         justifyContent: "flex-end",
          //         margin: 5,
          //       }}
          //     >
          //       <TouchableOpacity
          //         onPress={() => this.props.updateBasicInfo()}
          //         style={{
          //           marginRight: 10,
          //           borderWidth: 1,
          //           borderStyle: "solid",
          //           borderColor: "#336597",
          //           borderRadius: 30,
          //           backgroundColor: "#494848",
          //           height: 40,
          //         }}
          //       >
          //         <Text
          //           style={{ color: "white", padding: 10, fontWeight: "bold" }}
          //         >
          //           Cancel
          //         </Text>
          //       </TouchableOpacity>
          //       <TouchableOpacity
          //         style={{
          //           marginRight: 10,
          //           borderWidth: 1,
          //           borderStyle: "solid",
          //           borderColor: "#336597",
          //           borderRadius: 30,
          //           backgroundColor: "#336599",
          //           height: 40,
          //         }}
          //         onPress={() => this.props.updateBasicInfo()}
          //       >
          //         <Text
          //           style={{ color: "white", padding: 10, fontWeight: "bold" }}
          //         >
          //           Save & Close
          //         </Text>
          //       </TouchableOpacity>
          //     </View>
          //   </View>
          //   {items &&
          //     items.length > 0 &&
          //     items.map((item, index) => {
          //       return (
          //         <View
          //           key={index}
          //           style={
          //             this.props.removeIds.includes(item.iteminfo.id)
          //               ? [styles.itemBox, { backgroundColor: "#799cbf" }]
          //               : styles.itemBox
          //           }
          //         >
          //           <View style={styles.labelBox}>
          //             <Text style={styles.label}>Name</Text>
          //             <TouchableOpacity
          //               style={styles.value}
          //               onPress={() => this.props.removeOrderItem(item)}
          //             >
          //               <Text>{item.productinfo.name}</Text>
          //             </TouchableOpacity>
          //           </View>
          //           <View style={styles.labelBox}>
          //             <Text style={styles.label}>Quantity</Text>
          //             <TextInput
          //               style={styles.value}
          //               value={item.iteminfo.qty}
          //               onChangeText={(text) =>
          //                 this.props.handleChange(text, item)
          //               }
          //               onBlur={() => this.props.updateQuantity(item)}
          //               // onBlur={this.props.updateQuantity(item)}
          //             />
          //           </View>
          //           <View style={styles.labelBox}>
          //             <Text style={styles.label}>Status</Text>
          //             <Text style={styles.value}>
          //               {item.productinfo.status}
          //             </Text>
          //           </View>
          //           <View style={styles.labelBox}>
          //             <Text style={styles.label}>Sku</Text>
          //             <Text style={styles.value}>{item.sku}</Text>
          //           </View>
          //           <View style={styles.labelBox}>
          //             <Text style={styles.label}>Barcode</Text>
          //             <Text style={styles.value}>{item.barcode}</Text>
          //           </View>
          //         </View>
          //       );
          //     })}
          // </React.Fragment>

          <React.Fragment>
            {/* <LinearGradient
              colors={["#000", "#5c778f", "#253c57", "#000"]}
              start={[-0.3, -0.01]}
              style={{
                flex: 1,
                overflow: "scroll",
                // paddingHorizontal: 15,
                paddingBottom: 25,
                // backgroundColor: "#2D455F",
              }}
            > */}

            <View
              style={{
                backgroundColor: "#000",
                height: 20,
                width: "102%",
                shadowColor: "#000",
                shadowOffset: {
                  width: 1,
                  height: 10,
                },
                shadowOpacity: 3,
                shadowRadius: 10,

                elevation: 5,
              }}
            ></View>
            <View style={{}}>
              {this.state.items ? (
                <Text
                  style={{
                 
                    fontSize: 22,
                    fontWeight: "bold",
                    paddingHorizontal: 15,
                    fontFamily: "Poppins_700Bold",
                    "background": "-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)",  "WebkitTextFillColor": "transparent","webkitBackgroundClip": "text",
                  }}
                >
                  Order # {this.state.order_id}
                </Text>
              ) : (
                <Text
                  style={{
                    color: "#67869B",
                    fontSize: 22,
                    fontWeight: "bold",
                    paddingHorizontal: 15,
                    fontFamily: "Poppins_700Bold",
                  }}
                >
                  no id
                </Text>
              )}
              {/* <View style={{ flexDirection: "row", alignSelf: "flex-end", marginRight: 10, marginLeft: 10, padding: 10 }}></View> */}
              <View style={{
                alignSelf: "flex-end",
                justifyContent: "center",
                alignItems: "center",

                flexDirection: "row",
              }}>
                <TouchableOpacity
                  style={{
                    marginRight: 10,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#fff",
                    borderRadius: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#336599",
                    height: 30,
                    shadowColor: "#2bfac3",
                    shadowOffset: { width: 0, height: 0.5 },
                    shadowRadius: 8,
                    elevation: 5,
                    shadowOpacity: 0.5,
                    // box-shadow: rgb(43 250 195) 0px 1px 3px;
                  }}
                  onPress={() => this.props.updateBasicInfo()}
                >
                  <Text
                    style={{
                      color: "white",
                      paddingHorizontal: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Save & Close
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.props.updateBasicInfo()}
                  style={{
                    marginRight: 10,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#fff",
                    borderRadius: 30,
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#494848",
                    height: 30,
                    shadowColor: "#e853fa",
                    shadowOffset: { width: 0, height: 0.5 },
                    shadowRadius: 8,
                    elevation: 5,
                    shadowOpacity: 0.5,
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      paddingHorizontal: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>

            </View>
            {this.state.itemadd ? (
              <View>
                <AddItemToOrder
                  onchange={() =>
                    this.setState({ itemadd: !this.state.itemadd })
                  }
                  {...this.props}
                />
              </View>
            ) : (
              <View
                // colors={["#22292C", "#68879C", "#68879C", "#22292C"]}
                // start={[-0.3, 0.01]}
                style={{
                  // overflow: "scroll",
                  paddingHorizontal: "1%",
                  backgroundColor: "rgba(104, 135, 156, 0.4)",
                  borderRadius: 10,
                  marginHorizontal: "1%",
                  marginTop: 20,
                  marginBottom: 10,
                  // borderWidth: 1,
                  // borderColor: "#50708b",
                  shadowColor: "#50708b",
                  shadowOffset: { width: 1, height: 1 },
                  shadowRadius: 10,
                }}
              >
                <View
                  style={{
                    alignSelf: "flex-end",
                    justifyContent: "center",
                    alignItems: "center",

                    flexDirection: "row",
                    // borderWidth: 1
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.props.addOrderItem(this.state.items);
                      this.setState({ itemadd: true });
                    }}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingRight: 30,
                      paddingVertical: 20,
                      // borderWidth: 1
                    }}
                  >
                    {/* <Image
                      style={{ width: 40, height: 40, resizeMode: "contain" }}
                      source={require("../../../assets/plus.png")}
                    /> */}
   <FontAwesomeIcon style={{ color: "white", height: "40", width: "40" }} icon={faPlus}  />
                    <Text
                      style={{
                        fontFamily: "Poppins_800ExtraBold",
                        // fontWeight: "bold",
                        color: "white",
                        fontSize: 16,
                        textShadowColor: "#000",
                        textShadowOffset: { width: 1, height: 1 },
                        textShadowRadius: 5,
                        right:"20%",
                        elevation: 5,
                      }}
                    >
                      Add Items
                    </Text>
                  </TouchableOpacity>
                  {/* <TouchableOpacity
                    style={{
                      marginRight: 10,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#fff",
                      borderRadius: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: "#336599",
                      height: 30,
                      shadowColor: "#2bfac3",
                      shadowOffset: { width: 0, height: 0.5 },
                      shadowRadius: 8,
                      elevation: 5,
                      shadowOpacity: 0.5,
                      // box-shadow: rgb(43 250 195) 0px 1px 3px;
                    }}
                    onPress={() => this.props.updateBasicInfo()}
                  >
                    <Text
                      style={{
                        color: "white",
                        paddingHorizontal: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Save & Close
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => this.props.updateBasicInfo()}
                    style={{
                      marginRight: 10,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#fff",
                      borderRadius: 30,
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: "#494848",
                      height: 30,
                      shadowColor: "#e853fa",
                      shadowOffset: { width: 0, height: 0.5 },
                      shadowRadius: 8,
                      elevation: 5,
                      shadowOpacity: 0.5,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        paddingHorizontal: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Cancel
                    </Text>
                  </TouchableOpacity> */}
                </View>

                {this.state.windowWidth >= 900 ? (
                  <View
                    style={{
                      // overflow: "scroll",
                      flex: 1,
                      // marginHorizontal: 10,
                      backgroundColor: "rgba(15, 39, 58, 0.4)",
                      borderRadius: 15,
                      marginBottom: 50,
                      // opacity: 0.5
                      // width: "100%",
                      height: 100,
                    }}
                  >
                    <View style={[styles.itemContainer]}>
                      <View
                        style={[
                          styles.itemView,
                          {
                            width: "2%",
                            marginHorizontal: "1%",
                            paddingVertical: 15,
                          },
                        ]}
                      ></View>

                      <View
                        style={{
                          flexDirection: "row",
                          // borderBottomWidth: 0.7,
                          // borderColor: "rgba(250, 250, 250, .4)",
                          paddingHorizontal: "1%",
                          width: "96%",
                          justifyContent: "space-around",
                          // backgroundColor: 'aqua',
                          flexWrap: "wrap",
                        }}
                      >
                        <View
                          style={[
                            styles.itemView,
                            {
                              width: "10%",
                            },
                          ]}
                        ></View>
                        <View style={[styles.itemView, { width: "18%" }]}>
                          <Text style={[styles.gridHeader]}>Product Name</Text>
                        </View>

                        <View style={[styles.itemView, { width: "15%" }]}>
                          <Text style={styles.gridHeader}>SKU</Text>
                        </View>

                        <View style={[styles.itemView, { width: "8%" }]}>
                          <Text style={styles.gridHeader}>Status</Text>
                        </View>

                        <View style={[styles.itemView, { width: "15%" }]}>
                          <Text style={styles.gridHeader}>Barcode</Text>
                        </View>

                        <View
                          style={[
                            styles.itemView,
                            {
                              width: "10%",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            },
                          ]}
                        >
                          <Text style={styles.gridHeader}>QTY Ordered</Text>
                        </View>

                        <View
                          style={[
                            styles.itemView,
                            {
                              width: "15%",
                              justifyContent: "center",
                              alignItems: "center",
                            },
                          ]}
                        >
                          <Text style={styles.gridHeader}>Location l</Text>
                        </View>
                        <View
                          style={[
                            styles.itemView,
                            {
                              width: "4%",
                              justifyContent: "center",
                              alignItems: "center",
                            },
                          ]}
                        >
                          <Text style={styles.gridHeader}>QOH</Text>
                        </View>
                      </View>
                    </View>
                    <View>
                      {this.state.items &&
                        this.state.items.length > 0 &&
                        this.state.items.map((item, index) => {
                          return (
                            <View style={[styles.itemContainer]}>
                              <TouchableOpacity
                                onPress={() => {
                                  this.props.removeIds.includes(
                                    item.iteminfo.id
                                  );
                                  this.props.removeOrderItem(item);
                                  this.props.removeItems();
                                  // this.state.items.splice(index, 1);
                                }}
                                style={[
                                  styles.itemView,
                                  {
                                    width: "2%",
                                    marginHorizontal: "1%",
                                  },
                                ]}
                              >
                                <Image
                                  style={{
                                    width: 35,
                                    height: 35,
                                    resizeMode: "stretch",
                                  }}
                                  source={require("../../../assets/cross.png")}
                                />
                              </TouchableOpacity>

                              <View
                                style={{
                                  flexDirection: "row",
                                  borderBottomWidth: 0.7,
                                  borderColor: "rgba(250, 250, 250, .4)",
                                  paddingHorizontal: "1%",
                                  width: "96%",
                                  justifyContent: "space-around",
                                  // backgroundColor: 'aqua',
                                  flexWrap: "wrap",
                                }}
                              >
                                {/* <TouchableOpacity
                                onPress={() => {
                                  this.props.removeIds.includes(
                                    item.iteminfo.id
                                  );
                                  this.props.removeOrderItem(item);
                                  this.props.removeItems();
                                  this.state.items.splice(index, 1);
                                }}
                                style={[
                                  styles.itemView,
                                  {
                                    width: "3%",
                                  },
                                ]}
                              >
                                <Image
                                  style={{
                                    width: 35,
                                    height: 35,
                                    resizeMode: "stretch",
                                  }}
                                  source={require("../../../assets/cross.png")}
                                />
                              </TouchableOpacity> */}
                                <View
                                  style={[
                                    styles.itemView,
                                    {
                                      width: "10%",
                                      marginVertical: 5,
                                    },
                                  ]}
                                >
                                  <Image
                                    style={{
                                      width: 70,
                                      height: 70,
                                      borderRadius: 5,
                                      resizeMode: "contain",
                                    }}
                                    source={{ uri: item.image }}
                                  />
                                </View>
                                <View
                                  style={[styles.itemView, { width: "18%" }]}
                                >
                                  <Text
                                    style={[
                                      styles.itemName,
                                      { color: "#aad9fc" },
                                    ]}
                                  >
                                    {item.productinfo.name}
                                  </Text>
                                </View>
                                <View
                                  style={[styles.itemView, { width: "15%" }]}
                                >
                                  <Text style={styles.itemName}>
                                    {item.sku}
                                  </Text>
                                </View>
                                <View
                                  style={[styles.itemView, { width: "8%" }]}
                                >
                                  {item.productinfo.status === "active" ? (
                                    <Text style={styles.statusActive}>
                                      {this.Capitalize(item.productinfo.status)}
                                    </Text>
                                  ) : (
                                    // <Text>{this.Capitalize(this.state.title)} </Text>
                                    <Text style={styles.statusNew}>
                                      {this.Capitalize(item.productinfo.status)}
                                    </Text>
                                  )}
                                </View>
                                {/* {item.barcode.length >=13 ? ( */}
                                <View
                                  style={[styles.itemView, { width: "15%" }]}
                                >
                                  <Text
                                    ellipsizeMode="tail"
                                    numberOfLines={1}
                                    style={[styles.itemName]}
                                  >
                                    {item.barcode}
                                  </Text>
                                </View>
                                {/* ) : (<View style={[styles.itemView, { width: "15%" }]}>
                                <Text style={[styles.itemName]}>
                                  {item.barcode}
                                </Text>
                              </View>)} */}

                                <View
                                  style={[
                                    styles.itemView,
                                    {
                                      width: "10%",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    },
                                  ]}
                                >
                                  {this.state.editable == index ? (
                                    <TextInput
                                      // placeholder={item.iteminfo.qty}
                                      placeholderTextColor="white"
                                      // onChangeText={() => {}}
                                      keyboardType="numeric"
                                      autoFocus={true}
                                      placeholder={item.iteminfo.qty}
                                      onChangeText={(text) =>
                                        this.props.handleChange(text, item)
                                      }
                                      onBlur={() => {
                                        {
                                          item.iteminfo.qty > 0
                                            ? (this.props.updateQuantity(item),
                                              this.setState({ editable: -1 }))
                                            : (this.props.removeIds.includes(
                                              item.iteminfo.id
                                            ),
                                              this.props.removeOrderItem(item),
                                              this.props.removeItems(),
                                              this.setState({ editable: -1 }));
                                          // this.state.items.splice(index, 1)
                                        }
                                      }}
                                      style={{
                                        width: 25,
                                        height: 25,
                                        fontSize: 19,
                                        color: "white",
                                      }}
                                    />
                                  ) : (
                                    <Text style={styles.itemName}>
                                      {item.iteminfo.qty}
                                    </Text>
                                  )}
                                  <TouchableOpacity
                                    onPress={() => {
                                      this.setState({ editable: index });
                                      // this.changeEdit();
                                    }}
                                  >
                                    <Image
                                      style={{
                                        width: 20,
                                        height: 20,
                                        resizeMode: "contain",
                                      }}
                                      source={require("../../../assets/edit.png")}
                                    />
                                  </TouchableOpacity>
                                </View>

                                <View
                                  style={[
                                    styles.itemView,
                                    {
                                      width: "15%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    },
                                  ]}
                                >
                                  <Text style={styles.itemName}>
                                    {item.location_primary}
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    styles.itemView,
                                    {
                                      width: "4%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    },
                                  ]}
                                >
                                  <Text style={styles.itemName}>
                                    {item.qty_on_hand}
                                  </Text>
                                </View>
                                {/* {this.state.instruction ? (
                                <View
                                  style={[
                                    styles.itemView,
                                    {
                                      width: 130,
                                      justifyContent: "center",
                                      alignItems: "center",
                                    },
                                  ]}
                                >
                                  <Text style={styles.itemName}>
                                    {item.available_inv}
                                  </Text>
                                </View>
                              ) : (
                                <View></View>
                              )} */}
                              </View>
                            </View>
                          );
                        })}
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      // overflow: "scroll",
                      flex: 1,
                      marginHorizontal: 10,
                      backgroundColor: "rgba(15, 39, 58, 0.4)",
                      // backgroundColor: "teal",
                      borderRadius: 15,
                      marginBottom: 50,
                      // paddingLeft: 5,
                      // opacity: 0.5
                      // width: "100%",
                      // height: 100,
                      paddingVertical: 15,
                      paddingLeft: 10,
                      flexWrap: "wrap",
                    }}
                  >
                    {this.state.items &&
                      this.state.items.length > 0 &&
                      this.state.items.map((item, index) => {
                        // console.log(index)
                        return (
                          <View style={[styles.mblItemsView]}>
                            {/* <TouchableOpacity
                          onPress={() => {
                            this.props.removeIds.includes(item.iteminfo.id);
                          }}
                          style={[
                            styles.itemView,
                            {
                              width: "3%",
                              marginHorizontal: 15,
                            },
                          ]}
                        >
                          <Image
                            style={{
                              width: 35,
                              height: 35,
                              resizeMode: "stretch",
                            }}
                            source={require("../../../assets/cross.png")}
                          />
                        </TouchableOpacity> */}
                            <TouchableOpacity
                              onPress={() => {
                                this.props.removeIds.includes(item.iteminfo.id);
                                this.props.removeOrderItem(item);
                                this.props.removeItems();
                                // this.state.items.splice(index, 1);
                              }}
                              style={[
                                styles.itemView,
                                {
                                  width: "3%",
                                  // paddingHorizontal: 2,
                                },
                              ]}
                            >
                              <Image
                                style={{
                                  width: 30,
                                  height: 30,
                                  resizeMode: "contain",
                                }}
                                source={require("../../../assets/cross.png")}
                              />
                            </TouchableOpacity>
                            <View
                              style={{
                                // flexDirection: "row",
                                borderBottomWidth: 0.7,
                                borderColor: "rgba(250, 250, 250, .4)",
                                paddingHorizontal: "2%",
                                width: "95%",
                                justifyContent: "space-around",
                                // backgroundColor: 'blue',
                                flexWrap: "wrap",
                                // paddingVertical: 10,
                              }}
                            >
                              <View
                                style={[
                                  styles.itemView,
                                  { alignSelf: "flex-start" },
                                ]}
                              >
                                <Text
                                  style={[
                                    styles.mblViewName,
                                    { color: "#aad9fc" },
                                  ]}
                                >
                                  {item.productinfo.name}
                                </Text>
                              </View>

                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <View style={{ width: "70%" }}>
                                  <View
                                    style={[
                                      styles.itemView,
                                      { alignSelf: "flex-start" },
                                    ]}
                                  >
                                    <Text style={styles.mblViewName}>
                                      {item.sku}
                                    </Text>
                                  </View>

                                  <View
                                    style={[
                                      styles.itemView,
                                      {
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        alignSelf: "flex-start",
                                      },
                                    ]}
                                  >
                                    <Text style={styles.mblViewName}>QTY </Text>
                                    {this.state.editable == index ? (
                                      <TextInput
                                        placeholder={item.iteminfo.qty}
                                        placeholderTextColor={"white"}
                                        // ref={(input) => { this.secondTextInput = input; }}
                                        autoFocus={true}
                                        keyboardType="numeric"
                                        onChangeText={(text) =>
                                          this.props.handleChange(text, item)
                                        }
                                        onBlur={() => {
                                          {
                                            item.iteminfo.qty > 0
                                              ? this.props.updateQuantity(item)
                                              : (this.props.removeIds.includes(
                                                item.iteminfo.id
                                              ),
                                                this.props.removeOrderItem(
                                                  item
                                                ),
                                                this.props.removeItems(),
                                                this.setState({
                                                  editable: -1,
                                                }));
                                            // this.state.items.splice(index, 1)
                                          }
                                        }}
                                        style={{
                                          width: 25,
                                          height: 25,
                                          color: "white",
                                          fontSize: 19,
                                          borderColor: "white",
                                          borderBottomWidth: 1,
                                          justifyContent: "center",
                                        }}
                                      />
                                    ) : (
                                      <Text style={styles.mblViewName}>
                                        {item.iteminfo.qty}
                                      </Text>
                                    )}
                                    <TouchableOpacity
                                      onPress={() => {
                                        // let self = this;
                                        this.setState({ editable: index });
                                        // this.changeEdit();
                                        // this.secondTextInput.focus();
                                      }}
                                    >
                                      <Image
                                        style={{
                                          width: 20,
                                          height: 20,
                                          resizeMode: "contain",
                                        }}
                                        source={require("../../../assets/edit.png")}
                                      />
                                    </TouchableOpacity>
                                  </View>
                                  <View
                                    style={[
                                      styles.itemView,
                                      { alignSelf: "flex-start" },
                                    ]}
                                  >
                                    {item.productinfo.status === "active" ? (
                                      <Text style={styles.statusActive}>
                                        {this.Capitalize(
                                          item.productinfo.status
                                        )}
                                      </Text>
                                    ) : (
                                      // <Text>{this.Capitalize(this.state.title)} </Text>
                                      <Text style={styles.statusNew}>
                                        {this.Capitalize(
                                          item.productinfo.status
                                        )}
                                      </Text>
                                    )}
                                  </View>
                                </View>
                                <View
                                  style={[
                                    styles.itemView,
                                    {
                                      alignSelf: "flex-end",
                                      paddingHorizontal: "2%",
                                      width: "30%",
                                      alignItems: "flex-end",
                                      paddingVertical: 5,
                                    },
                                  ]}
                                >
                                  {/* <Text style={styles.itemName}>{item.productinfo.name}</Text> */}
                                  <Image
                                    style={{
                                      width: 75,
                                      height: 75,
                                      borderRadius: 7,
                                      resizeMode: "contain",
                                    }}
                                    source={{ uri: item.image }}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>

                          // ): ()}
                        );
                      })}
                  </View>
                )}
              </View>
            )}

            {/* </LinearGradient> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View
                style={{
                  flex: 2,
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  margin: 5,
                  marginLeft: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.props.addOrderItem(items)}
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#336597",
                    backgroundColor: "#336599",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      padding: 4,
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Add Item
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.props.removeItems()}
                  style={
                    this.props.removeIds && this.props.removeIds.length > 0
                      ? {
                        marginRight: 2,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#336597",
                        backgroundColor: "#961F1F",
                        justifyContent: "center",
                      }
                      : {
                        marginRight: 2,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#336597",
                        backgroundColor: "#c48787",
                        justifyContent: "center",
                      }
                  }
                >
                  <Text
                    style={{
                      color: "white",
                      padding: 4,
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Remove selected
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flex: 2,
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  margin: 5,
                }}
              >
                <TouchableOpacity
                  onPress={() => this.props.updateBasicInfo()}
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#336597",
                    backgroundColor: "#494848",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      padding: 4,
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    marginRight: 10,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#336597",
                    backgroundColor: "#336599",
                    justifyContent: "center",
                  }}
                  onPress={() => this.props.updateBasicInfo()}
                >
                  <Text
                    style={{
                      color: "white",
                      padding: 4,
                      fontSize: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Save & Close
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            {items &&
              items.length > 0 &&
              items.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={
                      this.props.removeIds.includes(item.iteminfo.id) && {
                        backgroundColor: "#799cbf",
                      }
                    }
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: "#000",
                        borderRadius: 10,
                        width: "96%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: 5,
                      }}
                    >
                      <View style={{ width: "20%" }}>
                        <TouchableOpacity
                          onPress={() => this.props.removeOrderItem(item)}
                        >
                          <Image
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              resizeMode: "contain",
                              marginBottom: 2,
                            }}
                            source={{
                              uri: item.image !== null ? item.image : "",
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                      <View style={{ width: "80%" }}>
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            alignItem: "center",
                          }}
                        >
                          <View style={{ flex: 2, flexDirection: "row" }}>
                            <Text style={{ padding: 5 }}>QTY</Text>
                            <TextInput
                              keyboardType="numeric"
                              style={{
                                borderWidth: 1,
                                borderRadius: 10,
                                width: 50,
                                paddingLeft: 10,
                                paddingRight: 5,
                              }}
                              value={
                                item.iteminfo.qty &&
                                JSON.stringify(item.iteminfo.qty)
                              }
                              onChangeText={(text) =>
                                this.props.handleChange(text, item)
                              }
                              onBlur={() => this.props.updateQuantity(item)}
                            />
                          </View>
                          <View style={{ flex: 4, justifyContent: "center" }}>
                            <TouchableOpacity
                              style={styles.value}
                              onPress={() => this.props.removeOrderItem(item)}
                            >
                              <Text numberOfLines={1} ellipsizeMode="tail">
                                {" "}
                                {item.productinfo.name}{" "}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>

                        <TouchableOpacity
                          onPress={() => this.props.removeOrderItem(item)}
                        >
                          <View
                            style={{
                              flex: 1,
                              flexDirection: "row",
                              alignItem: "center",
                            }}
                          >
                            <View style={{ flex: 2, flexDirection: "row" }}>
                              <Text
                                numberOfLines={1}
                                ellipsizeMode="tail"
                                style={{ padding: 5 }}
                              >
                                {item.sku}
                              </Text>
                            </View>
                            <View style={{ flex: 2, flexDirection: "row" }}>
                              {item.productinfo.custom_product_1 !== "" && (
                                <Text
                                  numberOfLines={1}
                                  ellipsizeMode="tail"
                                  style={{ padding: 5 }}
                                >
                                  C1: {item.productinfo.custom_product_1}
                                </Text>
                              )}
                            </View>
                            <View style={{ flex: 4, justifyContent: "center" }}>
                              <Text numberOfLines={1} ellipsizeMode="tail">
                                {" "}
                                {item.barcode}{" "}
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                );
              })}
          </React.Fragment>
        )}
      </ScrollView>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state from item_detail", state);
  const propsData = state;
  return {
    propsData,
  };
};

const mapDispatchToProps = {
  UpdateOrderQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsDetail);
