import { StyleSheet } from "react-native";
// import { Poppins_700Bold } from "@expo-google-fonts/poppins";
import { fontFamily } from "../helpers/fontFamily";

import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";

const styles = StyleSheet.create({
  tabLabel: {
    paddingHorizontal: 5,
    textAlign: "center",
    // fontWeight: "bold",
    color: "white",
    fontFamily: 'Poppins_700Bold',
    fontSize: 19,
    // borderWidth: 1
  },
  itemDetailContainer: {
    width: "100%",
    marginBottom: 115,
    // overflow: "scroll",
    paddingLeft:20,
    paddingRight:20
  },
  itemBox: {
    margin: 10,
    borderWidth: 1,
  },
  labelBox: {
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    flex: 2,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: "#336598",
    color: "#fff",
    width: "100%",
    textAlign: "center",
    padding: 10,
  },
  value: {
    flex: 8,
    padding: 10,
  },
  roundedBox: {
    margin: 10,
    // borderWidth: 1,
    borderRadius: 10,
    padding: 10,
   
  },
  inputBoxWidth:{
     width: "240%",
    },
  bgGray: {
    backgroundColor: "#dbdada",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 18,
  },
  headerTextNew: {
    fontFamily:'Poppins_600SemiBold',
    fontSize: 18,
    color: "#dbdada"
  },
  inputBox: {
    margin: 10,
    // borderWidth: 1,
    borderRadius: 15,
    padding: 10,
    backgroundColor: "#dbdada",
  },
  activityContainer: {
    flexDirection: "row",
    margin: 10,
    borderWidth: 1,
  },
  activityTab: {
    padding: 10,
    textAlign: "center",
  },
  activityIndex: {
    flex: 2,
    margin: 2,
    textAlign: "center",
  },
  activityDateBox: {
    flex: 19,
    margin: 2,
  },
  activityDate: {
    paddingBottom: 5,
    marginRight: 4,
    marginRight: 4,
  },
  logText: {
    flexWrap: "wrap",
    flex: 1,
    marginRight: 10,
  },
  lottie: {
    width: 100,
    height: 100,
  },
  tabBtn: {
    // justifyContent: "center",
    paddingHorizontal: 5,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    marginHorizontal: 10,
  },
  gridView: {
    flex: 1,
    marginTop: 10,
    width: "100%",
    // paddingBottom: 50,
  },

  itemContainer: {
    // justifyContent: "space-around",
    flexDirection: "row",
    borderRadius: 5,
    // width: "100%",
    // flexWrap: "wrap",
    width: "100%",
    // backgroundColor: "steelblue",
    // padding: 10,
    minHeight: 30,
    flexGrow: 0,
    // paddingVertical: 15
  },
  itemName2: {
    fontSize: 22,
    color: "#fff",
    fontStyle: "Poppins_800ExtraBold",
    textAlign: "center",
    // color: 'red'
    // fontWeight: "600",
    // paddingHorizontal: 5
  },
  itemsku: {
    fontSize: 22,
    color: "#fff",
    fontStyle: "Poppins_800ExtraBold",
    textAlign: "center",
    wordBreak: "break-all",
    // color: 'red'
    // fontWeight: "600",
    // paddingHorizontal: 5
  },
  itemName: {
    fontSize: 19,
    color: "#fff",
    // fontStyle: "Poppins_800ExtraBold",
    alignSelf: "center",
    textAlign: "center",

    // color: 'green'
    paddingHorizontal: 5,
  },
  mblViewName: {
    fontSize: 19,
    color: "#fff",
    // fontStyle: "Poppins_800ExtraBold",
    alignSelf: "center",
    paddingHorizontal: 5,
  },
  gridHeader: {
    fontSize: 19,
    color: "#fff",
    fontFamily: "Poppins_600SemiBold",
    // fontFamily: fontFamily.font500,
    opacity: 0.5,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "center",
    // borderWidth: 0.5
  },
  gridHeader2: {
    fontSize: 19,
    color: "#fff",
    fontFamily: 'Poppins_600SemiBold',
    opacity: 0.5,
    // alignItems: "flex-start",
    // justifyContent: "flex-start",
    textAlign: "center",
    // borderWidth: 0.5
  },
  statusActive: {
    fontSize: 19,
    color: "#7bfac3",
    // fontStyle: "Poppins_800ExtraBold",
    fontWeight: "bold",
    alignSelf: "center",
    textAlign: "center",
  },
  statusNew: {
    fontSize: 19,
    color: "#eb53fa",
    // fontStyle: "Poppins_800ExtraBold",
    alignSelf: "center",
    textAlign: "center",
    fontWeight: "bold",
  },
  itemCode: {
    fontWeight: "600",
    fontSize: 12,
    // fontStyle: "Poppins_200ExtraLight",
    // color: '#000',
    // paddingHorizontal: 5
  },
  itemView: {
    justifyContent: "center",
    alignItems: "center",
    // height: 70,
    borderRadius: 5,
    // borderWidth: 0.5,
    // marginVertical: 5,
    paddingHorizontal: 5,
  },
  addItemView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    // flexShrink: 1,
    // paddingHorizontal: 1,
    borderRadius: 5,
    // borderWidth: 1
  },
  mblItemsView: {
    // justifyContent: 'center',
    // alignItems: 'center'
    flexDirection: "row",
  },
});
export default styles;
