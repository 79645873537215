import React, { Component } from 'react';
import { View, Text, TextInput, ImageBackground, Image, Dimensions, TouchableOpacity, Platform, ScrollView } from 'react-native';
import placeholder from "../../../assets/placeholder.png";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
let update = false
export default class ImageComponent extends React.Component{
    constructor(props){
        super(props)
        this.state={
            Imageshow:[]
        }
    }
    componentDidUpdate(preProp,presta){
        // update = true
        // console.log("update pros here---->>",preProp,presta,this.props)
        if(update === true){
            update = false
            this.checkImage(this.props.img)
        }
    }
    componentDidMount(){
        this.checkImage(this.props.img)
    }
    componentWillUnmount(){
        
        this.setState({Imageshow:[]})
    }
    
    checkImage=async(img)=>{
        
        axios({
            method: 'get',
            url: `${img}`,
            // headers: {"Access-Control-Allow-Origin":"*","Access-Control-Request-Headers": "cache-control","Access-Control-Request-Method": "GET","cache-control": 'public, max-age=31557600', "content-length": '590218', "content-type": 'application/xml'} 
          }).then((res)=>{
            //   console.log("ressssss------>",res)
            let imgs = {
                img : res.config.url,
                status : res.status
            }
            if(this.state.Imageshow.includes(imgs)){
                update = false
            }else{
                this.setState({Imageshow : [this.state.Imageshow,imgs]})
                // imgarr.push(imgs)
                update = false
            }
            // if(res.status == 404){

                
                   
            //     imgarr.push(<Image style={{ height: "90%", width: "90%", resizeMode: 'contain', borderRadius: 10 }} source={placeholder}/>)
    
                    
            // }else{
            //     console.log("000000",res.url)
            //     imgarr.push(<Image style={{ height: "90%", width: "90%", resizeMode: 'contain', borderRadius: 10 }} source={{uri : res.url}}/>)

            // }
            // console.log(res)
        }).catch((err)=>{
            // console.log("errrrr",err)
            let imgs = {
                img : img,
                status : 200
            }
            if(this.state.Imageshow.includes(imgs)){
                update = false
            }else{
                this.setState({Imageshow : [this.state.Imageshow,imgs]})
                // imgarr.push(imgs)
                update = false
            }
            console.clear()
        })
        update = true
    }
    render(){
        return(
            // imgarr.map((imgg)=>{
            //     return imgg
            // })
            
             this.state.Imageshow.map((im,index)=>{
                //  console.log("immmggsss",im.status)
                 if(im.status === 404){

                     return(
                         <Image style={{ height: "100%", width: "90%",marginTop:-50,resizeMode: 'contain', borderRadius: 10 }} source={placeholder}/>
                     )
                 }else{
                    return(
                        <Image style={{ height: "100%", width: "90%",marginTop:-50,resizeMode: 'contain', borderRadius: 10 }} source={{uri : im.img}}/>
                    ) 
                 }
             })

            
        )
    }
    imgarr = []
}