import { LinearGradient } from "expo-linear-gradient";
import React, { Component } from "react";
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from "react-native";
import downArrow from "../../../assets/arrow-down-product.png";
import upArrow from "../../../assets/arrow-up-product.png";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus,faAngleDown,faArrowRightFromBracket,faArrowRightToBracket, faBriefcase,faXmark,faPrint} from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as ImagePicker from "expo-image-picker";
// import * as  Print from 'expo-print';
import {
  printScan,
  addImage,
  UpdateProductInfo,
  GetProductDetail,
} from "../../actions/productAction";
import { } from "../../actions/productAction";
import { connect } from "react-redux";
import styles from "../../style/signin";
// import ImageUploader from 'react-images-upload';
let barcodenew = [];
let updateimage = false;
class ProductInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get("window").width,
      multiInput: "",
      skusMultiInput: "",
      categoriesMultiInput: "",
      barcodesLocal: [],
      skusLocal: [],
      categoriesLocal: [],
      lessMore: false,
      lessMored: false,
      lessMoreMobile: false,
      notesInternal: false,
      loading: false,
      image: null,
      imageflag: false,
      ModalShow: false,
    };
  }

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  contentLess = () => {
    this.setState({ lessMore: !this.state.lessMore });
  };
  contentLessd = () => {
    this.setState({ lessMored: !this.state.lessMored });
  };
  contentLessMobile = () => {
    this.setState({ lessMoreMobile: !this.state.lessMoreMobile });
  };
  updateTagState = (state, type) => { };
  openTextField = (field) => {
    this.setState({ notesInternal: true });
  };
  componentWillUnmount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    if (
      this.props &&
      this.props.basicinfo &&
      this.props.basicinfo.multibarcode
    ) {
      let barcodes = this.props.product.barcodes;
      barcodenew = this.props.product.barcodes;

      let packes = [];
      let codes = [];

      barcodes &&
        barcodes.length > 0 &&
        barcodes.map((barcode, index) => {
          codes.push(barcode.barcode);
        });

      this.setState({ barcodesLocal: codes });
    }
    if (this.props && this.props.product && this.props.product.skus) {
      let skus = [];
      this.props.product.skus &&
        this.props.product.skus.length > 0 &&
        this.props.product.skus.map((sku, index) => {
          skus.push(sku.sku);
        });
      this.setState({ skusLocal: skus });
    }
    if (this.props && this.props.product && this.props.product.cats) {
      let cats = [];
      this.props.product.cats &&
        this.props.product.cats.length > 0 &&
        this.props.product.cats.map((cat, index) => {
          cats.push(cat.category);
        });
      this.setState({ categoriesLocal: cats });
    }
  }

  componentUnmount() {
    barcodenew = [];
  }

  onDrop = (picture) => {
    // console.log("pictureee here====>>>>>>>>>>",picture)
  };
  componentDidUpdate() {
    // console.log("00000--->", this.props?.AddImage?.product_image)
    if (this.props?.AddImage?.product_image && updateimage) {
      let ID = this.props.product.basicinfo.id;

      this.props.GetProductDetail(ID);
      updateimage = false;
    }
    // console.log("image state", this.state.image)
    if (this.props.removefromLocal !== "") {
      this.removeByName(this.props.removefromLocal);
    }
    // let barcodesLocal = this.state.barcodesLocal
    // if(this.props.updateBarcodeLocal === true){
    //   this.props.product.barcodes && this.props.product.barcodes.length > 0 && this.props.product.barcodes.map((barcode, index) => {
    //     barcodesLocal.push(barcode.barcode)
    //   })
    //   this.setState({barcodesLocal})
    // }
  }

  removeByName = (barcodeName) => {
    let arr = this.state.barcodesLocal;
    arr &&
      arr.length &&
      arr.map((barcode, index) => {
        if (barcode === this.props.removefromLocal) {
          arr.splice(index, 1);
        }
      });
    this.setState({ arr });
    this.props.updateLocalBarcode();
  };

  pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    let ID = this.props.product.basicinfo.id;
    let image = { image: result.uri };

    let cc = this.props.addImage(ID, result.uri);
    // console.log("hereeee-->", cc)
    updateimage = true;
    // console.log("pppppppppppp-->", this.props.AddImage, this.props)
    // this.setState({ image: result.uri, imageflag: true });
  };

  imageRemove = (data) => {
    this.setState({ ModalShow: false });
    // console.log("image id ", data)
    let ID = this.props.product.basicinfo.id;
    let productData = this.props.product;
    // console.log("image id ", this.props.product.images)

    {
      productData?.images?.map((remove, index) => {
        if (data == remove?.id) {
          productData?.images?.splice(index, 1);
          // console.log("bhguryur", data)
        }
        // console.log("remove map", remove.id)
      });
    }
    this.props.UpdateProductInfo(ID, productData);
  };

  updateMultiTag = (type, remove) => {
    if (type === "barcode") {
      let barcodesLocal = this.state.barcodesLocal;
      let barcode = this.state.multiInput;
      let previousData = this.props.product.barcodes;
      let finalArray = [];
      let newHash = {};
      let NewArray = [];
      barcodesLocal.includes(barcode) ? null : barcodesLocal.push(barcode);
      if (barcode !== " " || remove === true) {
        previousData.map((previousDataBarcode) => {
          if (barcodesLocal.includes(previousDataBarcode.barcode)) {
            finalArray.push(previousDataBarcode);
            NewArray.push(previousDataBarcode.barcode);
          }
        });
        barcodesLocal.map((barcode) => {
          if (barcode !== "" && NewArray.includes(barcode) === false) {
            newHash = {
              barcode: barcode,
              // created_at: "",
              id: "TEMP",
              // is_multipack_barcode: true,
              // lot_number: null,
              // order: 0,
              packing_count: "1",
              product_id: this.props.basicinfo.id,

              // updated_at: "",
            };
            finalArray.push(newHash);
          }
        });
        this.setState({ barcodesLocal, multiInput: "" });
        let checkRemove = remove === true ? true : false;
        if (finalArray.length > 0) {
          this.props.updateProductSBC(finalArray, "barcode", checkRemove);
        } else {
          if (finalArray.length === 0) {
            this.props.updateProductSBC([], "barcode");
          }
        }
      }
    }

    if (type === "skus") {
      let sku = this.state.skusMultiInput;
      let skusLocal = this.state.skusLocal;
      let previousDataSku = this.props.product.skus;
      let finalArraySku = [];
      let newHashSku = {};
      let NewArraySku = [];
      skusLocal.includes(sku) ? null : skusLocal.push(sku);
      if (sku !== " " || remove === true) {
        previousDataSku.map((previousSkus) => {
          if (skusLocal.includes(previousSkus.sku)) {
            finalArraySku.push(previousSkus);
            NewArraySku.push(previousSkus.sku);
          }
        });
        skusLocal.map((sku) => {
          if (sku !== "" && NewArraySku.includes(sku) === false) {
            newHashSku = {
              id: "TEMP",
              product_id: this.props.basicinfo.id,
              sku: sku,
            };
            finalArraySku.push(newHashSku);
          }
        });
        this.setState({ skusLocal, skusMultiInput: "" });
        let checkRemove = remove === true ? true : false;
        if (finalArraySku.length > 0) {
          this.props.updateProductSBC(finalArraySku, "sku", checkRemove);
        } else {
          if (finalArraySku.length === 0) {
            this.props.updateProductSBC([], "sku");
          }
        }
      }
    }

    if (type === "cats") {
      let cat = this.state.categoriesMultiInput;
      let categoriesLocal = this.state.categoriesLocal;
      let previousDataCat = this.props.product.cats;
      let finalArrayCat = [];
      let newHashCat = {};
      let NewArrayCat = [];
      categoriesLocal.includes(cat) ? null : categoriesLocal.push(cat);
      if (cat !== " " || remove === true) {
        previousDataCat.map((previousCat) => {
          if (categoriesLocal.includes(previousCat.category)) {
            finalArrayCat.push(previousCat);
            NewArrayCat.push(previousCat.category);
          }
        });
        categoriesLocal.map((cat) => {
          if (cat !== "" && NewArrayCat.includes(cat) === false) {
            newHashCat = {
              id: "TEMP",
              product_id: this.props.basicinfo.id,
              category: cat,
            };
            finalArrayCat.push(newHashCat);
          }
        });
        this.setState({ categoriesLocal, categoriesMultiInput: "" });
        let checkRemove = remove === true ? true : false;
        if (finalArrayCat.length > 0) {
          this.props.updateProductSBC(finalArrayCat, "cats", checkRemove);
        } else {
          if (finalArrayCat.length === 0) {
            this.props.updateProductSBC([], "cats");
          }
        }
      }
    }

    // if(type === "cats"){
    //   let categoriesLocal = this.state.categoriesLocal
    //   let cats = this.state.categoriesMultiInput
    //   let previousDataCat = this.props.product.cats
    //   let finalArrayCat = []
    //   let newHashCat = {}
    //   let NewArrayCat = []
    //   categoriesLocal.includes(cats) ? null : categoriesLocal.push(cats)
    //   if(cats !== "" || remove === true){
    //     previousDataCat.map((previousCats) => {
    //       if(categoriesLocal.includes(previousCats.category)){
    //         finalArrayCat.push(previousCats)
    //         NewArrayCat.push(previousCats.category)
    //       }
    //     })
    //     categoriesLocal.map((cats) => {
    //       if(cats !== "" && NewArrayCat.includes(cats) === false){
    //         newHashCat = {
    //           id: "TEMP",
    //           product_id: this.props.basicinfo.id,
    //           category: cats
    //         }
    //         finalArrayCat.push(newHashCat)
    //       }
    //     })
    //     this.setState({categoriesLocal , categoriesMultiInput: ""})
    //     let checkRemove = remove === true ? true : false
    //       if(finalArrayCat.length > 0){
    //         this.props.updateProductSBC(finalArrayCat , "cats" , checkRemove)
    //       }else{
    //         if(finalArrayCat.length === 0){
    //           this.props.updateProductSBC([] , "cats")
    //         }
    //       }
    //   }
    // }
  };
  changeProductKit = () => {
    this.props.changeState("inventoryKitOptionsActive");
  };

  removeMultiTag = (type, index) => {
    if (type === "barcode") {
      let barcodes = this.state.barcodesLocal;
      if (barcodes.length > 0) {
        barcodes.splice(index, 1);
        this.updateMultiTag("barcode", true);
      }
      this.setState({ barcodes });
    }

    if (type === "sku") {
      let skus = this.state.skusLocal;
      if (skus.length > 0) {
        skus.splice(index, 1);
        this.updateMultiTag("skus", true);
      }
      this.setState({ skus });
    }
    if (type === "cats") {
      let cats = this.state.categoriesLocal;
      if (cats.length > 0) {
        cats.splice(index, 1);
        this.updateMultiTag("cats", true);
      }
      this.setState({ cats });
    }
  };

  submitProduct = () => {
    let ID = this.props.product.basicinfo.id;
    this.props.printScan(ID);
  };

  // addimage =()=>{
  //   let ID = this.props.product.basicinfo.id
  //   this.props.addImage(ID)
  // }
  render() {
    let pack = this.props.basicinfo.multibarcode;
    let { basicinfo, images } = this.props;
    let { inventoryWarehouses } = this.props;
    return (
      <View>

        {this.state.windowWidth >= 900 ? (
          <>
            {basicinfo && (
              <>
                {images != undefined && images?.length > 0 &&
                  images?.map((img, index) => {
                    return (
                      <>
                        <View style={{ justifyContent: "center", alignItems: "center", position: "relative", zIndex: 9 }}>
                          {this.state.ModalShow ? (
                            <View style={{

                              position: "absolute",
                              // top: 10,
                              // zIndex: 9999,
                              // backgroundColor: "rgba(14, 14 , 14 , 0.7)",
                              justifyContent: "center",
                              alignItems: "center",
                              // height: "100%",
                              width: "30%",
                              marginLeft: "7%"
                              // left: "20%"
                            }}>
                              <LinearGradient
                                locations={[0, 1]}
                                colors={["#7790a7", "#7790a7"]}
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  backgroundColor: "#b7b7b7",
                                  padding: 5,
                                  borderRadius: 10,
                                  width: "100%",
                                  marginLeft: "",
                                  marginRight: "",
                                  padding: 10,
                                  shadowColor: "#000",
                                  shadowOffset: { width: 0, height: 0 },
                                  shadowOpacity: 10,
                                  shadowRadius: 10,
                                }}
                              >
                                <Text
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                    color: "#cdcdcd",
                                    fontFamily: "Poppins_800ExtraBold",
                                    fontSize: "16px",
                                  }}
                                >
                                  Are you sure to remove image ?
                                </Text>
                                <TouchableOpacity
                                  style={{
                                    height: 28,
                                    width: 56,
                                    borderRadius: 1,
                                    backgroundColor: "#536474",
                                    padding: 5,
                                    borderRadius: 10,
                                    marginLeft: 20
                                  }}
                                  onPress={() => {
                                    this.setState({ ModalShow: false });
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                      fontWeight: "bold",
                                      marginLeft: 2
                                    }}
                                  >
                                    cancel
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  style={{
                                    height: 28,
                                    width: 35,
                                    borderRadius: 1,
                                    backgroundColor: "#536474",
                                    padding: 5,
                                    borderRadius: 10,
                                    marginLeft: 10
                                  }}
                                  onPress={() => {
                                    this.imageRemove(img?.id);
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                      fontWeight: "bold",
                                      // marginRight: "20%"
                                    }}
                                  >
                                    OK
                                  </Text>
                                </TouchableOpacity>
                              </LinearGradient>
                            </View>
                          ) : (
                            <View></View>
                          )}
                        </View>
                      </>)
                  })
                }

                <View
                  style={{ marginLeft: "30px", marginTop: "-28px", width: 200 }}
                >
                  {/* {images.length > 0 &&
                    images.map((img, index) => {
                      return (
                        <>
                          {this.state.ModalShow ? (
                            <View style={{

                              position: "absolute",
                              top: 10,
                              // zIndex: 9999,
                              // backgroundColor: "rgba(14, 14 , 14 , 0.7)",
                              justifyContent: "center",
                              alignItems: "center",
                              // height: "100%",
                              width: "180%",
                              left:"20%"
                            }}>
                              <LinearGradient
                                locations={[0, 1]}
                                colors={["#7790a7", "#7790a7"]}
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  backgroundColor: "#b7b7b7",
                                  padding: 5,
                                  borderRadius: 10,
                                  width: "100%",
                                  marginLeft: "",
                                  marginRight: "",
                                  padding: 10,
                                  shadowColor: "#000",
                                  shadowOffset: { width: 0, height: 0 },
                                  shadowOpacity: 10,
                                  shadowRadius: 10,
                                }}
                              >
                                <Text
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                    fontWeight: "bold",
                                    fontSize: 16,
                                    color: "white",
                                  }}
                                >
                                  Are you sure to remove image ?
                                </Text>
                                <TouchableOpacity
                                  style={{
                                    height: 28,
                                    width: 56,
                                    borderRadius: 1,
                                    backgroundColor: "#536474",
                                    padding: 5,
                                    borderRadius: 10,
                                  }}
                                  onPress={() => {
                                    this.setState({ ModalShow: false });
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    cancel
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  style={{
                                    height: 28,
                                    width: 35,
                                    borderRadius: 1,
                                    backgroundColor: "#536474",
                                    padding: 5,
                                    borderRadius: 10,
                                  }}
                                  onPress={() => {
                                    this.imageRemove(img.id);
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    OK
                                  </Text>
                                </TouchableOpacity>
                              </LinearGradient>
                            </View>
                          ) : (
                            <View></View>
                          )}
                        </>
                      );
                    })} */}
                  {/* <View style={{ flexDirection: "row", alignSelf: "flex-end", marginRight: 10, marginLeft: 10, padding: 10 }}>
                    <TouchableOpacity onPress={() => this.props.updateProduct()}><Text style={{ padding: 10, color: "#fff", fontWeight: "bold", borderRadius: 20, marginRight: 10 }}>Save & Close</Text></TouchableOpacity>
                     <TouchableOpacity onPress={() => this.props.navigation.goBack()}><Text style={{ padding: 10, color: "#fff", fontWeight: "bold", borderRadius: 20 }}>Close </Text></TouchableOpacity>
                  </View> */}

                  <Text style={[{
                    fontWeight: "bold", fontFamily: "Poppins_700Bold", fontSize: "20px",
                    background: " -webkit-linear-gradient(#4b657d  , #768ea5)",
                      webkitBackgroundClip: "text", webkitTextFillColor: "transparent"
                  }]}>
                    {this.props.product?.skus?.[0]?.sku}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: "18px",
                    marginLeft: "25px",
                  }}
                >
                  

                  <FontAwesomeIcon style={{ color: "white", height: "17", width: "17" }} icon={faArrowRightToBracket} />
                  {/* <Image
                    style={{ width: 20, height: 20, resizeMode: "contain" }}
                    source={require("../../../assets/arrow-right.png")}
                  /> */}
                  <Text style={{ color: "white",marginLeft: "10px", }}>
                    Make this product an alias of another
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: "10px",
                    marginLeft: "25px",
                  }}
                >
                  <FontAwesomeIcon style={{ color: "white", height: "17", width: "17" }} icon={faArrowRightFromBracket} />
                  {/* <Image
                    style={{ width: 20, height: 20, resizeMode: "contain" }}
                    source={require("../../../assets/arrow-left.png")}
                  /> */}
                  <Text style={{ color: "white" ,marginLeft: "10px",}}>
                    Choose products that are aliases of this product
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: "10px",
                    marginLeft: "25px",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.changeProductKit();
                    }}
                  >
                      <FontAwesomeIcon style={{ color: "white", height: "17", width: "17" }} icon={faBriefcase} />
                    {/* <Image
                      style={{ width: 20, height: 20, resizeMode: "contain" }}
                      source={require("../../../assets/briefcase.png")}
                    /> */}
                    <Text
                      style={{
                        color: "white",
                        marginTop: "-18px",
                        marginLeft: "25px",
                      }}
                    >
                      Change this product to a kit
                    </Text>
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    marginLeft: "550px",
                    width: "30%",
                    marginTop: "-60px",
                  }}
                >

                  <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                  >
                    {images != undefined && images?.length > 0 &&
                      images?.map((img, index) => {
                        return (
                          <>

                            {/* {this.state.image && this.state.imageflag
                            ? */}
                            {/* <Image source={{ uri: this.state.image }} style={{ width: 80, height: 80 }} />
                            : */}
                            {img?.image ? (
                              <Image
                                style={{
                                  width: 80,
                                  height: 80,
                                  marginLeft: 30,
                                }}
                                source={{ uri: img?.image }}
                                key={index}
                              />
                            ) : (
                              <View></View>
                            )}
                            {/* } */}

                            <TouchableOpacity
                              onPress={() => {
                                this.setState({ ModalShow: true });
                              }}
                            >
                              <Text
                                style={{
                                  color: "white",
                                  marginLeft: -71,
                                  marginTop: 88,
                                  fontFamily: "Poppins_600SemiBold",
                                }}
                              >
                                Remove
                              </Text>
                            </TouchableOpacity>
                          </>
                        );
                      })}
                  </ScrollView>
                  {/* <View>
                  <FontAwesomeIcon  style={{color:"white",height:"80%",width:"80%"}} icon={ faPlus } />
                  </View> */}

                  <View
                    style={{
                      flexDirection: "row",
                      height: 100,
                      width: 190,
                      marginLeft: 40,
                    }}
                      >
                    <TouchableOpacity onPress={() => this.pickImage()}>
                      <LinearGradient
                        colors={[
                          // "#000",
                          // "#000",
                          "#5c778f",
                          "#5c778f",
                          // "#253c57",
                          // "#253c57",
                          "#000",
                        ]}
                        start={[-0.5, -1]}
                        style={{
                          flex: 1,
                          borderWidth: 1,
                          borderRadius: 5,
                          borderColor: "#869db1",
                          borderBottomWidth: 0,
                          shadowColor: "#ffff",
                          shadowOffset: { width: 0, height: 2 },
                          shadowOpacity: 0.9,
                          shadowRadius: 3,
                          elevation: 3,
                          // overflow: "scroll",
                          // paddingHorizontal: 15,
                          // width: windowWidth,
                          // height: windowHeight,
                          paddingBottom: 15,
                          width: "112%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >

                        <FontAwesomeIcon style={{ color: "white", height: "80%", width: "120%" }} icon={faPlus} />

                        {/* <Image
                          style={{
                            height: "70%",
                            width: "70%",
                            marginTop: "10%",
                            marginLeft: "16%",
                          }}
                          source={require("../../../assets/plus-white-thickness.png")}
                        /> */}
                        {/* {this.state.image && <Image source={{ uri: this.state.image }} style={{ width: 60, height: 60 }} />} */}
                        {/* <ImageUploader
                          // withIcon={true}
                          // buttonText='Choose images'
                          onChange={this.onDrop}
                          imgExtension={['.jpg', '.gif', '.png', '.gif']}
                          maxFileSize={5242880}
                        /> */}

                        <Text
                          style={{
                            color: "white",
                            fontFamily: "Poppins_600SemiBold",
                            textAlign: "center",
                            marginBottom: -15,
                          }}
                        >
                          Add Image
                        </Text>
                      </LinearGradient>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    margin: 10,
                    marginTop: "2%",
                    backgroundColor: "#rgba(104, 135, 156, 0.3)",
                    borderWidth: 1,
                    borderRadius: 10,
                    border: "none",
                  }}
                >
                  <View style={{ margin: 10, flexDirection: "row" }}>
                    {inventoryWarehouses &&
                      inventoryWarehouses.length > 0 &&
                      inventoryWarehouses.map((item, index) => {
                        return (
                          <>
                            <Text
                              style={{
                                marginRight: "-69px",
                                marginLeft: "20px",
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              QOH
                            </Text>

                            <TextInput
                              style={{
                                margin: 15,
                                marginTop: "25px",
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                width: "50%",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.inventoryWarehousesHandleChange(
                                  text,
                                  "quantity_on_hand"
                                )
                              }
                              value={item.info.quantity_on_hand}
                              keyboardType={"numeric"}
                            ></TextInput>

                            <Text
                              style={{
                                marginRight: "-86px",
                                marginLeft: "10px",
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Location1
                            </Text>
                            <TextInput
                              style={{
                                margin: 15,
                                marginTop: "25px",
                                marginLeft: "-18px",
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                width: "50%",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.inventoryWarehousesHandleChange(
                                  text,
                                  "location_primary"
                                )
                              }
                              value={item.info.location_primary}
                            ></TextInput>
                          </>
                        );
                      })}
                  </View>
                  <TouchableOpacity onPress={() => this.contentLess()}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          color: "#cdcdcd",
                          fontFamily: "Poppins_600SemiBold",
                          fontSize: "18px",
                        }}
                      >
                        {this.state.lessMore ? "less" : "More"}
                      </Text>
                      <Image
                        style={{ width: 25, height: 25, resizeMode: "contain" }}
                        source={this.state.lessMore ? upArrow : downArrow}
                      />
                    </View>
                  </TouchableOpacity>
                  {this.state.lessMore && (
                    <>
                      <View>
                        {inventoryWarehouses &&
                          inventoryWarehouses.length > 0 &&
                          inventoryWarehouses.map((item, index) => {
                            return (
                              <>
                                <View
                                  style={{ margin: 10, flexDirection: "row" }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      width: "49%",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "#cdcdcd",
                                        fontFamily: "Poppins_600SemiBold",
                                        fontSize: "18px",
                                        marginLeft: "17px",
                                      }}
                                    >
                                      Location 1
                                    </Text>
                                    <TextInput
                                      style={{
                                        borderWidth: 1,
                                        border: "none",
                                        height: "31px",
                                        borderRadius: 10,
                                        padding: 10,
                                        backgroundColor: "#cdcdcd",
                                        color: "#1d3045",
                                        fontFamily: "Extra-light 200",
                                      }}
                                      onChangeText={(text, name) =>
                                        this.props.inventoryWarehousesHandleChange(
                                          text,
                                          "location_primary"
                                        )
                                      }
                                      value={item.info.location_primary}
                                    ></TextInput>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      width: "49%",
                                      marginLeft: 17,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "#cdcdcd",
                                        fontFamily: "Poppins_600SemiBold",
                                        fontSize: "18px",
                                        marginLeft: "17px",
                                      }}
                                    >
                                      Location 1 Qty
                                    </Text>
                                    <TextInput
                                      style={{
                                        borderWidth: 1,
                                        border: "none",
                                        height: "31px",
                                        borderRadius: 10,
                                        padding: 10,
                                        backgroundColor: "#cdcdcd",
                                        color: "#1d3045",
                                        fontFamily: "Extra-light 200",
                                      }}
                                      onChangeText={(text, name) =>
                                        this.props.inventoryWarehousesHandleChange(
                                          text,
                                          "location_primary_qty"
                                        )
                                      }
                                      value={item.info.location_primary_qty}
                                      keyboardType={"numeric"}
                                    ></TextInput>
                                  </View>
                                </View>

                                <View
                                  style={{ margin: 10, flexDirection: "row" }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      width: "49%",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "#cdcdcd",
                                        fontFamily: "Poppins_600SemiBold",
                                        fontSize: "18px",
                                        marginLeft: "17px",
                                      }}
                                    >
                                      Location 2
                                    </Text>
                                    <TextInput
                                      style={{
                                        borderWidth: 1,
                                        border: "none",
                                        height: "31px",
                                        borderRadius: 10,
                                        padding: 10,
                                        backgroundColor: "#cdcdcd",
                                        color: "#1d3045",
                                        fontFamily: "Extra-light 200",
                                      }}
                                      onChangeText={(text, name) =>
                                        this.props.inventoryWarehousesHandleChange(
                                          text,
                                          "location_secondary"
                                        )
                                      }
                                      value={item.info.location_secondary}
                                    ></TextInput>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      width: "49%",
                                      marginLeft: 14,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "#cdcdcd",
                                        fontFamily: "Poppins_600SemiBold",
                                        fontSize: "18px",
                                        marginLeft: "17px",
                                      }}
                                    >
                                      Location 2 Qty
                                    </Text>
                                    <TextInput
                                      style={{
                                        borderWidth: 1,
                                        border: "none",
                                        height: "31px",
                                        borderRadius: 10,
                                        padding: 10,
                                        backgroundColor: "#cdcdcd",
                                        color: "#1d3045",
                                        fontFamily: "Extra-light 200",
                                      }}
                                      onChangeText={(text, name) =>
                                        this.props.inventoryWarehousesHandleChange(
                                          text,
                                          "location_secondary_qty"
                                        )
                                      }
                                      value={item.info.location_secondary_qty}
                                      keyboardType={"numeric"}
                                    ></TextInput>
                                  </View>
                                </View>

                                <View
                                  style={{ margin: 10, flexDirection: "row" }}
                                >
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      width: "49%",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "#cdcdcd",
                                        fontFamily: "Poppins_600SemiBold",
                                        fontSize: "18px",
                                        marginLeft: "17px",
                                      }}
                                    >
                                      Location 3
                                    </Text>
                                    <TextInput
                                      style={{
                                        borderWidth: 1,
                                        border: "none",
                                        height: "31px",
                                        borderRadius: 10,
                                        padding: 10,
                                        backgroundColor: "#cdcdcd",
                                        color: "#1d3045",
                                        fontFamily: "Extra-light 200",
                                      }}
                                      onChangeText={(text, name) =>
                                        this.props.inventoryWarehousesHandleChange(
                                          text,
                                          "location_tertiary"
                                        )
                                      }
                                      value={item.info.location_tertiary}
                                    ></TextInput>
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "column",
                                      width: "49%",
                                      marginLeft: 14,
                                    }}
                                  >
                                    <Text
                                      style={{
                                        color: "#cdcdcd",
                                        fontFamily: "Poppins_600SemiBold",
                                        fontSize: "18px",
                                        marginLeft: "17px",
                                      }}
                                    >
                                      Location 3 Qty
                                    </Text>
                                    <TextInput
                                      style={{
                                        borderWidth: 1,
                                        border: "none",
                                        height: "31px",
                                        borderRadius: 10,
                                        padding: 10,
                                        backgroundColor: "#cdcdcd",
                                        color: "#1d3045",
                                        fontFamily: "Extra-light 200",
                                      }}
                                      onChangeText={(text, name) =>
                                        this.props.inventoryWarehousesHandleChange(
                                          text,
                                          "location_tertiary_qty"
                                        )
                                      }
                                      value={item.info.location_tertiary_qty}
                                      keyboardType={"numeric"}
                                    ></TextInput>
                                  </View>
                                </View>
                              </>
                            );
                          })}
                      </View>
                    </>
                  )}
                </View>
                <View
                  style={{
                    margin: 10,
                    backgroundColor: "#rgba(104, 135, 156, 0.3)",
                    borderWidth: 1,
                    borderRadius: 10,
                    border: "none",
                  }}
                >
                  <Text
                    style={{
                      marginLeft: "15px",
                      color: "#cdcdcd",
                      fontFamily: "Poppins_600SemiBold",
                      fontSize: "18px",
                    }}
                  >
                    Product Name
                  </Text>
                  <TextInput
                    style={{
                      margin: 15,
                      marginTop: "3px",
                      borderWidth: 1,
                      border: "none",
                      height: "31px",
                      borderRadius: 10,
                      padding: 10,
                      backgroundColor: "#cdcdcd",
                      width: "97%",
                      color: "#1d3045",
                      fontFamily: "Extra-light 200",
                    }}
                    onChangeText={(text, name) =>
                      this.props.productInfoHandleChange(text, "name")
                    }
                    value={basicinfo.name}
                  />
                  <Text
                    style={{
                      marginLeft: "15px",
                      color: "#cdcdcd",
                      fontFamily: "Poppins_600SemiBold",
                      fontSize: "18px",
                      // background: " -webkit-linear-gradient(#4b657d  , #768ea5)",
                      // webkitBackgroundClip: "text", webkitTextFillColor: "transparent"
                    }}
                  >
                    SKUs
                  </Text>
                  <TextInput
                    style={{
                      margin: 15,
                      marginTop: "3px",
                      borderWidth: 1,
                      border: "none",
                      height: "31px",
                      borderRadius: 10,
                      padding: 10,
                      backgroundColor: "#cdcdcd",
                      width: "97%",
                      color: "#1d3045",
                      fontFamily: "Extra-light 200",
                    }}
                    onChangeText={(text) => {
                      this.setState({ skusMultiInput: text });
                    }}
                    value={this.state.skusMultiInput}
                    onSubmitEditing={() => this.updateMultiTag("skus")}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: 14,
                      marginRight: 10,
                    }}
                  >
                    {this.state.skusLocal.length > 0 &&
                      this.state.skusLocal.map((sku, index) => {
                        return (
                          sku !== "" && (
                            <View
                              key={index}
                              style={{
                                backgroundColor: "#cdcdcd",
                                padding: 5,
                                borderRadius: 10,
                                borderWidth: 1,
                                marginRight: 8,
                                flexDirection: "row",
                                border: "none",
                                justifyContent: "center", alignItems: "center"
                              }}
                            >
                              <Text
                                style={{
                                  marginRight: 4,
                                  color: "#253c57",
                                  fontFamily: "Extra-light 200",
                                }}
                              >
                                {sku}
                              </Text>
                              <TouchableOpacity
                                key={index}
                                onPress={() =>
                                  this.removeMultiTag("sku", index)
                                }
                              >
                                {/* <Image style={{ height: "70%", width: "70%" }} source={require("../../../assets/cross-black.png")} /> */}

                                {/* <Text style={{ color: "#000000", fontWeight: "800", marginTop: "3px" }}> X </Text> */}
                                <FontAwesomeIcon style={{ color: "black", height: "12", width: "12" }} icon={faXmark} />
                             
                                {/* <Image
                                  style={{
                                    width: 10,
                                    height: 10,
                                    marginTop: "2px",

                                  }}
                                  source={require("../../../assets/cross-new-blue.png")}
                                /> */}
                              </TouchableOpacity>
                            </View>

                          )
                        );
                      })}
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "60%" }}>
                      <View style={{ flexDirection: "row", width: " 120%" }}>
                        <Text
                          style={{
                            color: "#cdcdcd",
                            fontFamily: "Poppins_600SemiBold",
                            fontSize: "18px",
                            marginTop: "1%",
                            marginLeft: "2%",
                          }}
                        >
                          Barcodes
                        </Text>

                        <Text
                          style={{
                            width: "20%",
                            color: "#cdcdcd",
                            fontFamily: "Poppins_600SemiBold",
                            fontSize: "18px",
                            marginTop: "1%",
                            marginLeft: "73%",
                          }}
                        >
                          Scan Qty
                        </Text>
                      </View>
                      {barcodenew.map((barcode, index) => {
                        return (
                          barcode !== "" && (
                            <>
                              <View style={{ flexDirection: "row" }}>
                                <View style={{ width: "100%" }}>
                                  <TextInput
                                    style={{
                                      margin: 15,
                                      marginTop: "3px",
                                      borderWidth: 1,
                                      border: "none",
                                      height: "31px",
                                      borderRadius: 10,
                                      padding: 10,
                                      backgroundColor: "#cdcdcd",
                                      color: "#1d3045",
                                      fontFamily: "Extra-light 200",
                                    }}
                                    onChangeText={(text) => {
                                      this.setState({ multiInput: text });
                                    }}
                                    value={barcode.barcode}
                                    onSubmitEditing={() =>
                                      this.updateMultiTag("barcode")
                                    }
                                  />

                                  <TouchableOpacity
                                    key={index}
                                    onPress={() =>
                                      this.removeMultiTag("barcode", index)
                                    }
                                  >
                                      <FontAwesomeIcon style={{ color: "black", height: "17", width: "17",marginTop: "-40px",
                                        marginLeft: "95%", }} icon={faXmark} />
                                    {/* <Image
                                      style={{
                                        width: 10,
                                        height: 10,
                                        marginTop: "-37px",
                                        marginLeft: "95%",
                                      }}
                                      source={require("../../../assets/cross-new-blue.png")}
                                    /> */}
                                  </TouchableOpacity>
                                </View>
                                <View style={{ marginRight: "20px" }}>
                                  <TextInput
                                    style={{
                                      borderWidth: 1,
                                      border: "none",
                                      height: "31px",
                                      borderRadius: 10,
                                      padding: 10,
                                      backgroundColor: "#cdcdcd",
                                      color: "#1d3045",
                                      fontFamily: "Extra-light 200",
                                      marginTop: "3px",
                                    }}
                                    value={barcode.packing_count}
                                  ></TextInput>

                                  <TouchableOpacity
                                    onPress={() => this.submitProduct()}
                                  >
                                        <FontAwesomeIcon style={{ color: "white", height: "20", width: "20" ,marginTop: "-12%",
                                        marginLeft: "110%", }} icon={faPrint} />
                                    {/* <Image
                                      style={{
                                        width: 20,
                                        height: 20,
                                        resizeMode: "contain",
                                        marginTop: "-10%",
                                        marginLeft: "110%",
                                      }}
                                      source={require("../../../assets/printer-w.png")}
                                    /> */}
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </>
                          )
                        );
                      })}
                    </View>
                  </View>
                  <View style={{ marginLeft: "48%" }}>
                    {this.state.notesInternal == false ? (
                      <TouchableOpacity
                        onPress={() => {
                          this.openTextField();
                        }}
                      >
                          <FontAwesomeIcon style={{ color: "white", height: "60", width: "60" }} icon={faPlus} />
                          
                        {/* <Image
                          style={{ width: 40, height: 40 }}
                          source={require("../../../assets/plus-white-thickness.png")}
                        /> */}
                      </TouchableOpacity>
                    ) : (
                      <TextInput
                        style={{
                          marginLeft: "-90%",
                          marginRight: "3%",
                          marginTop: "3px",
                          borderWidth: 1,
                          border: "none",
                          height: "31px",
                          borderRadius: 10,
                          padding: 10,
                          backgroundColor: "#cdcdcd",
                          color: "#1d3045",
                          fontFamily: "Extra-light 200",
                        }}
                        onChangeText={(text) => {
                          this.setState({ multiInput: text });
                        }}
                        value={this.state.multiInput}
                        onSubmitEditing={() => this.updateMultiTag("barcode")}
                      />
                    )}
                  </View>
                  <View >
                    <TouchableOpacity onPress={() => this.contentLessd()}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop:"2%"
                        }}
                      >
                        <Text
                          style={{
                            color: "#cdcdcd",
                            fontFamily: "Poppins_600SemiBold",
                            fontSize: "18px",
                          }}
                        >
                          {this.state.lessMored ? "less" : "More"}
                        </Text>
                        <Image
                          style={{
                            width: 25,
                            height: 25,
                            resizeMode: "contain",
                          }}
                          source={this.state.lessMored ? upArrow : downArrow}
                        />
                      </View>
                    </TouchableOpacity>
                    {this.state.lessMored && (
                      <>
                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Categories
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text) => {
                                this.setState({ categoriesMultiInput: text });
                              }}
                              value={this.state.categoriesMultiInput}
                              onSubmitEditing={() =>
                                this.updateMultiTag("cats")
                              }
                            ></TextInput>
                            <View
                              style={{
                                flexDirection: "row",
                                marginLeft: "5px",
                                marginTop: "2%",
                              }}
                            >
                              {this.state.categoriesLocal.length > 0 &&
                                this.state.categoriesLocal.map(
                                  (cats, index) => {
                                    return (
                                      cats !== "" && (
                                        <View
                                          key={index}
                                          style={{
                                            backgroundColor: "#cdcdcd",
                                            padding: 5,
                                            borderRadius: 10,
                                            borderWidth: 1,
                                            marginRight: 8,
                                            flexDirection: "row",
                                            border: "none",
                                          }}
                                        >
                                          <Text
                                            style={{
                                              marginRight: 4,
                                              color: "#253c57",
                                              fontFamily: "Extra-light 200",
                                            }}
                                          >
                                            {cats}
                                          </Text>
                                          <TouchableOpacity
                                            key={index}
                                            onPress={() =>
                                              this.removeMultiTag("cats", index)
                                            }
                                          >
                                              <FontAwesomeIcon style={{ color: "black", height: "13", width: "13",marginTop: "2px",
                                        marginLeft: "11%", }} icon={faXmark} />
                                            {/* <Image
                                              style={{
                                                width: 10,
                                                height: 10,
                                                marginTop: "2px",
                                              }}
                                              source={require("../../../assets/cross-new-blue.png")}
                                            /> */}

                                            {/* <Text style={{ color: "#000000", fontWeight: "800", marginTop: "1px" }}>X </Text> */}
                                          </TouchableOpacity>
                                        </View>
                                      )
                                    );
                                  }
                                )}
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              FNSKU
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "fnsku"
                                )
                              }
                              value={basicinfo.fnsku}
                            ></TextInput>
                          </View>
                        </View>

                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              ASIN
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(text, "asin")
                              }
                              value={basicinfo.asin}
                            ></TextInput>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              FBA-UPC
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, _name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "fba_upc"
                                )
                              }
                              value={basicinfo.fba_upc}
                            ></TextInput>
                          </View>
                        </View>

                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              ISBN
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(text, "isbn")
                              }
                              value={basicinfo.isbn}
                            ></TextInput>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              EAN
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(text, "ean")
                              }
                              value={basicinfo.ean}
                            ></TextInput>
                          </View>
                        </View>

                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Supplier SKU
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "supplier_sku"
                                )
                              }
                              value={basicinfo.supplier_sku}
                            ></TextInput>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              AVG Cost
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "avg_cost"
                                )
                              }
                              value={basicinfo.avg_cost}
                              keyboardType={"numeric"}
                            ></TextInput>
                          </View>
                        </View>

                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Count Group
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "count_group"
                                )
                              }
                              value={basicinfo.count_group}
                            ></TextInput>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Product Weight
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "weight"
                                )
                              }
                              value={basicinfo.weight}
                              keyboardType={"numeric"}
                            ></TextInput>
                          </View>
                        </View>
                      </>
                    )}
                  </View>
                </View>
              </>
            )}
          </>
        ) : (
          <>
            {basicinfo && (
              <>
                <View
                  style={[
                    this.state.windowWidth < 450
                      ? { justifyContent: "center", alignItems: "center" }
                      : { marginLeft: "30px", marginTop: "-35px", position: "relative", zIndex: 9 },
                  ]}
                >
                  {images != undefined && images?.length > 0 && images?.map((img, index) => {
                    return (
                      <>
                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                          {this.state.ModalShow ? (
                            <View style={{
                              // zIndex:99,
                              position: "absolute",
                              // top: 10,
                              // zIndex: 1,
                              // backgroundColor: "rgba(14, 14 , 14 , 0.7)",
                              justifyContent: "center",
                              alignItems: "center",
                              // height: "100%",
                              width: 300,
                              marginTop: this.state.windowWidth >= 445 && this.state.windowWidth <= 900 ? 30 : -20,
                              height: 100
                              // left: "20%"
                            }}>
                              <LinearGradient
                                locations={[0, 1]}
                                colors={["#7790a7", "#7790a7"]}
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  backgroundColor: "#b7b7b7",
                                  padding: 5,
                                  borderRadius: 10,
                                  width: "100%",
                                  marginLeft: "",
                                  marginRight: "",
                                  padding: 10,
                                  shadowColor: "#000",
                                  shadowOffset: { width: 0, height: 0 },
                                  shadowOpacity: 10,
                                  zIndex: 99,
                                  shadowRadius: 10,
                                }}
                              >
                                <Text
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                    color: "#cdcdcd",
                                    fontFamily: "Poppins_600SemiBold",
                                    fontSize: "16px",
                                  }}
                                >
                                  Are you sure to remove image ?
                                </Text>
                                <TouchableOpacity
                                  style={{
                                    height: 28,
                                    width: 56,
                                    borderRadius: 1,
                                    backgroundColor: "#536474",
                                    padding: 5,
                                    borderRadius: 10,
                                    marginLeft: 20
                                  }}
                                  onPress={() => {
                                    this.setState({ ModalShow: false });
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                      fontFamily: "Poppins_600SemiBold",
                                      marginLeft: 2
                                    }}
                                  >
                                    cancel
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  style={{
                                    height: 28,
                                    width: 35,
                                    borderRadius: 1,
                                    backgroundColor: "#536474",
                                    padding: 5,
                                    borderRadius: 10,
                                    marginLeft: 10
                                  }}
                                  onPress={() => {
                                    this.imageRemove(img?.id);
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                     fontFamily: "Poppins_600SemiBold"
                                      // marginRight: "20%"
                                    }}
                                  >
                                    OK
                                  </Text>
                                </TouchableOpacity>
                              </LinearGradient>
                            </View>
                          ) : (
                            <View></View>
                          )}
                        </View></>
                    )
                  })}
                  {/* {images.length > 0 &&
                    images.map((img, index) => {
                      return (
                        <>
                          {this.state.ModalShow ? (
                            <View style={[styles.alertContainer]}>
                              <LinearGradient
                                locations={[0, 1]}
                                colors={["#7790a7", "#7790a7"]}
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  backgroundColor: "#b7b7b7",
                                  padding: 5,
                                  borderRadius: 10,
                                  width: "120%",
                                  marginLeft: "900px",
                                  marginRight: "",
                                  padding: 10,
                                  shadowColor: "#000",
                                  shadowOffset: { width: 0, height: 0 },
                                  shadowOpacity: 10,
                                  shadowRadius: 10,
                                }}
                              >
                                <Text
                                  style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                    fontWeight: "bold",
                                    fontSize: 16,
                                    color: "#1a73e8",
                                  }}
                                >
                                  hello
                                </Text>
                                <TouchableOpacity
                                  style={{
                                    height: 28,
                                    width: 56,
                                    borderRadius: 1,
                                    backgroundColor: "#1a73e8",
                                    padding: 5,
                                    borderRadius: 10,
                                  }}
                                  onPress={() => {
                                    this.setState({ ModalShow: false });
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    cancel
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  style={{
                                    height: 28,
                                    width: 35,
                                    borderRadius: 1,
                                    backgroundColor: "#1a73e8",
                                    padding: 5,
                                    borderRadius: 10,
                                  }}
                                  onPress={() => {
                                    this.imageRemove(img.id);
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#fff",
                                      fontSize: 14,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    OK
                                  </Text>
                                </TouchableOpacity>
                              </LinearGradient>
                            </View>
                          ) : (
                            <View></View>
                          )}
                        </>
                      );
                    })} */}

                  {/* <View style={{ flexDirection: "row", alignSelf: "flex-end", marginRight: 10, marginLeft: 10, padding: 10 }}>
                    <TouchableOpacity onPress={() => this.props.updateProduct()}><Text style={{ padding: 10, color: "#fff", fontWeight: "bold", borderRadius: 20, marginRight: 10 }}>Save & Close</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => this.props.navigation.goBack()}><Text style={{ padding: 10, color: "#fff", fontWeight: "bold", borderRadius: 20 }}>Close </Text></TouchableOpacity>
                  </View> */}

                  <Text style={[{ color: "#67869B", fontWeight: "bold", fontFamily: "Poppins_700Bold", fontSize: "18px",background: " -webkit-linear-gradient(#4b657d  , #768ea5)",
                      webkitBackgroundClip: "text", webkitTextFillColor: "transparent" }]}>
                    {this.props.product?.skus?.[0]?.sku}
                  </Text>
                </View>
                <View style={{ width: "auto" }}>
                  <TouchableOpacity onPress={() => this.contentLessMobile()}>
                    <View style={{ flexDirection: "row", marginLeft: 23 }}>
                      <Text
                        style={{
                          color: "#cdcdcd",
                          fontFamily: "Poppins_600SemiBold",
                          fontSize: "18px",
                        }}
                      >
                        {this.state.lessMoreMobile ? "Edit" : "Edit"}
                      </Text>
                      <Image
                        style={{ width: 25, height: 25, resizeMode: "contain" }}
                        source={this.state.lessMoreMobile ? upArrow : downArrow}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
                {this.state.lessMoreMobile && (
                  <>
                    <View style={{ flexDirection: "column", marginBottom: 5 }}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "18px",
                          marginLeft: "25px",
                        }}
                      >
                          <FontAwesomeIcon style={{ color: "white", height: "17", width: "17" }} icon={faArrowRightToBracket} />
                        {/* <Image
                          style={{
                            width: 20,
                            height: 20,
                            resizeMode: "contain",
                          }}
                          source={require("../../../assets/arrow-right.png")}
                        /> */}
                        <Text style={{ color: "white",marginLeft: "8px"}}>
                          Make this product an alias of another
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "10px",
                          marginLeft: "25px",
                        }}
                      >
                          <FontAwesomeIcon style={{ color: "white", height: "17", width: "17" }} icon={faArrowRightFromBracket} />
                        {/* <Image
                          style={{
                            width: 20,
                            height: 20,
                            resizeMode: "contain",
                          }}
                          source={require("../../../assets/arrow-left.png")}
                        /> */}
                        <Text style={{ color: "white",marginLeft:"8px" }}>
                          Choose products that are aliases of this product
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "10px",
                          marginLeft: "25px",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            this.changeProductKit();
                          }}
                        >
                           <FontAwesomeIcon style={{ color: "white", height: "17", width: "17" }} icon={faBriefcase} />
                          {/* <Image
                            style={{
                              width: 20,
                              height: 20,
                              resizeMode: "contain",
                            }}
                            source={require("../../../assets/briefcase.png")}
                          /> */}
                          <Text
                            style={{
                              color: "white",
                              marginTop: "-18px",
                              marginLeft: "25px",
                            }}
                          >
                            Change this product to a kit
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </>
                )}

                <View style={{ flexDirection: "row", marginLeft: "18%" }}>
                  <View>
                    <ScrollView
                      style={{
                        width: this.state.windowWidth > 500 ? 200 : 100,
                      }}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                    >
                      {images?.length > 0 &&
                        images?.map((img, index) => {
                          return (
                            <>
                              {/* {this.state.image && this.state.imageflag
                            ?
                            <Image source={{ uri: this.state.image }} style={{ width: 80, height: 80 }} />
                            : */}
                              <Image
                                style={{ width: 90, height: 90 }}
                                source={{ uri: img?.image ? img?.image : "" }}
                                key={index}
                              />
                              {/* } */}

                              <TouchableOpacity
                                onPress={() => this.setState({ ModalShow: true })}
                              >
                                <Text
                                  style={{
                                    color: "white",
                                    marginLeft: "-118%",
                                    marginTop: "160%",
                                    fontFamily: "Poppins_600SemiBold",
                                  }}
                                >
                                  Remove
                                </Text>
                              </TouchableOpacity>
                            </>
                          );
                        })}
                    </ScrollView>
                  </View>
                  <TouchableOpacity onPress={this.pickImage} >
                    <View
                      style={{
                        height: 60,
                        width: 70,
                        marginTop: 45,
                        marginLeft: 85,
                      }}
                    >
                      <LinearGradient
                        colors={[
                          // "#000",
                          // "#000",
                          "#5c778f",
                          "#5c778f",
                          // "#253c57",
                          // "#253c57",
                          "#000",
                        ]}
                        start={[-0.5, -1]}
                        style={{
                          marginTop: "-66%",
                          marginLeft: "-67px",
                          alignItems: "center",
                          justifyContent: "center",
                          flex: 1,
                          borderWidth: 1,
                          borderRadius: 5,
                          borderColor: "#869db1",
                          borderBottomWidth: 0,
                          shadowColor: "#ffff",
                          shadowOffset: { width: 0, height: 2 },
                          shadowOpacity: 0.9,
                          shadowRadius: 3,
                          elevation: 3,
                          width: 90,
                          // overflow: "scroll",
                          // paddingHorizontal: 15,
                          // width: windowWidth,
                          // height: windowHeight,
                          paddingBottom: 15,
                          zIndex: 1
                        }}
                      >
                          <FontAwesomeIcon style={{ color: "white", height: "80%", width: "120%" }} icon={faPlus} />
                        {/* <Image
                          style={{ height: 50, width: 45 }}
                          source={require("../../../assets/plus-white-thickness.png")}
                        /> */}
                        <Text
                          style={{
                            color: "white",
                            fontFamily: "Poppins_600SemiBold",
                            marginBottom: "-30px",
                          }}
                        >
                          Add Image
                        </Text>
                      </LinearGradient>
                    </View>
                  </TouchableOpacity>
                </View>

                {inventoryWarehouses &&
                  inventoryWarehouses.length > 0 &&
                  inventoryWarehouses.map((item, index) => {
                    return (
                      <>
                        <View
                          style={{
                            margin: 10,
                            marginTop: "4%",
                            backgroundColor: "#rgba(104, 135, 156, 0.3)",
                            borderWidth: 1,
                            borderRadius: 10,
                            border: "none",
                          }}
                        >
                          <View style={{ margin: 10, flexDirection: "row" }}>
                            <Text
                              style={{
                                marginRight: "-69px",
                                marginLeft: "20px",
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              QOH
                            </Text>
                            <TextInput
                              style={{
                                margin: 15,
                                marginTop: "25px",
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                width: "50%",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.inventoryWarehousesHandleChange(
                                  text,
                                  "quantity_on_hand"
                                )
                              }
                              value={item.info.quantity_on_hand}
                              keyboardType={"numeric"}
                            ></TextInput>
                            <Text
                              style={{
                                marginRight: "-86px",
                                marginLeft: "15px",
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Location1
                            </Text>
                            <TextInput
                              style={{
                                margin: 15,
                                marginTop: "25px",
                                marginLeft: "-18px",
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                width: "50%",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.inventoryWarehousesHandleChange(
                                  text,
                                  "location_primary"
                                )
                              }
                              value={item.info.location_primary}
                            ></TextInput>
                          </View>
                          <TouchableOpacity onPress={() => this.contentLess()}>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                
                              }}
                            >
                              <Text
                                style={{
                                  color: "#cdcdcd",
                                  fontFamily: "Poppins_600SemiBold",
                                  fontSize: "18px",
                                }}
                              >
                                {this.state.lessMore ? "less" : "More"}
                              </Text>
                              <Image
                                style={{
                                  width: 25,
                                  height: 25,
                                  resizeMode: "contain",
                                }}
                                source={
                                  this.state.lessMore ? upArrow : downArrow
                                }
                              />

                              {/* <Image style={{height: "10%",width: "10%",marginTop: "10%"}} source={require("../../../assets/plus-gray.png")}></Image> */}
                            </View>
                          </TouchableOpacity>
                          {this.state.lessMore && (
                            <>
                              <View
                                style={{ margin: 10, flexDirection: "row" }}
                              >
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: "49%",
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#cdcdcd",
                                      fontFamily: "Poppins_600SemiBold",
                                      fontSize: "18px",
                                      marginLeft: "17px",
                                    }}
                                  >
                                    Location 1
                                  </Text>
                                  <TextInput
                                    style={{
                                      borderWidth: 1,
                                      border: "none",
                                      height: "31px",
                                      borderRadius: 10,
                                      padding: 10,
                                      backgroundColor: "#cdcdcd",
                                      color: "#1d3045",
                                      fontFamily: "Extra-light 200",
                                    }}
                                    onChangeText={(text, name) =>
                                      this.props.inventoryWarehousesHandleChange(
                                        text,
                                        "location_primary"
                                      )
                                    }
                                    value={item.info.location_primary}
                                  ></TextInput>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: "49%",
                                    marginLeft: 14,
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#cdcdcd",
                                      fontFamily: "Poppins_600SemiBold",
                                      fontSize: "18px",
                                      marginLeft: "17px",
                                    }}
                                  >
                                    Location 1 Qty
                                  </Text>
                                  <TextInput
                                    style={{
                                      borderWidth: 1,
                                      border: "none",
                                      height: "31px",
                                      borderRadius: 10,
                                      padding: 10,
                                      backgroundColor: "#cdcdcd",
                                      color: "#1d3045",
                                      fontFamily: "Extra-light 200",
                                    }}
                                    onChangeText={(text, name) =>
                                      this.props.inventoryWarehousesHandleChange(
                                        text,
                                        "location_primary_qty"
                                      )
                                    }
                                    value={item.info.location_primary_qty}
                                    keyboardType={"numeric"}
                                  ></TextInput>
                                </View>
                              </View>

                              <View
                                style={{ margin: 10, flexDirection: "row" }}
                              >
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: "49%",
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#cdcdcd",
                                      fontFamily: "Poppins_600SemiBold",
                                      fontSize: "18px",
                                      marginLeft: "17px",
                                    }}
                                  >
                                    Location 2
                                  </Text>
                                  <TextInput
                                    style={{
                                      borderWidth: 1,
                                      border: "none",
                                      height: "31px",
                                      borderRadius: 10,
                                      padding: 10,
                                      backgroundColor: "#cdcdcd",
                                      color: "#1d3045",
                                      fontFamily: "Extra-light 200",
                                    }}
                                    onChangeText={(text, name) =>
                                      this.props.inventoryWarehousesHandleChange(
                                        text,
                                        "location_secondary"
                                      )
                                    }
                                    value={item.info.location_secondary}
                                  ></TextInput>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: "49%",
                                    marginLeft: 14,
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#cdcdcd",
                                      fontFamily: "Poppins_600SemiBold",
                                      fontSize: "18px",
                                      marginLeft: "17px",
                                    }}
                                  >
                                    Location 2 Qty
                                  </Text>
                                  <TextInput
                                    style={{
                                      borderWidth: 1,
                                      border: "none",
                                      height: "31px",
                                      borderRadius: 10,
                                      padding: 10,
                                      backgroundColor: "#cdcdcd",
                                      color: "#1d3045",
                                      fontFamily: "Extra-light 200",
                                    }}
                                    onChangeText={(text, name) =>
                                      this.props.inventoryWarehousesHandleChange(
                                        text,
                                        "location_secondary_qty"
                                      )
                                    }
                                    value={item.info.location_secondary_qty}
                                    keyboardType={"numeric"}
                                  ></TextInput>
                                </View>
                              </View>

                              <View
                                style={{ margin: 10, flexDirection: "row" }}
                              >
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: "49%",
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#cdcdcd",
                                      fontFamily: "Poppins_600SemiBold",
                                      fontSize: "18px",
                                      marginLeft: "17px",
                                    }}
                                  >
                                    Location 3
                                  </Text>
                                  <TextInput
                                    style={{
                                      borderWidth: 1,
                                      border: "none",
                                      height: "31px",
                                      borderRadius: 10,
                                      padding: 10,
                                      backgroundColor: "#cdcdcd",
                                      color: "#1d3045",
                                      fontFamily: "Extra-light 200",
                                    }}
                                    onChangeText={(text, name) =>
                                      this.props.inventoryWarehousesHandleChange(
                                        text,
                                        "location_tertiary"
                                      )
                                    }
                                    value={item.info.location_tertiary}
                                  ></TextInput>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: "49%",
                                    marginLeft: 14,
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: "#cdcdcd",
                                      fontFamily: "Poppins_600SemiBold",
                                      fontSize: "18px",
                                      marginLeft: "17px",
                                    }}
                                  >
                                    Location 3 Qty
                                  </Text>
                                  <TextInput
                                    style={{
                                      borderWidth: 1,
                                      border: "none",
                                      height: "31px",
                                      borderRadius: 10,
                                      padding: 10,
                                      backgroundColor: "#cdcdcd",
                                      color: "#1d3045",
                                      fontFamily: "Extra-light 200",
                                    }}
                                    onChangeText={(text, name) =>
                                      this.props.inventoryWarehousesHandleChange(
                                        text,
                                        "location_tertiary_qty"
                                      )
                                    }
                                    value={item.info.location_tertiary_qty}
                                    keyboardType={"numeric"}
                                  ></TextInput>
                                </View>
                              </View>
                            </>
                          )}
                        </View>
                      </>
                    );
                  })}

                <View
                  style={{
                    margin: 10,
                    backgroundColor: "#rgba(104, 135, 156, 0.3)",
                    borderWidth: 1,
                    borderRadius: 10,
                    border: "none",
                  }}
                >
                  <Text
                    style={{
                      marginLeft: "15px",
                      color: "#cdcdcd",
                      fontFamily: "Poppins_600SemiBold",
                      fontSize: "18px",
                    }}
                  >
                    Product Name
                  </Text>
                  <TextInput
                    style={{
                      margin: 15,
                      marginTop: "3px",
                      borderWidth: 1,
                      border: "none",
                      height: "31px",
                      borderRadius: 10,
                      padding: 10,
                      backgroundColor: "#cdcdcd",
                      width: "94%",
                      color: "#1d3045",
                      fontFamily: "Extra-light 200",
                    }}
                    onChangeText={(text, name) =>
                      this.props.productInfoHandleChange(text, "name")
                    }
                    value={basicinfo.name}
                  />
                  <Text
                    style={{
                      marginLeft: "15px",
                      color: "#cdcdcd",
                      fontFamily: "Poppins_600SemiBold",
                      fontSize: "18px",
                    }}
                  >
                    SKUs
                  </Text>
                  <TextInput
                    style={{
                      margin: 15,
                      marginTop: "3px",
                      borderWidth: 1,
                      border: "none",
                      height: "31px",
                      borderRadius: 10,
                      padding: 10,
                      backgroundColor: "#cdcdcd",
                      width: "94%",
                      color: "#1d3045",
                      fontFamily: "Extra-light 200",
                    }}
                    onChangeText={(text) => {
                      this.setState({ skusMultiInput: text });
                    }}
                    value={this.state.skusMultiInput}
                    onSubmitEditing={() => this.updateMultiTag("skus")}
                  />
                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: 14,
                      marginRight: 10,
                    }}
                  >
                    {this.state.skusLocal.length > 0 &&
                      this.state.skusLocal.map((sku, index) => {
                        return (
                          sku !== "" && (
                            <View
                              key={index}
                              style={{
                                backgroundColor: "#cdcdcd",
                                padding: 5,
                                borderRadius: 10,
                                borderWidth: 1,
                                marginRight: 8,
                                flexDirection: "row",
                                border: "none",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              >
                              <Text
                                style={{
                                  marginRight: 4,
                                  color: "#253c57",
                                  fontFamily: "Extra-light 200",
                                  
                                }}
                              >
                                {sku}
                              </Text>
                              <TouchableOpacity
                                key={index}
                                onPress={() =>
                                  this.removeMultiTag("sku", index)
                                }
                              >
                                {/* <Text style={{ color: "#000000", fontWeight: "800", marginTop: "3px" }}> X </Text> */}
                                <FontAwesomeIcon style={{ color: "black", height: "12", width: "12" }} icon={faXmark} />
                                {/* <Image
                                  style={{
                                    width: 10,
                                    height: 10,
                                    marginTop: "2px",
                                  }}
                                  source={require("../../../assets/cross-new-blue.png")}
                                /> */}
                              </TouchableOpacity>
                            </View>
                          )
                        );
                      })}
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "60%" }}>
                      <View style={{ flexDirection: "row", width: " 120%" }}>
                        <Text
                          style={{
                            color: "#cdcdcd",
                            fontFamily: "Poppins_600SemiBold",
                            fontSize: "18px",
                            marginTop: "1%",
                            marginLeft: "2%",
                          }}
                        >
                          Barcodes
                        </Text>

                        <View
                          style={{
                            position: "absolute",
                            right: -45,
                            marginTop: "1%",
                          }}
                        >
                          <Text
                            style={{
                              color: "#cdcdcd",
                              fontFamily: "Poppins_600SemiBold",
                              fontSize: "18px",
                            }}
                          >
                            Scan Qty
                          </Text>
                        </View>
                      </View>
                      {
                        // this.state.barcodesLocal.length > 0 && this.state.barcodesLocal.map((barcode, index) => {
                        barcodenew.map((barcode, index) => {
                          return (
                            barcode !== "" && (
                              <>
                                <View style={{ flexDirection: "row" }}>
                                  <View style={{ width: "100%" }}>
                                    <TextInput
                                      style={{
                                        margin: 15,
                                        marginTop: "3px",
                                        borderWidth: 1,
                                        border: "none",
                                        height: "31px",
                                        borderRadius: 10,
                                        padding: 10,
                                        backgroundColor: "#cdcdcd",
                                        color: "#1d3045",
                                        fontFamily: "Extra-light 200",
                                        width: "100%",
                                      }}
                                      onChangeText={(text) => {
                                        this.setState({ multiInput: text });
                                      }}
                                      value={barcode.barcode}
                                      onSubmitEditing={() =>
                                        this.updateMultiTag("barcode")
                                      }
                                    />

                                    <TouchableOpacity
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        marginTop: -5,
                                      }}
                                      key={index}
                                      onPress={() =>
                                        this.removeMultiTag("barcode", index)
                                      }
                                    >
                                      {/* <Text style={{ color: "#000000", fontWeight: "800", marginLeft: "94%" }}> X </Text> */}
                                      
                                      <FontAwesomeIcon style={{ color: "black", height: "17", width: "17",marginTop: "14px",
                                        marginLeft: "50%", }} icon={faXmark} />
                                      
                                      {/* <Image
                                        style={{
                                          width: 10,
                                          height: 10,
                                          marginTop: "18px",
                                        }}
                                        source={require("../../../assets/cross-new-blue.png")}
                                      /> */}
                                    </TouchableOpacity>
                                  </View>
                                  <View
                                    style={{ width: "40%", marginLeft: "12%" }}
                                  >
                                    <TextInput
                                      style={{
                                        borderWidth: 1,
                                        border: "none",
                                        height: "31px",
                                        borderRadius: 10,
                                        padding: 10,
                                        backgroundColor: "#cdcdcd",
                                        color: "#1d3045",
                                        fontFamily: "Extra-light 200",
                                        marginTop: "3px",
                                      }}
                                      value={barcode.packing_count}
                                    ></TextInput>
                                  </View>
                                  <TouchableOpacity
                                    onPress={() => this.submitProduct()}
                                  >
                                      <FontAwesomeIcon style={{ color: "white", height: "20", width: "20" ,marginTop: "34%",
                                        marginLeft: "60%", }} icon={faPrint} />
                                    {/* <Image
                                      style={{
                                        width: 20,
                                        height: 20,
                                        resizeMode: "contain",
                                        marginTop: 1,
                                        marginLeft: 2,
                                        marginTop: "3px",
                                      }}
                                      source={require("../../../assets/printer-w.png")}
                                    /> */}
                                  </TouchableOpacity>
                                </View>
                              </>
                            )
                          );
                        })
                      }
                    </View>
                  </View>
                  <View style={{ marginLeft: "48%" }}>
                    {this.state.notesInternal == false ? (
                      <TouchableOpacity
                        onPress={() => {
                          this.openTextField();
                        }}
                      >
                         <FontAwesomeIcon style={{ color: "white",  width: 40, height: 40 }} icon={faPlus} />
                        {/* <Image
                          style={{ width: 40, height: 40 }}
                          source={require("../../../assets/plus-white-thickness.png")}
                        /> */}
                      </TouchableOpacity>
                    ) : (
                      <TextInput
                        style={{
                          marginLeft: "-90%",
                          marginRight: "3%",
                          marginTop: "3px",
                          borderWidth: 1,
                          border: "none",
                          height: "31px",
                          borderRadius: 10,
                          padding: 10,
                          backgroundColor: "#cdcdcd",
                          color: "#1d3045",
                          fontFamily: "Extra-light 200",
                        }}
                        onChangeText={(text) => {
                          this.setState({ multiInput: text });
                        }}
                        value={this.state.multiInput}
                        onSubmitEditing={() => this.updateMultiTag("barcode")}
                      />
                    )}
                  </View>
                  <View>
                    <TouchableOpacity onPress={() => this.contentLessd()}>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "34px",
                          marginTop:"3%"
                        }}
                      >
                        <Text
                          style={{
                            color: "#cdcdcd",
                            fontFamily: "Poppins_600SemiBold",
                            fontSize: "18px",
                          }}
                        >
                          {this.state.lessMored ? "less" : "More"}
                        </Text>
                        <Image
                          style={{
                            width: 25,
                            height: 25,
                            resizeMode: "contain",
                          }}
                          source={this.state.lessMored ? upArrow : downArrow}
                        />
                      </View>
                    </TouchableOpacity>
                    {this.state.lessMored && (
                      <>
                        <View
                          style={{
                            margin: 10,
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Categories
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text) => {
                                this.setState({ categoriesMultiInput: text });
                              }}
                              value={this.state.categoriesMultiInput}
                              onSubmitEditing={() =>
                                this.updateMultiTag("cats")
                              }
                            ></TextInput>
                            <View
                              style={{
                                flexDirection: "row",
                                marginLeft: "5px",
                                marginTop: "2%",
                              }}
                            >
                              {/* <View style={{ width: 180 }}>
                                <ScrollView
                                  horizontal={true}
                                  showsHorizontalScrollIndicator={false}
                                  showsVerticalScrollIndicator={false}

                                > */}
                              {this.state.categoriesLocal.length > 0 &&
                                this.state.categoriesLocal.map(
                                  (cats, index) => {
                                    return (
                                      cats !== "" && (
                                        <View
                                          key={index}
                                          style={{
                                            backgroundColor: "#cdcdcd",
                                            padding: 5,
                                            borderRadius: 10,
                                            borderWidth: 1,
                                            marginRight: 8,
                                            flexDirection: "row",
                                            border: "none",
                                          }}
                                        >
                                          <Text
                                            style={{
                                              marginRight: 4,
                                              color: "#253c57",
                                              fontFamily: "Extra-light 200",
                                            }}
                                          >
                                            {cats}
                                          </Text>
                                          <TouchableOpacity
                                            key={index}
                                            onPress={() =>
                                              this.removeMultiTag("cats", index)
                                            }
                                          >
                                             <FontAwesomeIcon style={{ color: "black", height: "13", width: "13",marginTop: "2px",
                                        marginLeft: "11%", }} icon={faXmark} />


                                            {/* <Image
                                              style={{
                                                width: 10,
                                                height: 10,
                                                marginTop: "2px",
                                              }}
                                              source={require("../../../assets/cross-new-blue.png")}
                                            /> */}
                                            {/* <Text style={{ color: "#000000", fontWeight: "800", marginTop: "3px" }}>X </Text> */}
                                          </TouchableOpacity>
                                        </View>
                                      )
                                    );
                                  }
                                )}
                              {/* </ScrollView></View> */}
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "column",
                              width: "45%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              FNSKU
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "fnsku"
                                )
                              }
                              value={basicinfo.fnsku}
                            ></TextInput>
                          </View>
                        </View>

                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              ASIN
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(text, "asin")
                              }
                              value={basicinfo.asin}
                            ></TextInput>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              FBA-UPC
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, _name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "fba_upc"
                                )
                              }
                              value={basicinfo.fba_upc}
                            ></TextInput>
                          </View>
                        </View>

                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              ISBN
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(text, "isbn")
                              }
                              value={basicinfo.isbn}
                            ></TextInput>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              EAN
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(text, "ean")
                              }
                              value={basicinfo.ean}
                            ></TextInput>
                          </View>
                        </View>

                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Supplier SKU
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "supplier_sku"
                                )
                              }
                              value={basicinfo.supplier_sku}
                            ></TextInput>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              AVG Cost
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "avg_cost"
                                )
                              }
                              value={basicinfo.avg_cost}
                              keyboardType={"numeric"}
                            ></TextInput>
                          </View>
                        </View>

                        <View style={{ margin: 10, flexDirection: "row" }}>
                          <View
                            style={{ flexDirection: "column", width: "49%" }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Count Group
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "count_group"
                                )
                              }
                              value={basicinfo.count_group}
                            ></TextInput>
                          </View>
                          <View
                            style={{
                              flexDirection: "column",
                              width: "49%",
                              marginLeft: 14,
                            }}
                          >
                            <Text
                              style={{
                                color: "#cdcdcd",
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: "18px",
                              }}
                            >
                              Product Weight
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                border: "none",
                                height: "31px",
                                borderRadius: 10,
                                padding: 10,
                                backgroundColor: "#cdcdcd",
                                color: "#1d3045",
                                fontFamily: "Extra-light 200",
                              }}
                              onChangeText={(text, name) =>
                                this.props.productInfoHandleChange(
                                  text,
                                  "weight"
                                )
                              }
                              value={basicinfo.weight}
                              keyboardType={"numeric"}
                            ></TextInput>
                          </View>
                        </View>
                      </>
                    )}
                  </View>
                </View>
              </>
            )}
          </>
        )}
      </View>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    AddImage: state.product.AddImage,
  };
};

const mapDispatchToProps = {
  printScan,
  addImage,
  UpdateProductInfo,
  GetProductDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo);
