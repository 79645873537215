export const signSavedMsg = 'Saved Successfully'
export const signEnterNameMsg = 'Enter Name in Field'
export const scanConfirmationPopMsg = "This is a note to packer. Confirmation is reqiuired for this note."
export const scanConfirmationPopErrMsg = "Enter or scan your confirmation code to continue"
export const ScanPackItemtrackingMsg = "The tracking number barcode you scanned does not match any of the saved prefixes. Please check your tracking validation settings or contact an admin."
export const ScanpackQtyExceedMsgPart1 = "The quantity entered exceeds the remaining"
export const ScanpackQtyExceedMsgPart2 = "pieces in the order. Please try again"
export const ScanPackSuccessMsg = "Save Changes Successful"
export const ScanPackTypeCountMsg = "Please scan the item once before triggering a type-in count."
export const ScanPackMultiPackMsg = "The multi-pack barcode scanned exceeds the quantity remaining"
export const ScanPackPassFunMsg = "The pass function is disabled"
export const ScanPackRequiredSerialMsg = "The Value Scanned does not appear to be a Valid serial or lot number. Please check the 'Require Serial/Lot Prefix' setting in your scan and pack options."
export const ScanOrderAlreadyMsg = "The order has already been scanned."