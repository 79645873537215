import React, { Component } from 'react';
import { View, Text, TextInput, ActivityIndicator, TouchableOpacity, Platform, ImageBackground, Dimensions, Image, ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { connect } from 'react-redux';
import NetInfo from "@react-native-community/netinfo";
import { GetOrderList } from "../../actions/orderActions";
import { SearchScanpackOrder, ImportOrder, ImportOrderStatus } from "../../actions/scanpackAction";
import { UserInfo } from "../../actions/userAction";
import styles from '../../style/scanpack';
import PopUpModel from "./pop_up_model";
import { LinearGradient } from 'expo-linear-gradient';
import { fontFamily } from '../../helpers/fontFamily';
import moment from 'moment';
import { ScanOrderAlreadyMsg } from "../notification"
const height = Dimensions.get('window').height
let orderListSuccess = false;
let searchOrderSuccess = false;
let validateUser = false
let messageShow = false
let importDates = false
class ScanPack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowwidth: 0,
      toolTipVisible: false,
      data: {
        "input": "",
        "state": "scanpack.rfo",
        "id": null,
        "box_id": null,
        "store_order_id": null,
        "app": "app"
      },
      popUpModelShow: false,
      dataList: {
        "filter": "",
        "order": "DESC",
        "limit": "1",
        "offset": "0",
        "app": "app",
        "count": 0
      },
      orderDetail: null,
      rfoTitle: "Scan the Packing Slip of an Order you'd like to Pack.",
      time: new Date(),
      loading: false,
      loaderTitle: "",
      userInfo: "",
      scan_pack_settings: null,
      quickScanToolTip: false,
      importOrderText: '',
      animatingType: false,
      cueOrderStatus: "",
      importButton: true,
      EtaText: '',
      ShowEtaText: false
    };
    this.inputFocus = React.createRef();
    this.searchOrder = this.searchOrder.bind(this);
    window.addEventListener("resize", this.update);
    // importDate = true

  }
  componentDidMount() {
    this.props.UserInfo()
    this.props.ImportOrderStatus()
    this.setState({ loading: true, loaderTitle: "Loading..." })
    this.getOrder()
    validateUser = true
    this.update()

  }
  update = () => {
    this.setState({
      windowwidth: window.innerWidth
    })
  }
  //According to the platform call the function
  getOrder = () => {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      this.checkConnection("apiCall")
    } else {
      this.apiCall()
    }
  }
  /////////////Alert DialogBox open 
  alertBox = (message, skip, messageTypeSuccess) => {
    // console.log("---000msg--", message)
    if (messageTypeSuccess === true) {
      this.setState({ popUpModelShow: true, message: message, messageTypeSuccess: true })
    } else {
      this.setState({ popUpModelShow: true, message: message })
    }
    setTimeout(function () {
      this.setState({ popUpModelShow: false, message: "", messageTypeSuccess: false });
    }.bind(this), 4000);
  }
  //Check the connection for the ios and android
  checkConnection = async (type, input) => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      type === "callShipmentOrder" ? this.callShipmentOrder(input) : this.apiCall()
    } else {
      this.setState({ rfoTitle: "Please check your internet connection" })
    }
  }
  //Call the api to fetch the data from backend
  apiCall = () => {
    this.props.GetOrderList(this.state.dataList);
    orderListSuccess = true
  }
  componentWillUpdate(pre) {
    // if(importDates == true){
    //   console.log("props scan will",this.props.importOrder.importstatusData)
    //   importDates = false
    // }
    // if (!pre.importOrder === this.props.importOrder) {
    // console.log(pre?.importOrder?.importdata?.error_messages,"=======================_________ ",this.props?.importOrder?.importdata?.error_messages)
    // }
  }
  checkEstimateFun = (data) => {
    // console.log("data here-->", data)
    data.import_items.map((estimate) => {
      // console.log("estimate", estimate)
      if (estimate.progress !== null && estimate.progress.status !== false) {
        let storeName = estimate?.store_info?.name
        if (estimate?.progress?.elapsed_time_remaining) {
          if(estimate?.progress?.elapsed_time_remaining.substring(0,2) == "00" && estimate?.progress?.elapsed_time_remaining.substring(3,5) == "00" && estimate?.progress?.elapsed_time_remaining.substring(6,8) != "00"){
            this.setState({ EtaText: `Estimated time remaining ${estimate?.progress?.elapsed_time_remaining.substring(6,8)} seconds for store ${storeName}` })
          }
          if(estimate?.progress?.elapsed_time_remaining.substring(0,2) == "00" && estimate?.progress?.elapsed_time_remaining.substring(3,5) != "00" && estimate?.progress?.elapsed_time_remaining.substring(6,8) != "00"){
            this.setState({ EtaText: `Estimated time remaining ${estimate?.progress?.elapsed_time_remaining.substring(3,5)} minutes ${estimate?.progress?.elapsed_time_remaining.substring(6,8)} seconds for store ${storeName}` })
          }
          if(estimate?.progress?.elapsed_time_remaining.substring(0,2) == "00" && estimate?.progress?.elapsed_time_remaining.substring(3,5) != "00" && estimate?.progress?.elapsed_time_remaining.substring(6,8) == "00"){
            this.setState({ EtaText: `Estimated time remaining ${estimate?.progress?.elapsed_time_remaining.substring(3,5)} minutes for store ${storeName}` })
          }
          if(estimate?.progress?.elapsed_time_remaining.substring(0,2) !== "00" && estimate?.progress?.elapsed_time_remaining.substring(3,5) == "00" && estimate?.progress?.elapsed_time_remaining.substring(6,8) == "00"){
            this.setState({ EtaText: `Estimated time remaining ${estimate?.progress?.elapsed_time_remaining.substring(0,2)} hours for store ${storeName}` })
          }
          if(estimate?.progress?.elapsed_time_remaining.substring(0,2) !== "00" && estimate?.progress?.elapsed_time_remaining.substring(3,5) != "00" && estimate?.progress?.elapsed_time_remaining.substring(6,8) == "00"){
            this.setState({ EtaText: `Estimated time remaining ${estimate?.progress?.elapsed_time_remaining.substring(0,2)} hours ${estimate?.progress?.elapsed_time_remaining.substring(3,5)} minutes for store ${storeName}` })
          }
          this.setState({ ShowEtaText: true })
        } else {
          this.setState({ EtaText: "Estimated time remaining calculating" })
          this.setState({ ShowEtaText: true })
        }
      }
    })
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log("----->nextprops", nextProps)
    if (nextProps?.importOrder?.importstatusData?.summary?.import_info?.status == 'cancelled' || nextProps?.importOrder?.importstatusData?.summary?.import_info?.status == 'completed') {
      if (nextProps.importOrder.importstatusData?.summary?.import_info?.updated_at) {
        this.setState({ ShowEtaText: false })
        let currentdate = new Date()
        // console.log(moment(currentdate).format('mm'))
        let updatedate = new Date(nextProps.importOrder.importstatusData?.summary?.import_info?.updated_at)
        // console.log(updatedate.getMonth())
        let status = nextProps?.importOrder?.importstatusData?.summary?.import_info?.status

        if (currentdate.getFullYear() !== updatedate.getFullYear()) {
          let days = currentdate - updatedate
          // console.log("----days by update date", (days / (1000 * 60 * 60 * 24)).toFixed(0))
          let d = (days / (1000 * 60 * 60 * 24)).toFixed(0)
          this.setState({ importOrderText: `${status} ${d} days ago` })
        }
        if (currentdate.getFullYear() == updatedate.getFullYear() && currentdate.getMonth() == updatedate.getMonth() && currentdate.getDay() !== updatedate.getDay()) {
          let days = currentdate - updatedate
          let d = (days / (1000 * 60 * 60 * 24)).toFixed(0)
          this.setState({ importOrderText: `${status} ${d} days ago` })
          // console.log("----days by update date", (days / (1000 * 60 * 60 * 24)).toFixed(0))
        }
        if (currentdate.getFullYear() == updatedate.getFullYear() && currentdate.getMonth() !== updatedate.getMonth()) {
          let month = currentdate.getMonth() - updatedate.getMonth()
          this.setState({ importOrderText: `${status} ${month} months ago` })

          //  console.log("----days by update date", days)
        }
        if (currentdate.getFullYear() == updatedate.getFullYear() && currentdate.getMonth() == updatedate.getMonth() && currentdate.getDay() == updatedate.getDay() && currentdate.getHours() !== updatedate.getHours()) {
          let hour = moment(currentdate).format('HH') - moment(updatedate).format('HH')
          let min = moment(currentdate).format('mm') - moment(updatedate).format('mm')
          if ((min).toString().includes("-")) {
            hour = hour - 1
            let minc = 60 - parseInt(moment(updatedate).format('mm'))
            let ss = parseInt(moment(currentdate).format('mm')) + minc
            min = ss

          }
          let hourCheck = hour == 0 ? '' : `${hour} hours`
          let minCheck = min == '0' ? '' : `${min} minutes`
          this.setState({ importOrderText: `${status} ${hourCheck} ${minCheck} ago` })

        }
        if (currentdate.getFullYear() == updatedate.getFullYear() && currentdate.getMonth() == updatedate.getMonth() && currentdate.getDay() == updatedate.getDay() && currentdate.getHours() == updatedate.getHours() && currentdate.getMinutes() !== updatedate.getMinutes()) {
          let minut = moment(currentdate).format('mm') - moment(updatedate).format('mm')
          let minut1 = moment(currentdate).format('m:ss')
          let min2 = moment(updatedate).format('m:ss')
          let sec = moment(currentdate).format('ss') - moment(updatedate).format('ss')
          if ((sec).toString().includes("-")) {
            minut = minut - 1
            let se = 60 - parseInt(moment(updatedate).format('ss'))
            let ss = parseInt(moment(currentdate).format('ss')) + se
            sec = ss
          }
          let minCheck = minut == 0 ? '' : `${minut} minutes`
          let secCheck = sec == '0' ? '' : `${sec} seconds`
          this.setState({ importOrderText: `${status} ${minCheck} ${secCheck} ago` })
          // console.log("----days by update date", minut)

        }
        if (currentdate.getFullYear() == updatedate.getFullYear() && currentdate.getMonth() == updatedate.getMonth() && currentdate.getDay() == updatedate.getDay() && currentdate.getHours() == updatedate.getHours() && currentdate.getMinutes() == updatedate.getMinutes() && currentdate.getSeconds() !== updatedate.getSeconds()) {
          // let minut = moment(currentdate).format('mm') - moment(updatedate).format('mm')
          let sec = moment(currentdate).format('ss') - moment(updatedate).format('ss')
          // let secCheck = sec == '0' ? '' : `and ${sec} seconds`
          this.setState({ importOrderText: `${status} ${sec} seconds ago` })
          // console.log("----days by update date", minut)

        }

      }
      this.setState({
        animatingType: false,
        importButton: true
      })

    }
    if (nextProps?.importOrder?.importstatusData?.summary?.import_info?.status == 'in_progress') {
      this.setState({ importButton: false })
      this.checkEstimateFun(nextProps.importOrder.importstatusData?.summary)
      if (nextProps.importOrder.importstatusData?.summary?.import_info?.created_at) {
        let currentdate = new Date()
        // console.log(moment(currentdate).format('h:mm'))
        let createdate = new Date(nextProps.importOrder.importstatusData?.summary?.import_info?.created_at)
        // console.log(updatedate.getMonth())
        let status = "Import in Progress"
        // nextProps?.importOrder?.importstatusData?.summary?.import_info?.status
        if (currentdate.getFullYear() !== createdate.getFullYear()) {
          let days = currentdate - createdate
          let d = (days / (1000 * 60 * 60 * 24)).toFixed(0)
          this.setState({ importOrderText: `${status} ${d} days ago` })

          // console.log("----days by update date", (days / (1000 * 60 * 60 * 24)).toFixed(0))

        }
        if (currentdate.getFullYear() == createdate.getFullYear() && currentdate.getMonth() == createdate.getMonth() && currentdate.getDay() !== createdate.getDay()) {
          let days = currentdate - createdate
          let d = (days / (1000 * 60 * 60 * 24)).toFixed(0)
          this.setState({ importOrderText: `${status} ${d} days ago` })
          // console.log("----days by update date", (days / (1000 * 60 * 60 * 24)).toFixed(0))
        }
        if (currentdate.getFullYear() == createdate.getFullYear() && currentdate.getMonth() !== createdate.getMonth()) {
          let month = currentdate.getMonth() - createdate.getMonth()
          this.setState({ importOrderText: `${status} ${month} months ago` })

          // console.log("----days by update date", days)
        }
        if (currentdate.getFullYear() == createdate.getFullYear() && currentdate.getMonth() == createdate.getMonth() && currentdate.getDay() == createdate.getDay() && currentdate.getHours() !== createdate.getHours()) {
          let hour = moment(currentdate).format('H') - moment(createdate).format('H')
          let min = moment(currentdate).format('mm') - moment(updatedate).format('mm')
          if ((min).toString().includes("-")) {
            hour = hour - 1
            let minc = 60 - parseInt(moment(updatedate).format('mm'))
            let ss = parseInt(moment(currentdate).format('mm')) + minc
            min = ss

          }
          let hourCheck = hour == 0 ? '' : `${hour} hours`
          let minCheck = min == '0' ? '' : `${min} minutes`
          this.setState({ importOrderText: `${status} ${hourCheck} ${minCheck} ago` })

        }
        if (currentdate.getFullYear() == createdate.getFullYear() && currentdate.getMonth() == createdate.getMonth() && currentdate.getDay() == createdate.getDay() && currentdate.getHours() == createdate.getHours() && currentdate.getMinutes() !== createdate.getMinutes()) {
          let minut = moment(currentdate).format('mm') - moment(createdate).format('mm')
          let secd = moment(currentdate).format('ss') - moment(createdate).format('ss')
          if ((secd).toString().includes("-")) {
            minut = minut - 1
            let se = 60 - parseInt(moment(createdate).format('ss'))
            let ss = parseInt(moment(currentdate).format('ss')) + se
            secd = ss
          }
          let minCheck = minut == 0 ? '' : `${minut} minutes`
          let secCheck = secd == '0' ? '' : `${secd} seconds`

          this.setState({ importOrderText: `${status} ${minCheck} ${secCheck} ago` })

        }
        if (currentdate.getFullYear() == createdate.getFullYear() && currentdate.getMonth() == createdate.getMonth() && currentdate.getDay() == createdate.getDay() && currentdate.getHours() == createdate.getHours() && currentdate.getMinutes() == createdate.getMinutes() && currentdate.getSeconds() !== createdate.getSeconds()) {
          let sec = moment(currentdate).format('ss') - moment(createdate).format('ss')
          // console.log("here--->>>sec", sec)
          this.setState({ importOrderText: `${status} ${sec} seconds ago` })

        }

      }
    }
    if (nextProps?.importOrder?.importstatusData?.summary?.import_info?.status == "not_started") {
      // console.log("started -->", nextProps.importOrder.importstatusData.summary)
      this.setState({ importButton: true })
      let currentdate = new Date()
      let createdate = new Date(nextProps?.importOrder?.importstatusData?.summary?.import_info?.created_at)
      if (currentdate.getFullYear() == createdate.getFullYear() && currentdate.getMonth() == createdate.getMonth() && currentdate.getDay() == createdate.getDay() && currentdate.getHours() !== createdate.getHours()) {
        debugger
        let hour = moment(currentdate).format('H') - moment(createdate).format('H')
        let min = moment(currentdate).format('mm') - moment(createdate).format('mm')
        if ((min).toString().includes("-")) {
          hour = hour - 1
          let minc = 60 - parseInt(moment(updatedate).format('mm'))
          let ss = parseInt(moment(currentdate).format('mm')) + minc
          min = ss
        }
        let hourCheck = hour == 0 ? '' : `${hour} hours`
        let minCheck = min == '0' ? '' : `${min} minutes`
        this.setState({ importOrderText: `Import is started ${hourCheck} ${minCheck} ago` })
      }
      if (currentdate.getFullYear() == createdate.getFullYear() && currentdate.getMonth() == createdate.getMonth() && currentdate.getDay() == createdate.getDay() && currentdate.getHours() == createdate.getHours() && currentdate.getMinutes() !== createdate.getMinutes()) {
        let min = moment(currentdate).format('mm') - moment(createdate).format('mm')
        let sec = moment(currentdate).format('ss') - moment(createdate).format('ss')
        // console.log("-->>>>>",createdate,secCreate,min,sec,moment(currentdate).format('mm'),moment(currentdate).format('ss'))
        if ((sec).toString().includes("-")) {
          min = min - 1
          let se = 60 - parseInt(moment(currentdate).format('ss'))
          let ss = parseInt(moment(secCreate).format('ss')) + se
          sec = ss
        }
        let minCheck = min == 0 ? '' : `${min} minutes`
        let secCheck = sec == '0' ? '' : `${sec} seconds`
        this.setState({ importOrderText: `Import is started ${minCheck} ${secCheck} ago` })
      }
      if (currentdate.getFullYear() == createdate.getFullYear() && currentdate.getMonth() == createdate.getMonth() && currentdate.getDay() == createdate.getDay() && currentdate.getHours() == createdate.getHours() && currentdate.getMinutes() == createdate.getMinutes() && currentdate.getSeconds() !== createdate.getSeconds()) {
        let sec = moment(currentdate).format('ss') - moment(createdate).format('ss')
        
        this.setState({ importOrderText: `Import is started ${sec} seconds ago` })

      }
      this.setState({ animatingType: false })

    }
  }
  componentDidUpdate(pP) {
    // console.log("------===+++",pP,this.props)

    // if(importDates == true){
    //   console.log("props scan",this.props.importOrder.importstatusData)
    //   importDates = false
    // }
    if (orderListSuccess === true && this.props && this.props.ordersList && this.props.ordersList !== this.state.list) {
      orderListSuccess = false
      let list = this.props.ordersList

      let orderShow = list.orders.length === 0 ? true : false
      this.setState({
        scan_pack_settings: list.scan_pack_settings,
        general_settings: list.general_settings,
        rfoTitle: list.orders.length > 0 ? list && list.orders[0] && list.orders[0].ordernum : null,
        //rfoTitle: list.orders.length > 0 ? list && list.orders[0] && list.orders[0].ordernum : "Please scan an order to continue." ,
        orderDetail: list && list.orders && list.orders[0],
        loading: false,
        list: this.props.ordersList,
        unClick: orderShow
      })
      if (list?.scan_pack_settings?.scan_by_packing_slip) {
        this.setState({ cueOrderStatus: 'Scan a Packing Slip' })
      }
      if (list?.scan_pack_settings?.scan_by_packing_slip_or_shipping_label) {
        this.setState({ cueOrderStatus: 'Scan a Packing Slip or Shipping Label' })
      }
      if (list?.scan_pack_settings?.scan_by_shipping_label) {
        this.setState({ cueOrderStatus: 'Scan a Shipping Label' })
      }
    }
    if (messageShow === false && this.props && this.props.route && this.props.route.params && this.props.route.params.showScannedMessage) {
      this.alertBox(ScanOrderAlreadyMsg, true, true)
      this.setState({ loading: true, loaderTitle: "Loading..." })
      this.getOrder()
      messageShow = true
    }
    //Search Order Data
    if (searchOrderSuccess === true && this.props &&
      this.props.searchOrder && this.props.searchOrder.time > this.state.apiCallTime &&
      this.props.searchOrder !== this.state.oldOne) {
      let searchResult = this.props.searchOrder && this.props.searchOrder.searchOrder
      let order = searchResult && searchResult.data.order && searchResult.data.order[0]
      if (searchResult) {
        if (searchResult.matched) {
          if (searchResult.data) {
            this.orderDetails(order)

            searchOrderSuccess = false
          } else {
            if (searchResult.data.status === "scanned") {
              let message = `Order ${searchResult.data.order_num} has already been scanned.`
              this.statusFunction(message)
              this.setState({ unClick: true })
            }
          }
        } else {
          this.alertBox(searchResult.error_messages, true, true)
          this.setState({ unClick: true })
          searchOrderSuccess = false
        }
        this.setFocus()
        let data = this.state.data
        data["input"] = ""
        this.setState({ oldOne: this.props.searchOrder, data, loading: false })
      }
    }
    if (validateUser && this.props && this.props.userInfo && this.props.userInfo !== this.state.userInfo) {
      if (this.props.userInfo.is_active === false) {
        validateUser = false
        this.props.UserInfo(false)
        this.redirect()
      }
    }
    this.inputFocus.current.focus()
  }
  //////////// For Close Alert 
  closeAlert = () => {
    this.setState({ popUpModelShow: false })
  }

  redirect = async () => {
    // await AsyncStorage.removeItem("access_token")
    await AsyncStorage.clear();
    this.props.navigation.navigate("SignIn", { status: "Inactive", time: new Date() })
    this.setState({ userInfo: this.props.userInfo })
  }
  //After the apis call update the status (scanned , order  not present)
  statusFunction = (message) => {
    let data = this.state.data

    data["input"] = ""
    this.setState({ rfoTitle: message, data })

  }

  //To set the focus
  setFocus = () => {
    this.inputFocus.current.focus();
  }

  //Redirect to the scanpackItem page with order details 
  orderDetails = (order) => {
    if (order && order.scan_hash && order.scan_hash.data) {
      // console.log("------->>>>>>>>>>>>>>>>")

      this.props.navigation.navigate("ScanPackItem", { data: order.scan_hash.data })
      messageShow = false
    }
  }
  //Check the connection for mobile device before call the api
  searchOrder = (input) => {
    this.setState({ loading: true, loaderTitle: "Loading..." })
    if (input !== "") {
      if (Platform.OS === "ios" || Platform.OS === "android") {
        this.checkConnection("callShipmentOrder", input)
      } else {
        this.callShipmentOrder(input)
      }
    }
  }
  //Api to call the search order
  callShipmentOrder = (input) => {
    let shipmentOrder = this.state.data
    shipmentOrder["input"] = input
    if (input !== "") {
      shipmentOrder.input = input
      this.props.SearchScanpackOrder(shipmentOrder)
      searchOrderSuccess = true
      let apiCallTime = new Date()
      this.setState({ apiCallTime })
    }
  }
  //Handle the search field input
  handleChange = (name, value) => {
    let order = this.state.data;
    if (name === "searchOrder") {
      order["input"] = value
    }
    this.setState({ order })
  }
  importorderFun = () => {
    this.setState({ importOrderText: 'Import starting. Refresh in a moment to check status' })
    this.setState({ animatingType: true })
    this.props.ImportOrder()
    this.props.ImportOrder()

  }
  // importTextFun = (text) =>{
  //   this.setState({importOrderText : text })

  // }
  componentWillUnmount() {
    this.props.GetOrderList(this.state.dataList, false)
    this.setState({ importOrderText: '' })
  }

  render() {
    return (
      <ImageBackground style={{ flex: 1 }} source={require('././../../../assets/background.png')}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <ImageBackground style={{ height: height / 2, marginTop: 20, alignItems: "center" }} source={require('././../../../assets/scaning.png')} resizeMode='contain'>
            <Text style={{ textAlign: 'center', fontSize: 60, color: '#8da4b8', fontFamily: fontFamily.font400, marginTop: height / 4 }}>
              Scan
            </Text>
            <TextInput
              name="searchOrder"
              autoFocus={true}
              ref={this.inputFocus}
              value={this.state.data.input}
              onChangeText={(text) => { this.handleChange("searchOrder", text) }}
              onSubmitEditing={() => {
                if (this.state.data.input === "") return
                this.searchOrder(this.state.data && this.state.data.input)
              }}
              style={{ backgroundColor: 'black', borderWidth: 2, borderColor: '#7a92a8', width: 250, borderRadius: 5, color: 'white', fontSize: 16, fontFamily: fontFamily.font400, outline: "none", padding: 5 }}
            />
            { // Notification Pop Up for messages
              this.state.popUpModelShow &&
              <PopUpModel closeAlert={this.closeAlert.bind(this)}
                message={this.state.message}
                messageTypeSuccess={this.state.messageTypeSuccess ? true : false}
              />
            }
            {
              this.state.loading
                ?
                <View style={{ flex: 1, justifyContent: "center" }}>
                  {/* Activity Indicator Shown  */}
                  <ActivityIndicator size="large" color="#336599" />
                </View>
                :
                <>
                  {
                    this.state.error
                      ?
                      <Text style={styles.text}>
                        {this.state.error}
                      </Text>
                      : null
                  }
                </>
            }
            <Text style={{ color: '#8da4b8', fontSize: 18, marginTop: 24, fontFamily: fontFamily.font400, textAlign: "center" }}>
              {this?.state?.cueOrderStatus}
            </Text>
            <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20, alignItems: "center" }}>
              <LinearGradient
                colors={['#95abbf', '#516b83']}
                style={{ borderRadius: 5, minWidth: 250, minHeight: 40 }}>
                <View />
                <TouchableOpacity
                  style={{ flexDirection: 'row', justifyContent: "space-between" }}
                  onPress={() => {
                    if (this.state.rfoTitle)
                      this.searchOrder(this.state.rfoTitle)
                  }}>
                  <View style={{ alignItems: "center" }}>
                    <Text style={{ color: 'white', fontFamily: fontFamily.font400, fontSize: 15 }}>
                      Quick-Scan
                    </Text>
                    {!this.state.loading ?
                      <Text style={{ color: 'white', fontFamily: fontFamily.font400, fontSize: 15, width: 220, textAlign: 'center' }}
                      >
                        {this.state.rfoTitle ? this.state.rfoTitle : "No orders were found in the awaiting orders list."}
                      </Text> : null}
                  </View>
                  <Image source={require('././../../../assets/arroe.png')} style={{ marginRight: 10, height: 22, width: 22, alignSelf: 'center' }} />
                </TouchableOpacity>
              </LinearGradient>
              <TouchableOpacity
                onPress={() => this.setState({ quickScanToolTip: !this.state.quickScanToolTip })}>
                <Image source={require('././../../../assets/notificationicon.png')} style={{ height: 15, width: 15, marginLeft: 10 }} />
              </TouchableOpacity>
            </View>
            {
              this.state.quickScanToolTip &&
              <LinearGradient
                locations={[0, 1]}
                colors={["#8a9daf", "#d7e1e9"]}
                style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 335 }}>
                <Text style={{ fontFamily: fontFamily.font400, fontSize: 14, color: "#030303" }}>Click the Quick-Scan button or scan a barcode with the value QUICKSCAN to automatically display the oldest unscanned order for verification.</Text>
                <View style={{ position: "absolute", right: "8%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
              </LinearGradient>
            }
            {this.props.importOrder.importstatusData?.summary?.import_info?.status == "cancelled" ?
              (<View style={{ marginTop: 50 }}>
                <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 7, fontSize: 16 }}>
                  {/* Import  Is {String(this.props?.importOrder?.time)} */}
                  The last import was {this.state.importOrderText}
                  {/* {moment(this.props.importOrder.importstatusData?.summary?.import_info?.updated_at).format('MMMM Do YYYY, h:mm:ss a')} */}
                </Text>
              </View>)
              :
              this.props.importOrder.importstatusData?.summary?.import_info?.status == "completed" ?
                (<View style={{ marginTop: 50 }}>
                  <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 7, fontSize: 16 }}>
                    {/* Import  Is {String(this.props?.importOrder?.time)} */}
                    The last import was {this.state.importOrderText}
                    {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                  </Text>
                </View>)
                :
                // this.props.importOrder.importstatusData?.summary?.import_info?.status == "Import is in progress" ?
                // (<View style={{ marginTop: 50 }}>
                //   <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 7, fontSize: 16 }}>
                //     {/* Import  Is {String(this.props?.importOrder?.time)} */}
                //     Last import {this.state.importOrderText}
                //     {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                //   </Text>
                // </View>)
                // :
                this.props?.importOrder?.importstatusData?.summary?.import_info?.status == "not_started" ?
                  (<View style={{ marginTop: 50,justifyContent:'center',alignItems:'center' }}>
                    <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 7, fontSize: 16 }}>
                      {/* Import  Is {String(this.props?.importOrder?.time)} */}
                      {this.state.importOrderText}
                      {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                    </Text>
                    {
                      this?.state?.ShowEtaText &&
                      <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 7, fontSize: 16 }}>
                        {/* Import  Is {String(this.props?.importOrder?.time)} */}
                        {this.state.EtaText}
                        {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                      </Text>
                    }
                  </View>)
                  :
                  this.props?.importOrder?.importstatusData?.summary?.import_info?.status == "in_progress" ?
                    (<View style={{ marginTop: 50,justifyContent:'center',alignItems:'center' }}>
                      <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 7, fontSize: 16 }}>
                        {/* Import  Is {String(this.props?.importOrder?.time)} */}
                        {this.state.importOrderText}
                        {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                      </Text>
                      {
                        this?.state?.ShowEtaText &&
                        <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 7, fontSize: 16 }}>
                          {/* Import  Is {String(this.props?.importOrder?.time)} */}
                          {this.state.EtaText}
                          {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                        </Text>
                      }
                    </View>)
                    :
                    <View></View>

            }
            {
              this?.state?.importButton &&
              <TouchableOpacity onPress={() => this.importorderFun()}>
                <LinearGradient
                  colors={['#95abbf', '#516b83']}
                  style={{ borderRadius: 5, marginTop: 30, borderWidth: 1, paddingHorizontal: 40, paddingVertical: 5, justifyContent: 'center', alignItems: 'center', borderRadius: 3 }} >
                  <Text style={{ color: 'white', fontFamily: fontFamily.font400, fontSize: 15 }}>
                    Import Now
                  </Text>
                </LinearGradient>
              </TouchableOpacity>
            }
            {
              this.props?.importOrder?.importdata?.error_messages && this.state.animatingType !== false &&
              <View style={{ marginTop: 20 }}>
                <ActivityIndicator size="large" color='#95abbf' animating={this.state.animatingType} />
                <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 2, marginTop: 4, fontSize: 16 }}>{this.props?.importOrder?.importdata?.error_messages[0]}</Text>

              </View>
            }
            <TouchableOpacity onPress={() => this.props.ImportOrderStatus()}>
              <LinearGradient
                colors={['#95abbf', '#516b83']}
                style={{ borderRadius: 5, marginTop: 30, borderWidth: 1, paddingHorizontal: 40, paddingVertical: 5, justifyContent: 'center', alignItems: 'center', borderRadius: 3 }} >
                <Text style={{ color: 'white', fontFamily: fontFamily.font400, fontSize: 15 }}>
                  Refresh
                </Text>
              </LinearGradient>
            </TouchableOpacity>
            <View style={{ height: 40, width: "100%" }} />
          </ImageBackground>
        </ScrollView>
      </ImageBackground>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    ordersList: state.order.list,
    searchOrder: state.scanpack,
    userInfo: state.user.userInfo,
    importOrder: state.importOrder
  }
};

const mapDispatchToProps = {
  GetOrderList,
  SearchScanpackOrder,
  UserInfo,
  ImportOrder,
  ImportOrderStatus,
}
export default connect(mapStateToProps, mapDispatchToProps)(ScanPack)

