import React, { Component } from "react";
import {
  View,
  // Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Image,
  TouchableHighlight,
  // Modal,
} from "react-native";
// import styles from '../../style/orderdetail';
import { connect } from "react-redux";
import { GetAllProduct, GetSearchProduct } from "../../actions/productAction";
import { AddOrderItems } from "../../actions/orderActions";
import globalStyles from "../../style/global";
import styles from "../../style/orderdetail";
// import styles from '../../style/orderlist'
import { Tooltip, Text } from "react-native-elements";
// import Modal from "modal-react-native-web";
import { LinearGradient } from "expo-linear-gradient";
import { fontFamily } from "../../helpers/fontFamily";

let addItemSuccess = true;
let update = true;
let updateSearch = false;

class AddItemToOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      getAllItem: {
        filter: "all",
        sort: "",
        order: "",
        is_kit: -1,
        limit: 20,
        offset: 0,
      },
      searchData: {
        search: "",
        sort: "",
        order: "DESC",
        is_kit: -1,
        limit: 20,
        offset: 0,
      },
      allProduct: [],
      selected: [],
      searchProduct: [],
      existingItems: [],
      orderID: "",
      windowWidth: 0,
      checkWidth: 0,
      searchTxt: "",
      start: 0,
      end: 10,
      quickScanToolTip: false,
    };
    window.addEventListener("resize", this.update);
    this.tooltipRef = React.createRef();
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };
  componentDidMount() {
    this.update();
    // console.log("*********************");
    // console.log("props from add item to order", this.props);
    // console.log("*********************");

    // this.setState({
    //   orderID:
    //     this.props?.items &&
    //     this.props?.items?.items &&
    //     this.props?.items?.activities[0]?.order_id,
    // });
    let orderID =
      this.props?.items &&
      this.props?.items?.items &&
      this.props?.items?.items[0]?.iteminfo?.order_id;

    if (orderID > 0) {
      let existingItems = [];
      this.props?.items?.items.map((item, index) => {
        existingItems.push(item.productinfo.id);
      });
      update = true;
      this.setState({ existingItems });
      this.props.GetAllProduct(this.state.getAllItem);
    }
    if (this.props?.items?.items?.length) {
      let sorted = this.props.items.items.sort(
        (a, b) =>
          Date.parse(a.iteminfo?.updated_at) -
          Date.parse(b.iteminfo?.updated_at)
      );
      this.setState({ items: sorted });
    }
  }

  componentDidUpdate() {
    // componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // if (this.props.userID !== prevProps.userID) {
    //   this.fetchData(this.props.userID);
    // }
    // }
    // if(this.state.windowWidth != this.state.checkWidth)
    // {
    //   this.tooltipRef.current.toggleTooltip();
    // }

    // console.log("updates", this.props);
    // console.log("Update")

    if (update && this.state.allProduct !== this.props.allProduct) {
      let updated = [];
      let indexLength = 0;
      if (this.props.allProduct && this.props.allProduct.length > 0) {
        let existing = this.state.existingItems;
        // let filterArray = []
        this.props.allProduct.filter((arrayItem, index) => {
          existing.includes(arrayItem.id) ? null : updated.push(arrayItem);
          indexLength = index;
        });
        if (
          this.props.allProduct &&
          this.props.allProduct.length === indexLength + 1
        ) {
          this.setState({ allProduct: updated });
          update = false;
        }
      }
    }
    if (addItemSuccess && this.props.addOrderItems) {
      let id = this.props?.items?.items[0]?.iteminfo?.order_id;
      // console.log(id);
      this.props.navigation.navigate("OrderDetail", {
        data: id,
        from: "UpdateOrder",
      });
      addItemSuccess = false;
    }
    if (
      updateSearch === false &&
      this.props.searchProduct &&
      this.state.searchProduct !== this.props.searchProduct
    ) {
      let temp = this.state.searchData;
      temp["search"] = "";
      this.setState({
        allProduct: this.props.searchProduct,
        searchProduct: this.props.searchProduct,
        temp,
      });
      update = false;
      updateSearch = true;
    }
  }

  submitItems() {
    let orderID =
      this.props?.items &&
      this.props?.items?.items &&
      this.props?.items?.items[0]?.iteminfo.order_id;
    // console.log("child orderID", this.props);
    if (orderID && this.state.selected.length > 0) {
      // console.log("abcd");
      // let qty = this.props.route.params[0].iteminfo.qty;
      let data = {
        productids: this.state.selected,
        qty: 1,
        // qty: qty
      };

      this.props.AddOrderItems(orderID, data);
      addItemSuccess = true;
    }
  }

  itemSelect(item) {
    let selected = this.state.selected;
    var check = selected.includes(item.id);
    if (check) {
      const index = selected.findIndex((selected) => selected === item.id);
      selected.splice(index, 1);
    } else {
      selected.push(item.id);
    }
    this.setState({ selected });
  }

  searchItem() {
    let search = this.state.searchData;
    if (search.search !== "") {
      this.props.GetSearchProduct(search);
      updateSearch = false;
    }
  }

  handleChange(text) {
    let searchData = this.state.searchData;
    searchData["search"] = text;
    this.setState({ searchData });
  }

  componentWillUnmount() {
    update = true;
    this.setState({ allProduct: [], searchProduct: [] });
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    return (
      <View
        // colors={["#22292C", "#68879C", "#68879C", "#22292C"]}
        // start={[-0.3, 0.01]}
        style={{
          // overflow: "scroll",
          paddingHorizontal: "1%",
          backgroundColor: "rgba(104, 135, 156, 0.7)",
          borderRadius: 10,
          marginHorizontal: "1%",
          marginTop: 20,
          marginBottom: 10,
          // borderWidth: 1,
          // borderColor: "#50708b",
          shadowColor: "#50708b",
          shadowOffset: { width: 1, height: 1 },
          shadowRadius: 10,
          flexWrap: "wrap",
        }}
      >
        <View
          style={{
            alignSelf: "flex-end",
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 10,
            flexDirection: "row",
            // borderWidth: 1
          }}
        >
          <TouchableOpacity
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#336599",
              height: 30,
              shadowColor: "#2bfac3",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
              // box-shadow: rgb(43 250 195) 0px 1px 3px;
            }}
            onPress={() => {
              {
                this.state.selected.length
                  ? this.submitItems()
                  : this.props?.onchange();
              }
            }}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Save & Close
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props?.onchange()}
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#494848",
              height: 30,
              shadowColor: "#e853fa",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
            }}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>

        <Text
          style={{
            textAlign: "center",
            fontSize: 21,
            fontFamily:'Poppins_600SemiBold',
            color: "white",
          }}
        >
          Search & select items to add them
        </Text>
        {/* <View style={{}}> */}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-around",
            marginTop: 10,
            height: 40,
            flexWrap: "wrap",
            paddingHorizontal: 15,
            // marginHorizontal: 10
          }}
        >
          <TouchableOpacity
            style={{
              width: "7%",
              marginRight: 5,
              flexWrap: "wrap",
              height: "100%",
            }}
          >
            <Image
              style={{ width: "100%", height: "100%", resizeMode: "contain" }}
              source={require("../../../assets/barcode.png")}
            />
          </TouchableOpacity>
          <View style={{ width: "75%", paddingRight: 5 }}>
            <TextInput
              placeholder="Type order to search"
              name="searchOrder"
              autoFocus={true}
              // ref={this.searchField}
              value={this.state.searchData && this.state.searchData.search}
              onChangeText={(text) => {
                this.handleChange(text), this.setState({ searchTxt: text });
              }}
              onSubmitEditing={(e) => this.searchItem(e)}
              style={{
                backgroundColor: "#f4efef",
                borderRadius: 5,
                height: 30,
                paddingHorizontal: 10,
                width: "100%",
                // borderWidth: 1,
              }}
            />
          </View>
          <View
            style={{
              width: "15%",
              flexDirection: "row",
              flexWrap: "wrap",
              height: "100%",
              // borderWidth: 1
            }}
          >
            <TouchableOpacity
              onPress={() => this.searchItem(this.state.searchTxt)}
              style={{
                width: "50%",
                flexWrap: "wrap",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Image
                style={{ width: "100%", height: "100%", resizeMode: "contain" }}
                source={require("../../../assets/search.png")}
              />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() =>
                this.setState({
                  quickScanToolTip: !this.state.quickScanToolTip,
                })
              }
              style={{
                width: "50%",
                flexWrap: "wrap",
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Image
                style={{
                  width: "100%",
                  height: "100%",
                  resizeMode: "contain",
                }}
                source={require("../../../assets/info.png")}
              />
            </TouchableOpacity>
          </View>
        </View>
        {this.state.quickScanToolTip && (
          <LinearGradient
            locations={[0, 1]}
            colors={["#8a9daf", "#d7e1e9"]}
            style={{
              padding: 10,
              // position: "relative",
              // right: "85%",
              // marginRight: 50,
              alignSelf: "flex-end",
              borderRadius: 5,
              width: 335,
              flexWrap: "wrap",
            }}
          >
            <Text
              style={{
                fontFamily: fontFamily.font400,
                fontSize: 14,
                color: "#030303",
              }}
            >
              Multiple items can be added to an order at once. After searching
              for an item by it's name, sku or barcode use the checkbox to
              select it. Additional selections can be made the same way. Once
              all required items are selected click "Save & Close" to add them
              to the order.
            </Text>
            {/* <View
                    style={{
                      position: "absolute",
                      right: "8%",
                      top: -10,
                      borderLeftWidth: 10,
                      borderLeftColor: "transparent",
                      borderRightWidth: 10,
                      borderRightColor: "transparent",
                      borderBottomWidth: 10,
                      borderBottomColor: "#8d9fb1",
                    }}
                  /> */}
          </LinearGradient>
        )}

        {this.state.windowWidth >= 900 ? (
          <View
            style={{
              // overflow: "scroll",
              flex: 1,
              // marginHorizontal: 10,
              backgroundColor: "rgba(15, 39, 58, 0.4)",
              borderRadius: 15,
              marginBottom: 50,
              minHeight: 200,
              // opacity: 0.5
              // width: "100%",
              marginTop: 10,
              paddingLeft: 10,
              flexWrap: "wrap",
            }}
          >
            <View style={[styles.itemContainer]}>
              <View
                style={{
                  flexDirection: "row",
                  // borderBottomWidth: 0.7,
                  // borderColor: "rgba(250, 250, 250, .4)",
                  paddingHorizontal: "1%",
                  width: "100%",
                  justifyContent: "space-around",
                  // backgroundColor: 'aqua',
                  flexWrap: "wrap",
                }}
              >
                <View style={[styles.addItemView, { width: "5%" }]}>
                  <Image
                    style={{
                      width: 35,
                      height: 35,
                      resizeMode: "contain",
                    }}
                    source={require("../../../assets/tick01.png")}
                  />
                </View>
                <View style={{ width: "95%", flexDirection: "row" }}>
                  <View style={[styles.addItemView, { width: "25%" }]}>
                    <Text style={styles.gridHeader2}>SKU</Text>
                  </View>

                  <View style={[styles.addItemView, { width: "25%" }]}>
                    <Text style={[styles.gridHeader2]}>Product Name</Text>
                  </View>

                  <View
                    style={[
                      styles.addItemView,
                      {
                        width: "15%",
                      },
                    ]}
                  >
                    <Text style={styles.gridHeader2}>QOH</Text>
                  </View>

                  <View style={[styles.addItemView, { width: "15%" }]}>
                    <Text style={styles.gridHeader2}>Status</Text>
                  </View>
                  <View style={[styles.addItemView, { width: "25%" }]}>
                    <Text style={styles.gridHeader2}>Barcode</Text>
                  </View>
                </View>
              </View>
            </View>

            {this.state.allProduct.length > 0 && (
              <View>
                {this.state.allProduct &&
                  this.state.allProduct.length > 0 &&
                  this.state.allProduct.map((item, index) => {
                    return (
                      <TouchableOpacity
                      key={index}
                      onPress={() => {
                        this.itemSelect(item);
                      }}
                      delayLongPress={1000}
                      // style={[styles.addItemView, { width: "5%" }]}
                    >
                      <View style={[styles.itemContainer]}>
                          
                        <View
                          style={{
                            flexDirection: "row",
                            // borderBottomWidth: 0.7,
                            // borderColor: "rgba(250, 250, 250, .4)",
                            paddingHorizontal: "1%",
                            width: "100%",
                            justifyContent: "space-around",
                            // backgroundColor: 'aqua',
                            flexWrap: "wrap",
                          }}
                        >
                       
                            {this.state.selected.includes(item.id) ? (
                              <Image
                                style={{
                                  width: 35,
                                  height: 35,
                                  resizeMode: "contain",
                                }}
                                source={require("../../../assets/round.png")}
                              />
                            ) : (
                              <Image
                                style={{
                                  width: 35,
                                  height: 35,
                                  resizeMode: "contain",
                                }}
                                source={require("../../../assets/round3.png")}
                              />
                            )}
                         
                          <View
                            style={{
                              width: "95%",
                              flexDirection: "row",
                              borderBottomWidth: 0.7,
                              borderColor: "white",
                            }}
                          >
                            <View
                              style={[styles.addItemView, { width: "25%" }]}
                            >
                              <Text
                                style={[styles.itemName2, { fontSize: 16 }]}
                              >
                                {item.sku}
                              </Text>
                            </View>

                            <View
                              style={[styles.addItemView, { width: "25%" }]}
                            >
                              <Text
                                style={[styles.itemName2, { fontSize: 16 }]}
                              >
                                {item.name}
                              </Text>
                            </View>

                            <View
                              style={[
                                styles.addItemView,
                                {
                                  width: "15%",
                                  // justifyContent: "center",
                                  // alignItems: "center",
                                },
                              ]}
                            >
                              <Text
                                style={[styles.itemName2, { fontSize: 16 }]}
                              >
                                {item.qty_on_hand}
                              </Text>
                            </View>

                            <View
                              style={[styles.addItemView, { width: "15%" }]}
                            >
                              {item.status === "active" ? (
                                <Text
                                  style={[
                                    styles.statusActive,
                                    { fontSize: 16 },
                                  ]}
                                >
                                  {this.Capitalize(item.status)}
                                </Text>
                              ) : (
                                // <Text>{this.Capitalize(this.state.title)} </Text>
                                <Text
                                  style={[styles.statusNew, { fontSize: 16 }]}
                                >
                                  {this.Capitalize(item.status)}
                                </Text>
                              )}
                            </View>

                            <View
                              style={[styles.addItemView, { width: "25%" }]}
                            >
                              <Text
                                style={[styles.itemName2, { fontSize: 16 }]}
                              >
                                {item.barcode}
                              </Text>
                            </View>
                          </View>
                        </View>
                       
                      </View>
                      </TouchableOpacity>
                    );
                  })}
              </View>
            )}
          </View>
        ) : (
          <View
            style={{
              // overflow: "scroll",
              flex: 1,
              marginHorizontal: 10,
              backgroundColor: "rgba(15, 39, 58, 0.4)",
              borderRadius: 15,
              marginBottom: 50,
              minHeight: 200,
              // opacity: 0.5
              // width: "100%",
              marginTop: 10,
              // paddingLeft: 10,
              flexWrap: "wrap",
            }}
          >
            <View style={[styles.itemContainer]}>
              <View
                style={{
                  flexDirection: "row",
                  // borderBottomWidth: 0.7,
                  // borderColor: "rgba(250, 250, 250, .4)",
                  paddingHorizontal: "2%",
                  width: "100%",
                  justifyContent: "space-around",
                  // backgroundColor: 'aqua',
                  flexWrap: "wrap",
                }}
              >
                <View style={[styles.addItemView, { width: "5%" }]}>
                  <Image
                    style={{
                      width: 30,
                      height: 30,
                      resizeMode: "contain",
                    }}
                    source={require("../../../assets/tick01.png")}
                  />
                </View>
                <View
                  style={{
                    width: "95%",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <View style={[styles.addItemView, { width: "25%" }]}>
                    <Text style={[styles.gridHeader2, { fontSize: 16 }]}>
                      SKU
                    </Text>
                  </View>

                  <View style={[styles.addItemView, { width: "35%" }]}>
                    <Text
                      style={[
                        styles.gridHeader2,
                        { fontSize: 16, lineHeight: 13 },
                      ]}
                    >
                      Product Name
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.addItemView,
                      {
                        width: "10%",
                      },
                    ]}
                  >
                    <Text style={[styles.gridHeader2, { fontSize: 16 }]}>
                      QOH
                    </Text>
                  </View>

                  <View style={[styles.addItemView, { width: "15%" }]}>
                    <Text style={[styles.gridHeader2, { fontSize: 16 }]}>
                      Status
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {this.state.allProduct.length > 0 && (
              <View style={{ overflow: "hidden" }}>
                {this.state.allProduct &&
                  this.state.allProduct.length > 0 &&
                  this.state.allProduct.map((item, index) => {
                    return (
                      <TouchableOpacity
                      key={index}
                      onPress={() => {
                        this.itemSelect(item);
                      }}
                      delayLongPress={1000}
                      // style={[styles.addItemView, { width: "5%", paddingRight: "1px" }]}
                      // style={{textAlign: "center",justifyContent: "center"}}
                      // style={[styles.addItemView, { width: "5%" }]}
                    >
                      <View style={[styles.itemContainer,{justifyContent: "center",alignItems: "center"}]}>
                        <View
                          style={{
                            flexDirection: "row",
                            // borderBottomWidth: 0.7,
                            // borderColor: "rgba(250, 250, 250, .4)",
                            paddingHorizontal: "2%",
                            width: "100%",
                            justifyContent: "space-around",
                            alignItems: "center",
                            // backgroundColor: 'aqua',
                            flexWrap: "wrap",
                          }}
                         >
                       
                            {this.state.selected.includes(item.id) ? (
                           
                         
                           <Image
                                style={{
                                  width: 30,
                                  height: 30,
                                  width: "5%",
                                  // marginTop: "5%",
                                  resizeMode: "contain",
                                  justifyContent:"center",
                                  alignItems: "center",
                                }}
                                source={require("../../../assets/round.png")}
                              />
                            ) : (
                              <Image
                                style={{
                                  width: 30,
                                  height: 30,
                                  // marginTop: "5%",
                                  width:"5%",
                                  resizeMode: "contain",
                                  justifyContent:"center",
                                  alignItems: "center",
                                }}
                                source={require("../../../assets/round3.png")}
                              />

                            )
                            }
                     
                          <View
                            style={{
                              width: "95%",
                              flexDirection: "row",
                              borderBottomWidth: 0.7,
                              borderColor: "white",
                              justifyContent: "space-around",
                            }}
                          >
                            <View
                              style={[styles.addItemView, { width: "25%" }]}
                            >
                              <Text style={[styles.itemsku, { fontSize: 16 }]}>
                                {item.sku}
                              </Text>
                            </View>

                            <View
                              style={[styles.addItemView, { width: "35%" }]}
                            >
                              <Text
                                style={[styles.itemName2, { fontSize: 16 }]}
                              >
                                {item.name}
                              </Text>
                            </View>

                            <View
                              style={[
                                styles.addItemView,
                                {
                                  width: "10%",
                                  // justifyContent: "center",
                                  // alignItems: "center",
                                },
                              ]}
                            >
                              <Text
                                style={[styles.itemName2, { fontSize: 16 }]}
                              >
                                {item.qty_on_hand}
                              </Text>
                            </View>

                            <View
                              style={[styles.addItemView, { width: "15%" }]}
                            >
                              {item.status === "active" ? (
                                <Text
                                  style={[
                                    styles.statusActive,
                                    { fontSize: 16 },
                                  ]}
                                >
                                  {this.Capitalize(item.status)}
                                </Text>
                              ) : (
                                // <Text>{this.Capitalize(this.state.title)} </Text>
                                <Text
                                  style={[styles.statusNew, { fontSize: 16 }]}
                                >
                                  {this.Capitalize(item.status)}
                                </Text>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                      </TouchableOpacity>
                    );
                  })}
              </View>
            )}
          </View>
        )}
      </View>

      // </View>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("maptostate", state)
  return {
    allProduct: state.product.allProduct,
    addOrderItems: state.order.addOrderItems,
    searchProduct: state.product.searchProduct,
  };
};

const mapDispatchToProps = {
  GetAllProduct,
  AddOrderItems,
  GetSearchProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemToOrder);
