import { StyleSheet,Dimensions } from 'react-native';
const width=Dimensions.get('window').width
const height=Dimensions.get('window').height
import { fontFamily } from '../helpers/fontFamily';

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    error: {
        color: "red",
        textAlign: "center",
        fontSize: 16,
        paddingLeft: 15,
        paddingRight: 15
    },
    header: {
        margin:10,
        borderColor: "gray",
        alignSelf: "center",
        
    },
    headerText: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 20,
        marginTop: 50,
        marginBottom: 50
    },
    m_30: {
        marginTop:30,
        
    },
    labelText: {
        fontSize: 18,
        lineHeight: 20,
        marginTop: 5,
        color:'#5c768d',
        // fontFamily:'Poppins_600SemiBold'
    },
    space:{
marginTop:20
    },
    inputBox: {
        fontSize: 20,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 2,
        marginTop: 5,
        borderColor: 'gray',
        borderWidth: 2.5,
        borderRadius: 5,
        // width:width/5.5,
        width:width >= 900 ? width/3 : width >= 330 ? width/2 : width/1.5,
        borderColor:'#5c768d',
        color:'#fff',
    },
    inputBoxusername: {
        fontSize: 18,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 2,
        marginTop: 5,
        borderColor: 'gray',
        borderWidth: 2.5,
        borderRadius: 5,
        // width:width/5.5,
        borderColor:'#5c768d',
        color:'#fff',
        marginLeft:7,
    },
    inputBoxusername1: {
        fontSize: 18,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 2,
        marginTop: 5,
        borderColor: 'gray',
        borderWidth: 2.5,
        borderRadius: 5,
        width:width/5.5,
        borderColor:'#5c768d',
        color:'#fff',
        marginLeft:13,
        // backgroundColor:'red',
       
    },
    loginbutton: {
        fontSize: 13,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 8,
        paddingBottom: 8,
        marginBottom: 2,
        borderColor: 'gray',
        // borderWidth: 1,
        borderRadius: 5,
        // width:width/6,
        height:height/20,
        justifyContent:'center',
        alignItems:'center',
        alignContent:'center',
        alignSelf:'center',
        width:230
    },
    imagestylelock:{
        height:40,justifyContent:'center',alignContent:'center',alignItems:'center',marginTop:10,marginLeft:-10

    },
    imagestylelock1:{
        height:40,justifyContent:'center',alignContent:'center',alignItems:'center',marginTop:10,

    },
    loginview:
        {marginLeft:-35,justifyContent:'center',alignItems:'center',alignContent:'center',marginTop:45,},
        bottomview:
        {justifyContent:'center',width:300,alignItems:'center',alignContent:'center',marginTop:50,marginLeft:-35},
    tenentSuffix: {
        fontSize: 15,
        lineHeight: 20,
        marginBottom: 5,
        textAlign: "right"
    },
    inputError: {
        color: "red",
        fontSize: 12
    },
    inputError1: {
        color: "red",
        fontSize: 12,
        marginLeft:30
    },
    buttonContainer: {
        flexDirection: "row",
        alignSelf: "flex-end",
        marginBottom: 20,
        marginRight: 20,
        marginLeft: 20
    },
    button: {
        height:40,
        marginTop: 14,
        textAlign: "center",
        color:"#fff",
        fontSize:15,
        backgroundColor:'grey',
        width:200

    },
    aligntextinput:{flexDirection:'row',},
    imagestyle:{
        height:40,justifyContent:'center',alignContent:'center',alignItems:'center',marginLeft:12,
        marginTop:5,
        alignSelf:'center'
    },
    imagestylerefresh:{
        height:40,
        marginTop:8,
       justifyContent:'center',alignContent:'center',alignItems:'center',alignSelf:'center',
    
    //    backgroundColor:'red'
    },
    refreshbutton:{
        flexDirection:'row',marginTop:15,justifyContent:'center',alignContent:'center',alignItems:'center',alignSelf:'center',marginLeft:-45
    },
    fontSize:{   
        fontSize:18,
        color:'#5c768d',
        // marginLeft:-3,
        marginTop:3
    },
    main:{ backgroundColor: 'black', height: 80, width: 80, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center', marginTop: 30, },
    logintext:{textAlign:'center',color:'#fff',fontSize:24},
    alertContainer: {
        flex: 1,
        position: "absolute",
        top: 10,
        zIndex: 9999,
        // backgroundColor: "rgba(14, 14 , 14 , 0.7)",
        // justifyContent: "center",
        alignItems: "center",
        // height: "100%",
        width: "100%"
    },
     alertBox: {
        backgroundColor: "#ccc",
        opacity: 1,
        width: "95%",
        marginTop: 10,
        padding: 10,
        // borderRadius: 30,
        borderWidth: 2,
        borderColor: "#0003"
    },
    alertText: {
        flex: 8,
        color: "#000",
        textAlign: "left",
        fontWeight: "bold",
        fontSize: 16
    },
    alertClose: {
        flex: 2,
        color: "#000",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 16
    },
  
    // container: {
    //     alignItems: 'center',
    //     backgroundColor: '#ede3f2',
    //     padding: 100
    //  },
    //  modal: {
    //     flex: 1,
    //     alignItems: 'center',
    //     backgroundColor: '#f7021a',
    //     padding: 100
    //  },
    //  text: {
    //     color: '#3f2949',
    //     marginTop: 10
    //  }

})
export default styles;
