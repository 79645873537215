import React, { Component } from "react";
import {
  View,
  // Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  Image,
  TouchableHighlight,
  // Modal,
} from "react-native";
// import styles from '../../style/orderdetail';
import { connect } from "react-redux";
import { GetAllProduct, GetSearchProduct } from "../../actions/productAction";
import { AddOrderItems } from "../../actions/orderActions";
import { AllKITProduct } from "../../actions/productAction";
import globalStyles from "../../style/global";
import styles from "../../style/orderdetail";
// import styles from '../../style/orderlist'
import { Tooltip, Text } from "react-native-elements";
// import Modal from "modal-react-native-web";
import { LinearGradient } from "expo-linear-gradient";
import { fontFamily } from "../../helpers/fontFamily";

let addItemSuccess = true;
let update = true;
let updateSearch = false;

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      getAllItem: {
        filter: "all",
        sort: "",
        order: "",
        is_kit: -1,
        limit: 20,
        offset: 0,
      },
      searchData: {
        search: "",
        sort: "",
        order: "DESC",
        is_kit: -1,
        limit: 20,
        offset: 0,
      },
      allProduct: [],
      selected: [],
      searchProduct: [],
      existingItems: [],
      orderID: "",
      windowWidth: 0,
      checkWidth: 0,
      searchTxt: "",
      start: 0,
      end: 10,
      quickScanToolTip3: false,

    };
    window.addEventListener("resize", this.update);
    this.tooltipRef = React.createRef();
  }
  update = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  componentDidMount() {
    // this.apiCall()
    this.props.hideSaveFun()
    this.update();
    // console.log("*********************");
    // console.log("props from add item to order", this.props);
    // console.log("*********************");

    // this.setState({
    //   orderID:
    //     this.props?.items &&
    //     this.props?.items?.items &&
    //     this.props?.items?.activities[0]?.order_id,
    // });
    // let orderID =
    //   this.props?.items &&
    //   this.props?.items?.items &&
    //   this.props?.items?.items[0]?.iteminfo?.order_id;
    let product = this.props.product

    if (product) {
      let existingItems = [];
      this.props?.items?.items.map((item, index) => {
        existingItems.push(item.productinfo.id);
      });
      update = true;
      this.setState({ existingItems });
      this.props.GetAllProduct(this.state.getAllItem);
    }
    if (this.props?.items?.items?.length) {
      let sorted = this.props.items.items.sort(
        (a, b) =>
          Date.parse(a.iteminfo?.updated_at) -
          Date.parse(b.iteminfo?.updated_at)
      );
      this.setState({ items: sorted });
    }
  }
  // apiCall=()=>{
  //    this.props.GetAllProduct()
  // }
  componentDidUpdate() {

    // componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // if (this.props.userID !== prevProps.userID) {
    //   this.fetchData(this.props.userID);
    // }
    // }
    // if(this.state.windowWidth != this.state.checkWidth)
    // {
    //   this.tooltipRef.current.toggleTooltip();
    // }

    // console.log("updates", this.props);
    // console.log("Update")
    if (update && this.state.allProduct !== this.props.allProduct) {
      let updated = [];
      let indexLength = 0;
      if (this.props.allProduct && this.props.allProduct.length > 0) {
        let existing = this.state.existingItems;
        // let filterArray = []  
        this.props.allProduct.filter((arrayItem, index) => {
          existing.includes(arrayItem.id) ? null : updated.push(arrayItem);
          indexLength = index;
        });
        if (
          this.props.allProduct &&
          this.props.allProduct.length === indexLength + 1
        ) {
          this.setState({ allProduct: updated });
          update = false;
        }
      }
    }
    if (addItemSuccess && this.props.addOrderItems) {

      let id = this.props?.items?.items[0]?.iteminfo?.order_id;
      // console.log(id);
      this.props.navigation.navigate("InventoryKitOptions")
      // , {
      //     data: id,
      //     from: "UpdateOrder",
      // });
      addItemSuccess = false;
    }
    if (
      updateSearch === false &&
      this.props.searchProduct &&
      this.state.searchProduct !== this.props.searchProduct
    ) {
      let temp = this.state.searchData;
      temp["search"] = "";
      this.setState({
        allProduct: this.props.searchProduct,
        searchProduct: this.props.searchProduct,
        temp,
      });
      update = false;
      updateSearch = true;
    }
  }

  submitItems() {
    // let orderID =
    //   this.props?.items &&
    //   this.props?.items?.items &&
    //   this.props?.items?.items[0]?.iteminfo.order_id;
    let productID = this.props.product.basicinfo.id

    if (productID && this.state.selected.length > 0) {
      // console.log("abcd");
      // let qty = this.props.route.params[0].iteminfo.qty;
      let data = {
        product_ids: this.state.selected,

        // qty: qty
      };

      this.props.AllKITProduct(productID, data);
      setTimeout(() => this.props.AddItemToInventory(), 1000);

      addItemSuccess = true;
    }
  }

  itemSelect(item) {
    let selected = this.state.selected;
    var check = selected.includes(item.id);
    if (check) {
      const index = selected.findIndex((selected) => selected === item.id);
      selected.splice(index, 1);
    } else {
      selected.push(item.id);
    }
    this.setState({ selected });
  }

  searchItem() {
    let search = this.state.searchData;
    if (search.search !== "") {
      this.props.GetSearchProduct(search);
      updateSearch = false;
    }
  }

  handleChange(text) {
    let searchData = this.state.searchData;
    searchData["search"] = text;
    this.setState({ searchData });
  }

  componentWillUnmount() {
    update = true;
    this.setState({ allProduct: [], searchProduct: [] });
    this.props.hideSaveFun()
  }
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    // console.log("myname", this.state.windowWidth)
    return (
      <View
      // style={{
      //     marginTop:100
      // }}
      // colors={["#22292C", "#68879C", "#68879C", "#22292C"]}
      // // start={[-0.3, 0.01]}
      // style={{
      //   // overflow: "scroll",
      //   paddingHorizontal: "1%",  backgroundColor: "rgba(104, 135, 156, 0.7)",

      //   borderRadius: 10,
      //   marginHorizontal: "1%",
      //   marginTop: 20,
      //   marginBottom: 10,
      //   // borderWidth: 1,
      //   // borderColor: "#50708b",
      //   shadowColor: "#50708b",
      //   shadowOffset: { width: 1, height: 1 },
      //   shadowRadius: 10,
      //   flexWrap: "wrap",
      //   }}
      >
        <View
          style={{
            alignSelf: "flex-end",
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 10,
            flexDirection: "row",
            // borderWidth: 1
          }}
        >
          <TouchableOpacity
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#336599",
              height: 30,
              shadowColor: "#2bfac3",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
              // box-shadow: rgb(43 250 195) 0px 1px 3px;
            }}
            onPress={() => {
              {
                this.state.selected.length > 0
                  ? this.submitItems()
                  : this.props?.onchange();
              }
            }}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Save & Close
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props?.onchange()}
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#494848",
              height: 30,
              shadowColor: "#e853fa",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
            }}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>

        <View style={{
          marginTop: 0,
          alignSelf: "center",
          flexDirection: "row",
          marginBottom: 10
        }}>
          <Text
            style={{

              fontSize: 21,
              fontFamily: 'Poppins_600SemiBold',
              color: "white",

            }}
          >
            Search
          </Text>

          <Image style={{ flexDirection: "row", width: 30, height: 30, resizeMode: "contain" }}
            source={require("../../../assets/search.png")}
          />

          <Text
            style={{

              fontSize: 21,
              fontFamily: 'Poppins_600SemiBold',
              color: "white",

            }}
          >
            or Scan
          </Text>


          <Image style={{ width: 35, height: 35, resizeMode: "contain" }}
            source={require("../../../assets/barcode.png")}
          />
          <Text

            style={{

              fontSize: 21,
              fontFamily: 'Poppins_600SemiBold',
              color: "white",

            }}

          >Add to items</Text>
        </View>
        {/* <View style={{}}> */}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-around",
            marginTop: 10,
            height: 40,
            flexWrap: "wrap",
            paddingHorizontal: 15,
            // marginHorizontal: 10
          }}
        >

          <View style={{ width: "90%", marginLeft: 50, flexDirection: "row" }}>
            <TextInput
              placeholder="Type order to search"
              name="searchOrder"
              autoFocus={true}
              ref={this.searchField}
              value={this.state.searchData && this.state.searchData.search}
              onChangeText={(text) => {
                this.handleChange(text), this.setState({ searchTxt: text });
              }}
              onSubmitEditing={(e) => this.searchItem(e)}
              style={{
                backgroundColor: "#f4efef",
                borderRadius: 5,
                height: 30,
                paddingHorizontal: 10,
                flexDirection: "row",
                width: "90%",
                marginLeft: 30
                // borderWidth: 1,
              }}
            />
            <TouchableOpacity
              // onPress={() => {
              //  // this.props.addOrderItem(this.state.items);
              //  // this.setState({ itemadd: true });

              // }}
              onPress={() => this.searchItem(this.state.searchTxt)}
              style={{
                flexDirection: "row",
                marginLeft: 10

                // borderWidth: 1
              }}
            >
              <Image
                style={{ width: 35, height: 34, resizeMode: "contain" }}
                source={require("../../../assets/search.png")}
              />

            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // this.props.addOrderItem(this.state.items);
                // this.setState({ itemadd: true });
                this.setState({ quickScanToolTip3: !this.state.quickScanToolTip3 })
              }}
              style={{
                flexDirection: "row",
                marginLeft: 10

                // borderWidth: 1ls
              }}
            >
              <Image
                style={{ width: 35, height: 34, resizeMode: "contain" }}
                source={require("../../../assets/info.png")}
              />

            </TouchableOpacity>
          </View>


        </View>
        {this.state.quickScanToolTip3 &&
          <LinearGradient

            locations={[0, 1]}
            colors={["#8a9daf", "#d7e1e9"]}
            style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 300, alignSelf: "flex-end" }}>
            <Text style={{ fontSize: 20, color: "#030303" }}>Search for the kit part items you would like to add by entering the name or sku or by scanning the barcode. Click an item to select it. Multiple searches and selections can be made before saving so you can add multiple items at the same time. </Text>
            <View style={{ position: "absolute", right: "34%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
          </LinearGradient>
        }
        {this.state.quickScanToolTip && (
          <LinearGradient
            locations={[0, 1]}
            colors={["#8a9daf", "#d7e1e9"]}
            style={{
              padding: 10,
              // position: "relative",
              // right: "85%",
              // marginRight: 50,
              alignSelf: "flex-end",
              borderRadius: 5,
              width: 335,
              flexWrap: "wrap",
            }}
          >
            <Text
              style={{
                fontFamily: fontFamily.font400,
                fontSize: 14,
                color: "#030303",
              }}
            >
              Multiple items can be added to an order at once. After searching
              for an item by it's name, sku or barcode use the checkbox to
              select it. Additional selections can be made the same way. Once
              all required items are selected click "Save & Close" to add them
              to the order.
            </Text>
            {/* <View
                     style={{
                       position: "absolute",
                       right: "8%",
                       top: -10,
                       borderLeftWidth: 10,
                       borderLeftColor: "transparent",
                       borderRightWidth: 10,
                       borderRightColor: "transparent",
                       borderBottomWidth: 10,
                       borderBottomColor: "#8d9fb1",
                     }}
                   /> */}
          </LinearGradient>
        )}

        {this.state.windowWidth > 900 ? (
          <View
            style={{
              // overflow: "scroll",
              flex: 1,
              // marginHorizontal: 10,
              backgroundColor: "rgba(15, 39, 58, 0.4)",
              borderRadius: 15,
              marginBottom: 50,
              minHeight: 200,
              // opacity: 0.5
              // width: "100%",
              marginTop: 10,
              paddingLeft: 10,
              flexWrap: "wrap",
            }}
          >
            <View style={[styles.itemContainer]}>
              <View
                style={{
                  flexDirection: "row",
                  // borderBottomWidth: 0.7,
                  // borderColor: "rgba(250, 250, 250, .4)",
                  paddingHorizontal: "1%",
                  width: "100%",
                  justifyContent: "space-around",
                  // backgroundColor: 'aqua',
                  flexWrap: "wrap",
                }}
              >

                <View style={{ width: "95%", flexDirection: "row" }}>
                  <View style={[styles.addItemView, { width: "25%", paddingRight: 70 }]}>
                    <Text style={styles.gridHeader2}>SKU</Text>
                  </View>

                  <View style={[styles.addItemView, { width: "25%" }]}>
                    <Text style={[styles.gridHeader2]}>Product Name</Text>
                  </View>

                  <View
                    style={[
                      styles.addItemView,
                      {
                        width: "15%",
                      },
                    ]}
                  >
                    <Text style={styles.gridHeader2}>QOH</Text>
                  </View>

                  <View style={[styles.addItemView, { width: "15%" }]}>
                    <Text style={styles.gridHeader2}>Status</Text>
                  </View>
                  <View style={[styles.addItemView, { width: "25%", paddingRight: 48 }]}>
                    <Text style={styles.gridHeader2}>Barcode</Text>
                  </View>
                </View>
              </View>
            </View>

            {this.state.allProduct.length > 0 && (
              <View>
                {this.state.allProduct &&
                  this.state.allProduct.length > 0 &&
                  this.state.allProduct.map((item, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        onPress={() => {
                          this.itemSelect(item);
                        }}
                        delayLongPress={1000}

                      >
                        <View style={[styles.itemContainer]}>
                          <View
                            style={{
                              flexDirection: "row",
                              // borderBottomWidth: 0.7,
                              // borderColor: "rgba(250, 250, 250, .4)",
                              paddingHorizontal: "1%",
                              width: "100%",
                              // justifyContent: "space-around",
                              // backgroundColor: 'aqua',
                              flexWrap: "wrap",
                            }}
                          >

                            {this.state.selected.includes(item.id) ? (
                              <Image
                                style={{
                                  width: 30,
                                  height: 35,
                                  resizeMode: "contain",
                                }}
                                source={require("../../../assets/round.png")}
                              />
                            ) : (
                              <Image
                                style={{
                                  width: 30,
                                  height: 35,
                                  resizeMode: "contain",
                                }}
                                source={require("../../../assets/round3.png")}
                              />
                            )}

                            <View
                              style={{
                                width: "95%",
                                flexDirection: "row",
                                borderBottomWidth: 0.7,
                                borderColor: "white",
                              }}
                            >
                              <View
                                style={[styles.addItemView, { width: "25%" }]}
                              >
                                <Text
                                  style={[styles.itemName2, { fontSize: 16, paddingRight: 85 }]}
                                >
                                  {item.sku}
                                </Text>
                              </View>

                              <View
                                style={[styles.addItemView, { width: "25%" }]}
                              >
                                <Text
                                  style={[styles.itemName2, { fontSize: 16, paddingRight: 38 }]}
                                >
                                  {item.name}
                                </Text>
                              </View>

                              <View
                                style={[
                                  styles.addItemView,
                                  {
                                    width: "10%",
                                    // justifyContent: "center",
                                    // alignItems: "center",
                                  },
                                ]}
                              >
                                <Text
                                  style={[styles.itemName2, { fontSize: 16, paddingLeft: 55 }]}
                                >
                                  {item.qty_on_hand}
                                </Text>
                              </View>

                              <View
                                style={[styles.addItemView, { width: "10%", paddingLeft: 106 }]}
                              >
                                {item.status === "active" ? (
                                  <Text
                                    style={[
                                      styles.statusActive,
                                      { fontSize: 16 },
                                    ]}
                                  >
                                    {this.Capitalize(item.status)}
                                  </Text>
                                ) : (


                                  // <Text>{this.Capitalize(this.state.title)} </Text>
                                  <Text
                                    style={[styles.statusNew, { fontSize: 16 }]}
                                  >
                                    {this.Capitalize(item.status)}
                                  </Text>
                                )}
                              </View>

                              <View
                                style={[styles.addItemView, { alignItems: "flex-end", width: "25%" }]}
                              >
                                <Text
                                  style={[styles.itemName2, { fontSize: 16 }]}
                                >
                                  {item.barcode}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
              </View>
            )}
          </View>
        ) : (
          <View
            style={{
              // overflow: "scroll",
              flex: 1,
              marginHorizontal: 10,
              backgroundColor: "rgba(15, 39, 58, 0.4)",
              borderRadius: 15,
              marginBottom: 50,
              minHeight: 200,
              // opacity: 0.5
              // width: "100%",
              marginTop: 10,
              // paddingLeft: 10,
              flexWrap: "wrap",
            }}
          >
            <View style={[styles.itemContainer]}>
              <View
                style={{
                  flexDirection: "row",
                  // borderBottomWidth: 0.7,
                  // borderColor: "rgba(250, 250, 250, .4)",
                  paddingHorizontal: "2%",
                  width: "100%",
                  justifyContent: "space-around",
                  // backgroundColor: 'aqua', 
                  flexWrap: "wrap",
                }}
              >


                <View
                  style={{
                    width: "95%",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <View style={[styles.addItemView, {}]}>
                    <Text style={[styles.gridHeader2, { fontSize: 16, paddingLeft: 35, width: "100%" }]}>
                      SKU
                    </Text>
                  </View>

                  <View style={[styles.addItemView, {}]}>
                    <Text
                      style={[
                        styles.gridHeader2,
                        { fontSize: 16, lineHeight: 13, paddingLeft: 55, width: "100%" },
                      ]}
                    >
                      Product Name
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {this.state.allProduct.length > 0 && (
              <View style={{ overflow: "hidden" }}>
                {this.state.allProduct &&
                  this.state.allProduct.length > 0 &&
                  this.state.allProduct.map((item, index) => {
                    return (
                      <View style={[styles.itemContainer]}>
                        <View
                          style={{
                            flexDirection: "row",
                            // borderBottomWidth: 0.7,
                            // borderColor: "red",
                            paddingHorizontal: "2%",
                            width: "100%",
                            justifyContent: "space-around",
                            // backgroundColor: 'aqua',
                            flexWrap: "wrap",


                          }}
                        >

                          <View
                            style={{
                              width: "95%",
                              flexDirection: "row",
                              borderBottomWidth: 0.7,
                              borderColor: "white",
                              //justifyContent: "space-around",
                              marginLeft: 0
                            }}
                          >
                            <View style={[
                              styles.itemView,
                              {
                                width: "6%",
                                flexDirection: "row",

                              },
                            ]}>
                              <TouchableOpacity
                                key={index}
                                onPress={() => {
                                  this.itemSelect(item);
                                }}
                                // delayLongPress={1000}
                                style={[styles.addItemView, { width: "3%", paddingRight: "1px" }]}
                              >
                                {this.state.selected.includes(item.id) ? (
                                  <Image
                                    style={{
                                      width: 30,
                                      height: 35,
                                      resizeMode: "contain",
                                    }}
                                    source={require("../../../assets/round.png")}
                                  />
                                ) : (
                                  <Image
                                    style={{
                                      width: 30,
                                      height: 35,
                                      resizeMode: "contain",
                                    }}
                                    source={require("../../../assets/round3.png")}
                                  />
                                )}
                              </TouchableOpacity>
                            </View>
                            <View
                              style={[styles.addItemView, { marginLeft: 0, paddingLeft: 21, width: "40%" }]}
                            >
                              <Text style={[styles.itemsku, { fontSize: 16 }]}>
                                {item.sku}
                              </Text>
                            </View>

                            <View
                              style={[styles.addItemView, { paddingLeft: 73, width: "70%", marginLeft: -40 }]}
                            >
                              <Text
                                style={[styles.itemName2, { fontSize: 16 }]}
                              >
                                {item.name}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}
              </View>
            )}
          </View>
        )}
      </View>

      // </View>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("maptostate", state)
  return {
    allProduct: state.product.allProduct,
    addOrderItems: state.order.addOrderItems,
    searchProduct: state.product.searchProduct,
  };
};

const mapDispatchToProps = {
  GetAllProduct,
  AllKITProduct,
  GetSearchProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
