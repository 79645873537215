import axios from 'axios';
import {
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_FAIL,
  GET_ALL_PRODUCT,
  GET_SEARCH_PRODUCT,
  UPDATE_PRODUCT_INFO,
  UPDATE_PRODUCT_ALIAS,
  UPDATE_PRODUCT_INFO_FAIL,
  UPDATE_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST_FAIL,
  ALL_KIT_PRODUCT,
  GET_KIT_PRODUCT,
  REMOVE_KIT_PRODUCT,
  ADD_IMAGE
} from '../constants';
import AsyncStorage from '@react-native-async-storage/async-storage';

export function GetProductDetail(id) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    if (id !== 0) {
      axios({
        method: 'get',
        url: `${url}/products/${id}.json`,
        headers: { 'Authorization': `Bearer ${access_token}` }
      })
        .then((data) => {
          // console.log("actionproductdetail......", data)
          dispatch({
            type: PRODUCT_DETAIL,
            payload: data.data.product
          })
        })
        .catch((error) => {
        })
    } else {
      dispatch({
        type: PRODUCT_DETAIL_FAIL,
        payload: null
      })
    }
  }
}

export function GetAllProduct(data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
      method: 'get',
      url: `${url}/products.json?filter=${data.filter}&sort=${data.sort}&order=${data.order}&is_kit=${data.is_kit}&limit=${data.limit}&offset=${data.offset}`,
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
      .then((res) => {
        // console.log("getalllproducts......", res.data)
        dispatch({
          type: GET_ALL_PRODUCT,
          payload: res.data.products
        })
      })
      .catch((error) => {
      })
  }
}

export function GetSearchProduct(data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
      method: 'get',
      url: `${url}/products/search.json?search=${data.search}&sort=${data.sort}&order=${data.order}&is_kit=${data.is_kit}&limit=${data.limit}&offset=${data.offset}`,
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
      .then((res) => {
        dispatch({
          type: GET_SEARCH_PRODUCT,
          payload: res.data.products
        })
      })
      .catch((error) => {
      })
  }
}
// kit info action
export function AllKITProduct(productID, data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
      method: 'post',
      url: `${url}/products/${productID}/add_product_to_kit.json`,
      headers: { 'Authorization': `Bearer ${access_token}` },
      data: data
    })
      .then((res) => {
        dispatch({
          type: ALL_KIT_PRODUCT,
          payload: res.data.products
        })
      })
      .catch((error) => {
      })
  }
}
export function GETAllKITProduct(productID) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
      method: 'get',
      url: `${url}/products/${productID}.json`,
      headers: { 'Authorization': `Bearer ${access_token}` }

    })
      .then((res) => {
        // console.log('.........................................', res.data)
        dispatch({
          type: GET_KIT_PRODUCT,
          payload: res.data.product.productkitskus
        })
      })
      .catch((error) => {
      })
  }
}
export function RemoveKITProduct(productID,data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
      method: 'POST',
      url: `${url}/products/${productID}/remove_products_from_kit.json`,
      headers: { 'Authorization': `Bearer ${access_token}` },
      data:data
    })
      .then((res) => {
        dispatch({
          type: REMOVE_KIT_PRODUCT,
          payload: res.data.product.productkitskus
        })
      })
      .catch((error) => {
      })
  }
}
export function UpdateProductInfo(ID, data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    if (ID > 0) {
      axios({
        method: 'put',
        url: `${url}/products/${ID}.json`,
        headers: { 'Authorization': `Bearer ${access_token}` },
        data: data
      })
        .then(async (res) => {
          dispatch({
            type: UPDATE_PRODUCT_INFO,
            payload: {
              data: res.data,
              time: new Date()
            }
          })
        })
        .catch((error) => {
        })
    } else {
      dispatch({
        type: UPDATE_PRODUCT_INFO_FAIL,
        payload: null,
        time: new Date()
      })
    }
  }
}

export function updateProductAlias(ID, data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
      method: 'post',
      url: `${url}/products/${ID}/set_alias.json`,
      headers: { 'Authorization': `Bearer ${access_token}` },
      data: data
    })
      .then(async (res) => {
        dispatch({
          type: UPDATE_PRODUCT_ALIAS,
          payload: res.data,
          time: new Date()
        })
      })
      .catch((error) => {
      })
  }
}


export function updateProductList(data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    if (data !== null) {
      axios({
        method: 'post',
        url: `${url}/products/update_product_list.json`,
        headers: { 'Authorization': `Bearer ${access_token}` },
        data: data
      })
        .then(async (res) => {
          dispatch({
            type: UPDATE_PRODUCT_LIST,
            payload: {
              data: res.data,
              time: new Date()
            }
          })
        })
        .catch((error) => {
        })
    } else {
      dispatch({
        type: UPDATE_PRODUCT_LIST_FAIL,
        payload: {
          data: "",
          time: new Date()
        }
      })
    }
  }
}

export function printScan(ID) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    {
      axios({
        method: 'get',
        url: `${url}/products/${ID}/generate_barcode_slip.pdf`,
        headers: {
          'Authorization': `Bearer ${access_token}`,
        },
        responseType: 'blob',
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'barcode.pdf');
          document.body.appendChild(link);
          link.click();
        })

        .catch((error) => {
        })
    }
  }
}
let count = 1
export function addImage(ID, data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    // const formData= new FormData()
    let img = data.substring((data.indexOf(',')+1))
    let type = data.substring((data.indexOf("/")+1),data.indexOf(';'))
    // console.log("type here------->>>>>>>",data)
    let datanew = {
      base_64_img_upload: true,
      'product_image':{
        'image':img,
        'content_type':`image/${type}`,
        "original_filename":`product${count}.${type}`,
      }
     

    }
    // formData.append('base_64_img_upload', true)
    // formData.append('content_type','image/png')
    // formData.append('original_filename','product.png')
    // formData.append('product_image',JSON.stringify({'image':data}))
   
    // formData.append('image_type',mediaTypes)
    axios({
      method: 'post',
      url: `${url}/products/${ID}/add_image.json`,
      headers: { 'Authorization': `Bearer ${access_token}`,'content-type': 'application/json' },
      data: datanew,   
    })
      .then (res => {   
        // console.log("resss-->",res)
        if(res.data.status){
          count = count+1
        }
        dispatch({
          type: ADD_IMAGE,
          payload: {
            product_image: res.data.status,

          }
        })
        
      })

  } 
}