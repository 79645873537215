import React, { Component } from 'react';
import { View, Text, TextInput, Image, Platform, ScrollView, TouchableOpacity, Switch } from 'react-native';
import styles from '../../style/orderdetail';
import globalStyles from '../../style/global';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { LinearGradient } from 'expo-linear-gradient';


const thumf = "#999991"
const thumt = "#990991"
export default class NotesDetail extends Component {
  constructor(props) {
    super(props);
    // console.log("props-->>>>0", props.basicinfo)
    // console.log("--->++", props.bothSettings.data.data.general_setting.conf_code_product_instruction)
    this.state = {
      customerComments: false,
      notesInternal: false,
      notesToPacker: false,
      notesFromPacker: false,
      TagsSet: false,
      quickScanToolTip: false,
      quickScanToolTip1: false,
      quickScanToolTip2: false,
      quickScanToolTip3: false,
      quickScanToolTip4: false,
      quickScanToolTip5: false,
      switchtoggle: false
    };
  }
  toolTipFun=(tipno)=>{
    if(tipno == '0'){
      this.setState({ quickScanToolTip : !this.state.quickScanToolTip})
    }
    if(tipno == '1'){
      this.setState({ quickScanToolTip1 : !this.state.quickScanToolTip1})
    }
    if(tipno == '2'){
      this.setState({ quickScanToolTip2 : !this.state.quickScanToolTip2})
    }
    if(tipno == '3'){
      this.setState({ quickScanToolTip3 : !this.state.quickScanToolTip3})
    }
    if(tipno == '4'){
      this.setState({ quickScanToolTip4 : !this.state.quickScanToolTip4})
    }
    if(tipno == '5'){
      this.setState({ quickScanToolTip5 : !this.state.quickScanToolTip5})
    }
  }
  switchToggle=()=>{
    this.setState({switchtoggle : !this.state.switchtoggle })
    this.sendToserver()
  }
  sendToserver=()=>{
    this.props.handleChange("note_confirmation",this.state.switchtoggle)

  }
  openTextField=(field)=>{
    if(field == '1'){
      this.setState({ notesInternal: true });
    }
    if(field == '2'){
      this.setState({ notesToPacker: true });

    }
    if(field == '3'){
      this.setState({ customerComments: true });
    }
    if(field == '4'){
      this.setState({ TagsSet: true });
    }
    if(field == '5'){
      this.setState({ notesFromPacker: true });
    }

  }

  render() {
    let basicinfo = this.props && this.props.basicinfo
    return (
      <ScrollView style={styles.itemDetailContainer}>
        <View
          style={{
            backgroundColor: "#000",
            height: 20,
            width: "100%",
            shadowColor: "#000",
            shadowOffset: {
              width: 1,
              height: 10,
            },
            shadowOpacity: 3,
            shadowRadius: 10,

            elevation: 5,
          }}
        ></View>
        <View style={{}}>
          {basicinfo?.increment_id !== '' ? (
            <Text
              style={{
               
                fontSize: 22,
                fontWeight: "bold",
                paddingHorizontal: 15,
                fontFamily: "Poppins_700Bold",
                "background": "-webkit-linear-gradient(\n90deg\n, #4b657d, #768ea5 80%)",  "WebkitTextFillColor": "transparent","webkitBackgroundClip": "text",
              }}
            >
              Order # {basicinfo.increment_id}
            </Text>
          ) : (
            <Text
              style={{
                color: "#67869B",
                fontSize: 22,
                fontWeight: "bold",
                paddingHorizontal: 15,
                fontFamily: "Poppins_700Bold",
              }}
            >
              no id
            </Text>
          )}
        </View>
        <View
          style={{
            alignSelf: "flex-end",
            justifyContent: "center",
            alignItems: "center",

            flexDirection: "row",
            // borderWidth: 1
          }}
        >
          {/* <TouchableOpacity
                    onPress={() => {
                      this.props.addOrderItem(this.state.items);
                      this.setState({ itemadd: true });
                    }}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingRight: 30,
                      paddingVertical: 20,
                      // borderWidth: 1
                    }}
                  >
                    <Image
                      style={{ width: 40, height: 40, resizeMode: "contain" }}
                      source={require("../../../assets/plus.png")}
                    />
                    <Text
                      style={{
                        fontFamily: "Poppins_800ExtraBold",
                        // fontWeight: "bold",
                        color: "white",
                        fontSize: 16,
                        textShadowColor: "#000",
                        textShadowOffset: { width: 1, height: 1 },
                        textShadowRadius: 5,

                        elevation: 5,
                      }}
                    >
                      Add Items
                    </Text>
                  </TouchableOpacity> */}
          <TouchableOpacity
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#336599",
              height: 30,
              shadowColor: "#2bfac3",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
              // box-shadow: rgb(43 250 195) 0px 1px 3px;
            }}
            onPress={() => this.props.updateBasicInfo()}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Save & Close
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props.back()}
            style={{
              marginRight: 10,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#fff",
              borderRadius: 30,
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#494848",
              height: 30,
              shadowColor: "#e853fa",
              shadowOffset: { width: 0, height: 0.5 },
              shadowRadius: 8,
              elevation: 5,
              shadowOpacity: 0.5,
            }}
          >
            <Text
              style={{
                color: "white",
                paddingHorizontal: 10,
                fontWeight: "bold",
              }}
            >
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
        <View
          // colors={["#22292C", "#68879C", "#68879C", "#22292C"]}
          // start={[-0.3, 0.01]}
          style={{
            // overflow: "scroll",
            paddingHorizontal: "1%",
            backgroundColor: "rgba(104, 135, 156, 0.4)",
            borderRadius: 10,
            marginHorizontal: "1%",
            marginTop: 20,
            marginBottom: 10,

            // borderWidth: 1,
            // borderColor: "#50708b",
            shadowColor: "#50708b",
            shadowOffset: { width: 1, height: 1 },
            shadowRadius: 10,

          }}
        >

          {
            basicinfo &&
            <View>
              <View>
                {/* {
                  Platform.OS === "web"
                    ?
                    <View style={{ flex: 1, flexDirection: "row", alignSelf: "flex-end", margin: 5 }}>
                      <TouchableOpacity
                        onPress={this.props.updateBasicInfo}
                        style={{ marginRight: 10, borderWidth: 1, borderStyle: "solid", borderColor: "#336597", borderRadius: 30, backgroundColor: "#494848", height: 40 }}>
                        <Text style={{ color: "white", padding: 10, fontWeight: "bold" }}>Cancel</Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={{
                        marginRight: 10,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#336597",
                        borderRadius: 30,
                        backgroundColor: "#336599",
                        height: 40
                      }}
                        onPress={this.props.updateBasicInfo}
                      >
                        <Text style={{ color: "white", padding: 10, fontWeight: "bold" }}>Save & Close</Text>
                      </TouchableOpacity>
                    </View>
                    :
                    <View style={{ flexDirection: "row", position: "absolute", backgroundColor: "transparent", alignSelf: "flex-end", margin: 5 }}>
                      <TouchableOpacity onPress={this.props.back} style={{ borderWidth: 1, borderStyle: "solid", borderColor: "#336597", backgroundColor: "#494848", justifyContent: "center" }}>
                        <Text style={{ color: "white", padding: 4, fontSize: 13, fontWeight: "bold" }}>Cancel</Text>
                      </TouchableOpacity>
                      <TouchableOpacity style={{
                        marginRight: 10,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#336597",
                        backgroundColor: "#336599",
                        justifyContent: "center"
                      }}
                        onPress={this.props.updateBasicInfo}
                      >
                        <Text style={{ color: "white", padding: 4, fontSize: 13, fontWeight: "bold" }}>Save & Close</Text>
                      </TouchableOpacity>
                    </View>
                } */}
                <View style={{ flex: 1, flexDirection: "row", alignSelf: "flex-end", margin: 5 }}>
                  <Text style={{ fontWeight: "bold", fontSize: 18, color: "#dbdada", marginTop: 8 }}>Confirmation Required</Text>
                  <View style={{ marginTop: 8, marginLeft: 8 }}>
                    {this?.props?.bothSettings?.data?.data?.general_setting?.conf_code_product_instruction == "optional" ?
                      <Switch
                        trackColor={{ false: thumf, true: thumt }}
                        thumbColor={basicinfo?.note_confirmation ? "#959190" : "#959595"}
                        ios_backgroundColor="#767577"
                        style={{ borderWidth:0.5, borderRadius: 20 }}
                        onValueChange={() => this.switchToggle()}
                        value={basicinfo?.note_confirmation}
                      /> :
                      this?.props?.bothSettings?.data?.data?.general_setting?.conf_code_product_instruction == "always" ?
                        <View style={{ backgroundColor: 'green', width: 60, padding: 2,fontWeight:'bold',justifyContent:'center',textAlign:'center' }}>
                          <Text>Always</Text>
                        </View>
                        :
                        this?.props?.bothSettings?.data?.data?.general_setting?.conf_code_product_instruction == "never" ?
                          <View style={{ backgroundColor: 'grey', width: 60, padding: 2,fontWeight:'bold',justifyContent:'center',textAlign:'center' }}>
                            <Text>Never</Text>
                          </View>
                          :
                          <Switch
                            trackColor={{ false: "#999991", true: "#000" }}
                            thumbColor={basicinfo?.note_confirmation ? "#951590" : "#959595"}
                            ios_backgroundColor="#767577"
                        // backgroundColor="#3e3e3e"                    
                            style={{ borderWidth: 0.5, borderRadius: 20 }}
                            onValueChange={() => this.switchToggle()}
                            value={basicinfo?.note_confirmation}
                          />

                    }
                  </View>
                  <TouchableOpacity
                    onPress={() => this.toolTipFun("0")}>
                    <Image source={require('././../../../assets/info.png')} style={{ height: 40, width: 40, marginLeft: 4, marginTop: -4 }} />
                  </TouchableOpacity>
                </View>
                {
                  this.state.quickScanToolTip &&
                  <LinearGradient
                    locations={[0, 1]}
                    colors={["#8a9daf", "#d7e1e9"]}
                    style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 335 ,alignSelf: "flex-end",zIndex:999}}>
                    <Text style={{  fontSize: 20, color: "#030303" }}>Normally notes are displayed during the packing of the order and do not require any interaction fron the user.
                    When confirmation is enabled the user will be promted to enter or scan their unique confirmation code which is found on their user info page in the settings section</Text>
                    <View style={{ position: "absolute", right: "8%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                  </LinearGradient>
                }
                <View style={globalStyles.m_10}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.headerTextNew}>Internal Notes</Text>
                    <TouchableOpacity
                      onPress={() => this.toolTipFun("1")}>
                      <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, marginLeft: 4, marginTop: -4 }} />
                    </TouchableOpacity>
                  </View>
                  {
                  this.state.quickScanToolTip1 &&
                  <LinearGradient
                    locations={[0, 1]}
                    colors={["#8a9daf", "#d7e1e9"]}
                    style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 300 }}>
                    <Text style={{  fontSize: 20, color: "#030303" }}>Internal notes can be imported from the order source. For example if you add a note to an order in ShipStation or ShippingEasy it can be imported and saved in this field.
                    The import of internal notes  is controlled in the settings for each store.
                    Once imported, an option in the scan and pack setting determines if the note will be shown to the packer.</Text>
                    <View style={{ position: "absolute", right: "46%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                  </LinearGradient>
                }
                  {this.state.notesInternal == false && (basicinfo?.notes_internal == null ? basicinfo?.notes_internal == null : basicinfo?.notes_internal.trim() == '') ?
                    (<TouchableOpacity
                      onPress={() => {
                        this.openTextField("1")
                      }}
                      style={{
                        width: 70,
                        height: 70
                      }}
                    >
                       <FontAwesomeIcon style={{ borderColor:"white",color: "#4b657d", height: 70, width:70,stroke: "white",borderColor: "white",strokeWidth: "20",padding:10,size:"lg"}} icon={faPlus} aria-hidden="true"/>
                      {/* <Image
                        style={{ width: 40, height: 40, resizeMode: "contain" }}
                        source={require("../../../assets/plus.png")}
                      /> */}
                    </TouchableOpacity>)
                    :
                    (<TextInput
                      multiline={true}
                      numberOfLines={4}
                      style={styles.inputBox}
                      onChangeText={(text) => this.props.handleChange("notes_internal", text)}
                      value={basicinfo.notes_internal} />)
                  }
                </View>
                <View style={globalStyles.m_10}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.headerTextNew}>Notes to Packer</Text>
                    <TouchableOpacity
                      onPress={() => this.toolTipFun("2")}>
                      <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, marginLeft: 4, marginTop: -4 }} />
                    </TouchableOpacity>
                  </View>
                  {
                  this.state.quickScanToolTip2 &&
                  <LinearGradient
                    locations={[0, 1]}
                    colors={["#8a9daf", "#d7e1e9"]}
                    style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 300 }}>
                    <Text style={{  fontSize: 20, color: "#030303" }}>If notes to the packer are being added in GroovePacker directly this field should be used. Anything saved here is always shown during scanning.</Text>
                    <View style={{ position: "absolute", right: "42%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                  </LinearGradient>
                }
                  {this.state.notesToPacker == false && (basicinfo?.notes_toPacker == null ? basicinfo?.notes_toPacker == null : basicinfo?.notes_toPacker.trim() == '') ?
                    (<TouchableOpacity
                      onPress={() => {
                        this.openTextField("2")
                      }}
                      style={{
                        height: 70,
                        width: 70
                      }}
                    >
                     <FontAwesomeIcon style={{ borderColor:"white",color: "#4b657d", height: 70, width:70,stroke: "white",borderColor: "white",strokeWidth: "20",padding:10,size:"lg"}} icon={faPlus} aria-hidden="true"/>
                      {/* <Image
                        style={{ width: 40, height: 40, resizeMode: "contain" }}
                        source={require("../../../assets/plus.png")}
                      /> */}
                    </TouchableOpacity>)
                    :
                    (<TextInput
                      multiline={true}
                      numberOfLines={4}
                      style={styles.inputBox}
                      onChangeText={(text) => this.props.handleChange("notes_toPacker", text)}
                      value={basicinfo.notes_toPacker} />)
                  }
                </View>
                <View style={globalStyles.m_10}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.headerTextNew}>Customer Comments</Text>
                    <TouchableOpacity
                      onPress={() => this.toolTipFun("3")}>
                      <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, marginLeft: 4, marginTop: -4 }} />
                    </TouchableOpacity>
                  </View>
                  {
                  this.state.quickScanToolTip3 &&
                  <LinearGradient
                    locations={[0, 1]}
                    colors={["#8a9daf", "#d7e1e9"]}
                    style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 300 }}>
                    <Text style={{  fontSize: 20, color: "#030303" }}>With some integrations customer comments can be imported into this field and optionally displayed during scanning.
                    Once imported, an option in the scan and pack settings determines if the customer comments will be shown to the packer.</Text>
                    <View style={{ position: "absolute", right: "34%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                  </LinearGradient>
                }
                  {this.state.customerComments == false && (basicinfo?.customer_comments == null ? basicinfo?.customer_comments == null : basicinfo?.customer_comments?.trim() == '') ?
                    (<TouchableOpacity
                      onPress={() => {
                        this.openTextField("3")
                      }}
                      style={{
                        width: 70,
                        height: 70
                      }}
                    >
                       <FontAwesomeIcon style={{ borderColor:"white",color: "#4b657d", height: 70, width:70,stroke: "white",borderColor: "white",strokeWidth: "20",padding:10,size:"lg"}} icon={faPlus} aria-hidden="true"/>
                      {/* <Image
                        style={{ width: 40, height: 40, resizeMode: "contain" }}
                        source={require("../../../assets/plus.png")}
                      /> */}
                    </TouchableOpacity>)
                    :
                    (<TextInput
                      multiline={true}
                      numberOfLines={4}
                      style={styles.inputBox}
                      onChangeText={(text) => this.props.handleChange("customer_comments", text)}
                      value={basicinfo.customer_comments} />)
                  }
                </View>
              </View>
              <View>
                <View style={globalStyles.m_10}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.headerTextNew}>Tags</Text>
                    <TouchableOpacity
                      onPress={() => this.toolTipFun("4")}> 
                      <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, marginLeft: 4, marginTop: -4 }} />
                    </TouchableOpacity>
                  </View>
                  {
                  this.state.quickScanToolTip4 &&
                  <LinearGradient
                    locations={[0, 1]}
                    colors={["#8a9daf", "#d7e1e9"]}
                    style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 300 }}>
                    <Text style={{  fontSize: 20, color: "#030303" }}>Shopify order tags can be imported into this field and optionally displayed during scanning. 
                    Once imported, an option in the scan and pack settings determines if the tags will be shown to the packer.</Text>
                    <View style={{ position: "absolute", right: "81%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                  </LinearGradient>
                }
                  {this.state.TagsSet == false && (basicinfo?.tags == null ? basicinfo?.tags == null : basicinfo?.tags?.trim() == '') ?
                    (<TouchableOpacity
                      onPress={() => {
                        this.openTextField("4")
                      }}
                      style={{
                        height: 70,
                        width: 70
                        // borderWidth: 1
                      }}
                    >
                      <FontAwesomeIcon style={{ borderColor:"white",color: "#4b657d", height: 70, width:70,stroke: "white",borderColor: "white",strokeWidth: "20",padding:10,size:"lg"}} icon={faPlus} aria-hidden="true"/>
                      {/* <Image
                        style={{ width: 40, height: 40, resizeMode: "contain" }}
                        source={require("../../../assets/plus.png")}
                      /> */}
                    </TouchableOpacity>)
                    :
                    (<TextInput
                      multiline={true}
                      numberOfLines={4}
                      style={styles.inputBox}
                      onChangeText={(text) => this.props.handleChange("tags", text)}
                      value={basicinfo.tags} />)
                  }
                </View>
              </View>
              <View>
                <View style={globalStyles.m_10}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={styles.headerTextNew}>Notes from Packer</Text>
                    <TouchableOpacity
                      onPress={() => this.toolTipFun("5")}>
                      <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, marginLeft: 4, marginTop: -4 }} />
                    </TouchableOpacity>
                  </View>
                  {
                  this.state.quickScanToolTip5 &&
                  <LinearGradient
                    locations={[0, 1]}
                    colors={["#8a9daf", "#d7e1e9"]}
                    style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 300 }}>
                    <Text style={{  fontSize: 20, color: "#030303" }}>A note added to the order during the scanning process will be displayed here. 
                    The note can be automatically emailed to the notifications address specified in the system settings as well.
                    The note can be added during scanning by using the "Add Note" button or by scanning the action barcode: NOTE This barcode can be printed from the Scan and Pack settings section.</Text>
                    <View style={{ position: "absolute", right: "40%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                  </LinearGradient>
                }
                {/* {console.log("rrrr-->",this.state.notesFromPacker == false && basicinfo?.notes_fromPacker == null ? basicinfo?.notes_fromPacker == null : basicinfo?.notes_fromPacker?.trim() == '')} */}
                  {this.state.notesFromPacker == false && (basicinfo?.notes_fromPacker == null ? basicinfo?.notes_fromPacker == null : basicinfo?.notes_fromPacker?.trim() == '') ?
                    (<TouchableOpacity
                      onPress={() => {
                        this.openTextField("5")
                      }}
                      style={{
                        height: 70,
                        width: 70
                      }}
                    >
                     <FontAwesomeIcon style={{ borderColor:"white",color: "#4b657d", height: 70, width:70,stroke: "white",borderColor: "white",strokeWidth: "20",padding:10,size:"lg"}} icon={faPlus} aria-hidden="true"/>
                      {/* <Image
                        style={{ width: 40, height: 40, resizeMode: "contain" }}
                        source={require("../../../assets/plus.png")}
                      /> */}
                    </TouchableOpacity>)
                    :
                    (<TextInput
                      multiline={true}
                      numberOfLines={4}
                      style={styles.inputBox}
                      onChangeText={(text) => this.props.handleChange("notes_fromPacker", text)}
                      value={basicinfo.notes_fromPacker} />)
                  }
                </View>
              </View>
            </View>
          }
        </View>
      </ScrollView>
    );
  }
}
