import React, { Component, createRef } from 'react';
import { View, Text, TextInput, KeyboardAvoidingView, ScrollView, Picker, Platform, Dimensions, Image, Button } from 'react-native';
import moment from 'moment';
import styles from '../../style/orderdetail';
import globalStyles from '../../style/global';
import { TouchableOpacity } from 'react-native-gesture-handler';
// import downArrow from '../../../assets/down_arrow.png';
// import upArrow from '../../../assets/up_arrow.png';
import arrowDown from '../../../assets/arrow-down-product.png'
import arrowUp from '../../../assets/arrow-up-product.png'
// import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
//  import DateTimePicker from '@react-native-community/datetimepicker';
// import DatePicker from 'react-datepicker'
// import {Editor, EditorState} from 'draft-js';
// import {actions, RichEditor, RichToolbar} from "react-native-pell-rich-editor";
// import CNRichTextEditor, { CNToolbar, getInitialObject, getDefaultStyles } from "react-native-cn-richtext-editor";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import Switch from "react-switch";

import { LinearGradient } from 'expo-linear-gradient';

import '.../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


export default class ScanpackOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get('window').width,
      lessMore: false,
      value: new Date(),
      // isDatePickerVisible: false,
      // pickedDate:new Date(),
      pickedDate: new Date(),
      //pickedDate:new Date(),
      startDate: "",
      scanSeqcount: 0,
      quickScanToolTip: false,
      quickScanToolTip1: false,
      quickScanToolTip2: false,
      quickScanToolTip3: false,
      quickScanToolTip4: false,
      quickScanToolTip5: false,
      quickScanToolTip6: false,
      quickScanToolTip7: false,
      quickScanToolTip8: false,
      selectedTag: 'body',
      selectedStyles: [],
      editorState: EditorState.createEmpty(),
      showEditor: false,
      recordSerialDrop: '',
      contentState: ''

    };
    this.richText = React.createRef(),
      this.editor = null;


  }
  showEditorFun = () => {
    this.setState({ showEditor: !this.state.showEditor })
  }
  onEditorStateChange = (editorState) => {
    // console.log("45555555454455====>>", editorState)
    this.setState({
      editorState,
    });
  };

  toolTipFun = (open) => {
    if (open === "0") {
      this.setState({ quickScanToolTip: !this.state.quickScanToolTip })
    }
    if (open === "1") {
      this.setState({ quickScanToolTip1: !this.state.quickScanToolTip1 })
    }
    if (open === "2") {
      this.setState({ quickScanToolTip2: !this.state.quickScanToolTip2 })
    }
    if (open === "3") {
      this.setState({ quickScanToolTip3: !this.state.quickScanToolTip3 })
    }
    if (open === "4") {
      this.setState({ quickScanToolTip4: !this.state.quickScanToolTip4 })
    }
    if (open === "5") {
      this.setState({ quickScanToolTip5: !this.state.quickScanToolTip5 })
    }
    if (open === "6") {
      this.setState({ quickScanToolTip6: !this.state.quickScanToolTip6 })
    }
    if (open === "7") {
      this.setState({ quickScanToolTip7: !this.state.quickScanToolTip7 })
    }
    if (open === "8") {
      this.setState({ quickScanToolTip8: !this.state.quickScanToolTip8 })
    }


  }
  packingPlacement = () => {
    this.props.productInfoHandleChange(this.state.scanSeqcount, 'packing_placement')
  }
  countIncDec = (check) => {
    if (check == "Inc" && this.state.scanSeqcount <= 100) {
      this.setState({ scanSeqcount: this.state.scanSeqcount + 1 })
      this.packingPlacement()
    }
    if (check == "Dec" && this.state.scanSeqcount > 0) {
      this.setState({
        scanSeqcount: this.state.scanSeqcount - 1
      })
      this.packingPlacement()
    }
  }
  onStyleKeyPress = (toolType) => {
    this.editor.applyToolbar(toolType);
  }

  onSelectedTagChanged = (tag) => {
    this.setState({
      selectedTag: tag
    })
  }

  onSelectedStyleChanged = (styles) => {
    this.setState({
      selectedStyles: styles,
    })
  }

  onValueChanged = (value) => {
    this.setState({
      value: value
    });
  }
  onContentStateChange = (text) => {
    // console.log("77777777777--=-->>", text)
    // this.setState({
    //   contentState,
    // });
  };
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  contentLess = () => {
    this.setState({ lessMore: !this.state.lessMore })
  }
  updateSerialRecord = (itemValue) => {
    // console.log("serial record value here===>", itemValue)
    if (itemValue == "record_1") {
      this.setState({ recordSerialDrop: itemValue })
      this.props.productInfoHandleChange(true, "record_serial")
    }
    if (itemValue == 'record_2') {
      this.setState({ recordSerialDrop: itemValue })
      this.props.productInfoHandleChange(true, "record_serial")
      this.props.productInfoHandleChange(true, "second_record_serial")
    }
    if (itemValue == "off") {
      this.setState({ recordSerialDrop: itemValue })
      this.props.productInfoHandleChange(false, "record_serial")
      this.props.productInfoHandleChange(false, "second_record_serial")
    }
  }
  componentDidMount() {


    // console.log("basic info", this.props.basicinfo.order_placed_time)
    window.addEventListener('resize', this.updateDimensions)
    // console.log("-----rohit scanning option props-->", this.props)
    if (this?.props?.basicinfo?.record_serial) {
      this.setState({ recordSerialDrop: "record_1" })
    }
    if (this?.props?.basicinfo?.second_record_serial && this?.props?.basicinfo?.record_serial) {
      this.setState({ recordSerialDrop: 'record_2' })
    }
    if (this?.props?.basicinfo?.second_record_serial == false && this?.props?.basicinfo?.record_serial == false) {
      this.setState({ recordSerialDrop: 'off' })
    }
    this.setState({ scanSeqcount: this?.props?.basicinfo?.packing_placement })
    //  this.setState({pickedDate:this.props.basicinfo.order_placed_time})
    // console.log("chnage date formate", moment(this.props.basicinfo.order_placed_time).format('ddd MMM DD YYYY HH:mm:ss'))
    // this.setState({pickedDate:moment(this.props.basicinfo.order_placed_time).format('ddd MMM DD YYYY HH:mm:ss')})
    // this.setState({ pickedDate: new Date(this.props.basicinfo.order_placed_time) })
    // console.log("Cgheck new date", new Date(this.props.basicinfo.order_placed_time));
  }
  componentWillUnmount() {
    window.addEventListener('resize', this.updateDimensions)
  }



  render() {
    // let basicinfo = this.props && this.props.basicinfo
    // console.log("basic", basicinfo);
    let id = "111"
    let { barcodes, basicinfo } = this.props

    return (
      <View>
        {this.state.windowWidth >= 900 ?
          <>
            {
              basicinfo &&
              <>
                <View style={{ marginLeft: "30px", width: 200 }}>
                  {/* <View style={{ flexDirection: "row", alignSelf: "flex-end", marginRight: 10, marginLeft: 10, padding: 10 }}>
                    <TouchableOpacity onPress={() => this.props.updateProduct()}><Text style={{ padding: 10, color: "#fff", fontWeight: "bold", borderRadius: 20, marginRight: 10 }}>Save & Close</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => this.props.navigation.goBack()}><Text style={{ padding: 10, color: "#fff", fontWeight: "bold", borderRadius: 20 }}>Close </Text></TouchableOpacity>
                  </View> */}

                  <Text style={{
                    color: "#67869B", fontWeight: "bold", fontFamily: "Poppins_700Bold", fontSize: "20px", marginLeft: "30px", marginTop: -30, zIndex: 999,
                    background: " -webkit-linear-gradient(#4b657d  , #768ea5)",
                      webkitBackgroundClip: "text", webkitTextFillColor: "transparent"
                  }}>{this?.props?.product?.skus[0]?.sku}</Text>

                </View>
                <View style={{
                  // overflow: "scroll",
                  paddingHorizontal: "1%",
                  backgroundColor: "rgba(104, 135, 156, 0.4)",
                  borderRadius: 10,
                  marginHorizontal: "1%",
                  marginTop: 20,
                  marginBottom: 10,

                  // borderWidth: 1,
                  // borderColor: "#50708b",
                  shadowColor: "#50708b",
                  shadowOffset: { width: 1, height: 1 },
                  shadowRadius: 10,
                }}>
                  {
                    this.state.quickScanToolTip &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", marginTop: '6%', borderRadius: 5, width: 335, alignSelf: "flex-start", zIndex: 99 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>Items with missing or damaged barcodes can be Passed using the Pass button when enabled. The option can be disabled for items that should never be passed. "With Confirmation" will require a user with "Pass Button" permission to provide their confirmation code. "With Message" will permit Passing but a message will be shown which discourages unnecessary use.</Text>
                      <View style={{ position: "absolute", right: "33%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  {
                    this.state.quickScanToolTip1 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", marginTop: '6%', borderRadius: 5, width: 335, alignSelf: "center", zIndex: 99 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>When enabled, this setting allows you to scan one of an item and then manually type in a quantity rather than scanning each item one by one. It's ideal when you have many items that are pre-counted in cases. You can set the type-in trigger in the Scan and Pack settings.</Text>
                      <View style={{ position: "absolute", right: "22%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  {
                    this.state.quickScanToolTip2 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", marginTop: '6%', borderRadius: 5, width: 335, alignSelf: "flex-end", zIndex: 99 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>When enabled, the packer will be prompted to scan a serial number each time this product is packed. The recorded serial can then be viewed in the Order Activity log or downloaded in the Order Serial CSV. One or two entries can be recorded based on the setting.</Text>
                      <View style={{ position: "absolute", right: "8%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  {
                    this.state.quickScanToolTip3 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", marginTop: "14%", borderRadius: 5, width: 550, alignSelf: "flex-start", zIndex: 99 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>Intangible items, also known as transactional items, are non-physical items that do not need to be packed. Examples are Coupon Codes, Insurance, Same day handling charges etc. Many times these items import along with regular order items. To avoid having these items prompted during Scan and Pack you can enable this option to designate them as intangible.If you have intangible items that are dynamically generated but always contain a specific string you can have GroovePacker automatically set these items to intangible. You'll find this option in the Scan and Pack settings.</Text>
                      <View style={{ position: "absolute", right: "57%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  {
                    this.state.quickScanToolTip4 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", right: "35%", marginTop: "14%", borderRadius: 5, width: 335, alignSelf: "center", zIndex: 99 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>When this option is enabled for a product you can add it to any order you're packing just by scanning it. This is great for tracking the inventory of boxes or bags that are decided on at the time of packing. Catalogs, coupons, freebie items can be scanned and tracked too. Items added will be saved with the order record in GroovePacker and included on packing slips printed after packing is complete.</Text>
                      <View style={{ position: "absolute", right: "30%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  {
                    this.state.quickScanToolTip5 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", marginTop: "13.8%", borderRadius: 5, width: 335, alignSelf: "flex-end", zIndex: 99 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>Unlike the commonly used "Pass" option the Skippable option is usually enabled only for non-essential items, like free-bees or coupon cards which are normally scanned but which can be skipped when stock is out. The SKIP action barcdoe is scanned to skip an item. Skipped items may be removed from the order or may remain depending on the global setting found in the Scan and Pack options with the SKIP action barcode.</Text>
                      <View style={{ position: "absolute", right: "8%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  {
                    this.state.quickScanToolTip6 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", marginTop: "18%", borderRadius: 5, width: 335, alignSelf: "flex-start", zIndex: 99 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>The packing instructions text here will be shown every time this item is suggested for scanning. In addition to adding instructions here they can also be imported in bulk using the CSV product importer.</Text>
                      <View style={{ position: "absolute", right: "32%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  {
                    this.state.quickScanToolTip7 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", marginTop: "7%", borderRadius: 5, width: 660, alignSelf: "flex-start", zIndex: 99 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>The suggested scanning sequence is determined automatically. Items are sorted alphabetically by the Primary bin location. If no primary location is saved the SKU will be used to sort the items. If you wish to Override this sorting sequence and force some items to display before or after other items this setting can be used. ie. If the item should be picked or scanned before all others, or after all others you can adjust this value. By default, all items have a value of 50. Items with override values closer to 1 will be sorted before those with values closer to 99 regardless of the SKU or Location saved. The override value can also be set in bulk using a CSV file.</Text>
                      <View style={{ position: "absolute", right: "38%", bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  {
                    this.state.quickScanToolTip8 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "absolute", marginTop: "13%", borderRadius: 5, width: 335, alignSelf: "flex-start", zIndex: 99, marginLeft: 107 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>Custom product data can be entered here or imported using the product CSV importer. Each field can optionally be displayed when the item is suggested for scanning. The is very useful when variant information like size or color is not easily readable in the SKU or product name but is required by the picker or packer.</Text>
                      <View style={{ position: "absolute", right: "1%", bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{ margin: 10, justifyContent: "center" }}>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{
                        flex: 2, flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: "2%"
                      }}>
                        <View style={{ flexDirection: 'row' }}>

                          <Text style={{ fontFamily: "Poppins_600SemiBold", fontSize: 18, flex: 2, padding: 10, color: '#fff' }}>
                            Pass / click-scan
                          </Text>
                          <TouchableOpacity onPress={() => this.toolTipFun("0")} style={{ height: 30, width: 30, marginTop: 15 }}>
                            <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />

                          </TouchableOpacity>
                        </View>

                        <View style={{ marginTop: -10, marginLeft: 10 }}>
                          {/* <Image style={{ position: "absolute", bottom: 6, right: 0, height: 19, width: 18 }} source={arrowDown} /> */}
                          <Picker
                            mode="dropdown"
                            selectedValue={basicinfo.click_scan_enabled}
                            style={{ height: 30, width: "auto", background: 'transparent', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomColor: '#fff', color: '#fff' }}
                            onValueChange={(itemValue, itemIndex) => this.props.productInfoHandleChange(itemValue, "click_scan_enabled")}
                          >
                            {
                              this.props.pickerSelectedOptions.map((option, index) => {
                                return (
                                  <Picker.Item label={option.label} value={option.value} color="#000" />
                                )
                              })
                            }
                          </Picker>
                        </View>
                      </View>
                      <View style={{
                        flex: 2,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: "2%"
                      }}>
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ fontFamily: "Poppins_600SemiBold", fontSize: 18, flex: 2, padding: 10, color: '#fff' }}>
                            Type-In Count
                          </Text>
                          <TouchableOpacity onPress={() => this.toolTipFun("1")} style={{ height: 30, width: 30, marginTop: 10 }}>
                            <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />

                          </TouchableOpacity>
                        </View>
                        <View style={{ marginTop: -10 }}>
                          {/* <Image style={{ position: "absolute", bottom: 6, right: 0, height: 19, width: 18 }} source={arrowDown} /> */}
                          <Picker
                            mode="dropdown"
                            selectedValue={basicinfo.type_scan_enabled}
                            style={{ height: 30, width: "auto", background: 'transparent', color: '#fff', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomColor: '#fff' }}
                            onValueChange={(itemValue, itemIndex) => this.props.productInfoHandleChange(itemValue, "type_scan_enabled")}
                          >
                            {
                              this.props.pickerSelectedOptions.map((option, index) => {
                                return (
                                  <Picker.Item label={option.label} value={option.value} color='#000' />
                                )
                              })
                            }
                          </Picker>
                        </View>
                      </View>
                      <View style={{
                        flex: 2,
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        right: "1%"
                      }}>
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ fontFamily: "Poppins_600SemiBold", fontSize: 18, flex: 2, padding: 10, color: '#fff' }}>
                            Record Serial / Lot / Batch
                          </Text>
                          <TouchableOpacity onPress={() => this.toolTipFun("2")} style={{ height: 30, width: 30, marginTop: 10 }}>
                            <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />

                          </TouchableOpacity>
                        </View>
                        <View style={{ marginTop: -10, marginRight: 10 }}>
                          {/* <Image style={{ position: "absolute", bottom: 6, right: 0, height: 19, width: 18 }} source={arrowDown} /> */}
                          <Picker
                            mode="dropdown"
                            selectedValue={this.recordSerialDrop}
                            style={{ height: 30, width: 200, background: 'transparent', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomColor: '#fff', color: '#fff' }}
                            onValueChange={(itemValue, itemIndex) => this.updateSerialRecord(itemValue)}
                          >
                            {
                              this.props.pickerSelectedRecordOptions.map((option, index) => {
                                return (
                                  <Picker.Item label={option.label} value={option.value} color='#000' />
                                )
                              })
                            }
                          </Picker>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ margin: 15, justifyContent: "center" }}>
                    {/* <View style={{flexDirection:'row'}}> */}

                    {/* </View> */}
                    <View style={{ flexDirection: "row", marginTop: "3%" }}>

                      <View style={{
                        flex: 2, flexDirection: "row",
                        justifyContent: 'flex-start',
                      }}>
                        {/* <Switch
                          trackColor={{ false: "#767577", true: "#81b0ff" }}
                          thumbColor={basicinfo.is_intangible ? "#f5dd4b" : "#f4f3f4"}
                          backgroundColor="#3e3e3e"
                          style={{ borderWidth: 1, borderRadius: 20 }}
                          onValueChange={(value, name) => this.props.productInfoHandleChange(value, "is_intangible")}
                          value={basicinfo.is_intangible}
                        /> */}
                        <Switch
                          checked={basicinfo.is_intangible}
                          onChange={(value, name) => this.props.productInfoHandleChange(value, "is_intangible")}
                          offColor="#5c6e79"
                          onColor="#65baea"
                          offHandleColor="#969696"
                          onHandleColor="#fbfdfe"
                          handleDiameter={22}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        />

                        <Text style={{ marginLeft: 10, color: '#fff', marginTop: -4, fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Intangible Item</Text>
                        <TouchableOpacity onPress={() => this.toolTipFun("3")}>
                          <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, marginTop: -5 }} />

                        </TouchableOpacity>
                      </View>
                      <View style={{
                        flex: 2, flexDirection: "row",
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        {/* <Switch
                          trackColor={{ false: "#767577", true: "#81b0ff" }}
                          thumbColor={basicinfo.add_to_any_order ? "#f5dd4b" : "#f4f3f4"}
                          backgroundColor="#3e3e3e"
                          style={{ borderWidth: 1, borderRadius: 20 }}
                          onValueChange={(value, name) => this.props.productInfoHandleChange(value, "add_to_any_order")}
                          value={basicinfo.add_to_any_order}
                        /> */}
                        <Switch
                          checked={basicinfo.add_to_any_order}
                          onChange={(value, name) => this.props.productInfoHandleChange(value, "add_to_any_order")}
                          offColor="#5c6e79"
                          onColor="#65baea"
                          offHandleColor="#969696"
                          onHandleColor="#fbfdfe"
                          handleDiameter={22}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        />
                        <Text style={{ marginLeft: 10, color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Add to any order</Text>
                        <TouchableOpacity onPress={() => this.toolTipFun("4")}>
                          <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />

                        </TouchableOpacity>
                      </View>
                      <View style={{
                        flex: 2, flexDirection: "row",
                        justifyContent: 'flex-end',
                        right: "0.5%"
                      }}>
                        {/* <Switch
                          trackColor={{ false: "#767577", true: "#81b0ff" }}
                          thumbColor={basicinfo.is_skippable ? "#f5dd4b" : "#f4f3f4"}
                          backgroundColor="#3e3e3e"
                          style={{ borderWidth: 1, borderRadius: 20 }}
                          onValueChange={(value, name) => this.props.productInfoHandleChange(value, "is_skippable")}
                          value={basicinfo.is_skippable}
                        /> */}
                        <Switch
                          checked={basicinfo.is_skippable}
                          onChange={(value, name) => this.props.productInfoHandleChange(value, "is_skippable")}
                          offColor="#5c6e79"
                          onColor="#65baea"
                          offHandleColor="#969696"
                          onHandleColor="#fbfdfe"
                          handleDiameter={22}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        />
                        <Text style={{ marginLeft: 10, marginTop: -4, color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Optional Item / skippable</Text>
                        <TouchableOpacity onPress={() => this.toolTipFun("5")}>
                          <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, marginTop: -5 }} />

                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                  <View style={{ margin: 10 }}>

                    <View style={{ flexDirection: 'row', marginBottom: -5, marginLeft: "1%" }}>
                      <View
                        style={{ flexDirection: 'row', alignSelf: "flex-start", justifyContent: 'center', marginBottom: -10 }}
                      >
                        <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Packing Instructions</Text>
                        <TouchableOpacity onPress={() => this.toolTipFun("6")}>
                          <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />

                        </TouchableOpacity>
                      </View>
                      <View style={{ flex: 2, alignSelf: "flex-end", justifyContent: 'center', alignItems: 'flex-end', right: "1%" }}>
                        <TouchableOpacity onPress={() => this.showEditorFun()}>
                          <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>HTML</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View>
                      {
                        this.state.showEditor ?

                          <View style={{ marginTop: 5 }}>
                            <Editor
                              editorState={this.state.editorState}
                              initialContentState={basicinfo.packing_instructions}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(text, name) => this.onEditorStateChange(text)}
                              onContentStateChange={(text) => this.props.productInfoHandleChange(text.blocks[0].text, "packing_instructions")}

                            />
                          </View>
                          :

                          <TextInput
                            multiline={true}
                            numberOfLines={4}
                            style={{
                              margin: 10,
                              // borderWidth: 1,
                              borderRadius: 15,
                              padding: 10,
                              backgroundColor: "#dbdada",
                            }}
                            value={basicinfo.packing_instructions}
                            onChangeText={(text, name) => this.props.productInfoHandleChange(text, "packing_instructions")}

                          />
                      }


                    </View>

                  </View>
                  <View style={{ marginLeft: 40 }}>
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ width: 50, height: 30, borderRadius: 5, backgroundColor: "#dbdada", justifyContent: 'center', alignItems: 'center', marginTop: 7 }}>
                        <Text>{this.state.scanSeqcount}</Text>
                      </View>
                      <View style={{ flexDirection: 'column', marginTop: 5, marginLeft: 5, marginRight: 5 }}>
                        <TouchableOpacity style={{ marginBottom: -5 }} onPress={() => this.countIncDec("Inc")}>
                          <Image style={{ width: 15, height: 20 }} source={arrowUp} />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ marginTop: -5 }} onPress={() => this.countIncDec("Dec")}>
                          <Image style={{ width: 15, height: 20 }} source={arrowDown} />
                        </TouchableOpacity>
                      </View>
                      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Scanning Sequence Override</Text>
                      </View>
                      <View>
                        <TouchableOpacity onPress={() => this.toolTipFun("7")}>
                          <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, marginTop: 5 }} />

                        </TouchableOpacity>
                      </View>
                    </View>

                  </View>
                  <View>

                    <View style={{ flexDirection: 'row', margin: 15, width: '100%' }}>
                      <View style={{ flexDirection: 'row', width: '33%', alignSelf: 'flex-start' }}>
                        <View style={{ flexDirection: 'column', width: '70%' }}>
                          <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Custom Product 1</Text>
                          <TextInput
                            style={{
                              // margin: 10,
                              // borderWidth: 1,
                              width: '100%',
                              borderRadius: 10,
                              padding: 10,
                              backgroundColor: "#dbdada",
                            }}
                            value={basicinfo.custom_product_1}
                            onChangeText={(text, name) => this.props.productInfoHandleChange(text, "custom_product_1")}
                          />
                        </View>
                        <View style={{ flexDirection: 'column', marginLeft: 10, justifyContent: 'center', alignItems: 'center' }}>
                          <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Display</Text>
                          {/* <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={basicinfo.custom_product_display_1 ? "#f5dd4b" : "#f4f3f4"}
                            backgroundColor="#3e3e3e"
                            style={{ borderWidth: 1, borderRadius: 20, marginTop: 5 }}
                            onValueChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_1")}
                            value={basicinfo.custom_product_display_1}
                          /> */}
                          <Switch
                            checked={basicinfo.custom_product_display_1}
                            onChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_1")}
                            offColor="#5c6e79"
                            onColor="#65baea"
                            offHandleColor="#969696"
                            onHandleColor="#fbfdfe"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                          />
                        </View>
                        <View style={{ marginTop: 32, left: -5, height: 30, width: 30 }}>
                          <TouchableOpacity onPress={() => this.toolTipFun("8")}>
                            <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />

                          </TouchableOpacity>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', flex: 2, alignSelf: 'center' }}>
                        <View style={{ flexDirection: 'column', width: '70%' }}>
                          <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Custom Product 2</Text>
                          <TextInput
                            style={{
                              // margin: 10,
                              // borderWidth: 1,
                              borderRadius: 10,
                              padding: 10,
                              backgroundColor: "#dbdada",
                            }}
                            value={basicinfo.custom_product_2}
                            onChangeText={(text, name) => this.props.productInfoHandleChange(text, "custom_product_2")}
                          />
                        </View>
                        <View style={{ flexDirection: 'column', marginLeft: 10, justifyContent: 'center', alignItems: 'center' }}>
                          <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Display</Text>
                          {/* <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={basicinfo.custom_product_display_2 ? "#f5dd4b" : "#f4f3f4"}
                            backgroundColor="#3e3e3e"
                            style={{ borderWidth: 1, borderRadius: 20, marginTop: 5 }}
                            onValueChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_2")}
                            value={basicinfo.custom_product_display_2}
                          /> */}
                          <Switch
                            checked={basicinfo.custom_product_display_2}
                            onChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_2")}
                            offColor="#5c6e79"
                            onColor="#65baea"
                            offHandleColor="#969696"
                            onHandleColor="#fbfdfe"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                          />
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', flex: 2, alignSelf: 'flex-end' }}>
                        <View style={{ flexDirection: 'column', width: '70%' }}>
                          <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Custom Product 3</Text>
                          <TextInput
                            style={{
                              // margin: 10,
                              // borderWidth: 1,
                              borderRadius: 10,
                              padding: 10,
                              backgroundColor: "#dbdada",
                            }}
                            value={basicinfo.custom_product_3}
                            onChangeText={(text, name) => this.props.productInfoHandleChange(text, "custom_product_3")}
                          />
                        </View>
                        <View style={{ flexDirection: 'column', marginLeft: 10, justifyContent: 'center', alignItems: 'center' }}>
                          <Text style={{ color: '#fff', fontFamily: "Poppins_600SemiBold", fontSize: 18 }}>Display</Text>
                          {/* <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={basicinfo.custom_product_display_3 ? "#f5dd4b" : "#f4f3f4"}
                            backgroundColor="#3e3e3e"
                            style={{ borderWidth: 1, borderRadius: 20, marginTop: 5 }}
                            onValueChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_3")}
                            value={basicinfo.custom_product_display_3}
                          /> */}
                          <Switch
                            checked={basicinfo.custom_product_display_3}
                            onChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_3")}
                            offColor="#5c6e79"
                            onColor="#65baea"
                            offHandleColor="#969696"
                            onHandleColor="#fbfdfe"
                            handleDiameter={22}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            }
          </>
          :
          <>
            <View style={[this.state.windowWidth < 450 ? { justifyContent: 'center', alignItems: 'center' } : { marginLeft: "30px", marginTop: "-35px" }]}>
              {/* <View style={{ flexDirection: "row", alignSelf: "flex-end", marginRight: 10, marginLeft: 10, padding: 10 }}>
                    <TouchableOpacity onPress={() => this.props.updateProduct()}><Text style={{ padding: 10, color: "#fff", fontWeight: "bold", borderRadius: 20, marginRight: 10 }}>Save & Close</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => this.props.navigation.goBack()}><Text style={{ padding: 10, color: "#fff", fontWeight: "bold", borderRadius: 20 }}>Close </Text></TouchableOpacity>
                  </View> */}

              <Text style={{
             fontWeight: "bold", fontFamily: "Poppins_700Bold", fontSize: "18px",background: " -webkit-linear-gradient(#4b657d  , #768ea5)",
             webkitBackgroundClip: "text", webkitTextFillColor: "transparent" 
              }}>{this?.props?.product?.skus[0]?.sku}</Text>

            </View>
            <View style={{
              // overflow: "scroll",
              paddingHorizontal: "1%",
              backgroundColor: "rgba(104, 135, 156, 0.3)",
              borderRadius: 10,
              marginHorizontal: "1%",
              marginTop: 20,
              marginBottom: 10,
              position: "relative",
              zIndex: 9,

              // borderWidth: 1,
              // borderColor: "#50708b",
              shadowColor: "#50708b",
              shadowOffset: { width: 1, height: 1 },
              shadowRadius: 10,
            }}>
              <View style={{ margin: 10 }}>
                <View>
                  {
                    this.state.quickScanToolTip &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>Items with missing or damaged barcodes can be Passed using the Pass button when enabled. The option can be disabled for items that should never be passed. "With Confirmation" will require a user with "Pass Button" permission to provide their confirmation code. "With Message" will permit Passing but a message will be shown which discourages unnecessary use.</Text>
                      <View style={{ position: "absolute", right: 105, bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{
                    flex: 2, flexDirection: 'column',
                    // justifyContent: 'flex-start',
                    // alignItems: 'flex-start'
                  }}>
                    <View style={{ flexDirection: 'row', width: '200px' }}>
                      <Text style={{ fontWeight: "bold", fontSize: 15, flex: 2, padding: 10, color: '#fff', marginLeft: -10 }}>
                        Pass / click-scan
                      </Text>
                      <TouchableOpacity style={{ marginTop: 6, marginRight: 12 }} onPress={() => this.toolTipFun("0")}>
                        <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: '200px', marginTop: -10 }}>
                      {/* <Image style={{ position: "absolute", bottom: 3, right: 18, height: 22, width: 19 }} source={arrowDown} /> */}
                      <Picker
                        mode="dropdown"
                        selectedValue={basicinfo.type_scan_enabled}
                        style={{ height: 25, width: "180px", background: 'transparent', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomColor: '#fff', color: '#fff' }}
                        onValueChange={(itemValue, itemIndex) => this.props.productInfoHandleChange(itemValue, "type_scan_enabled")}
                      >
                        {
                          this.props.pickerSelectedOptions.map((option, index) => {
                            return (
                              <Picker.Item label={option.label} value={option.value} color='#000' />
                            )
                          })
                        }
                      </Picker>
                    </View>

                  </View>
                </View>
                <View style={{ marginTop: 10 }}>
                  {
                    this.state.quickScanToolTip1 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>When enabled, this setting allows you to scan one of an item and then manually type in a quantity rather than scanning each item one by one. It's ideal when you have many items that are pre-counted in cases. You can set the type-in trigger in the Scan and Pack settings.</Text>
                      <View style={{ position: "absolute", right: 107, bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{
                    flex: 2,
                    flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                  }}>
                    <View style={{ flexDirection: 'row', width: '200px' }}>
                      <Text style={{ fontWeight: "bold", fontSize: 15, flex: 2, padding: 10, color: '#fff', marginLeft: -10 }}>
                        Type-In Count
                      </Text>
                      <TouchableOpacity style={{ marginTop: 6, marginRight: 12 }} onPress={() => this.toolTipFun("1")}>
                        <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: '200px', marginTop: -10 }}>
                      {/* <Image style={{ position: "absolute", bottom: 3, right: 18, height: 22, width: 19 }} source={arrowDown} /> */}
                      <Picker
                        mode="dropdown"
                        selectedValue={basicinfo.type_scan_enabled}
                        style={{ height: 25, width: '180px', background: 'transparent', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomColor: '#fff', color: '#fff' }}
                        onValueChange={(itemValue, itemIndex) => this.props.productInfoHandleChange(itemValue, "type_scan_enabled")}
                      >
                        {
                          this.props.pickerSelectedOptions.map((option, index) => {
                            return (
                              <Picker.Item label={option.label} value={option.value} color='#000' />
                            )
                          })
                        }
                      </Picker>
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: 10 }}>
                  {
                    this.state.quickScanToolTip2 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>When enabled, the packer will be prompted to scan a serial number each time this product is packed. The recorded serial can then be viewed in the Order Activity log or downloaded in the Order Serial CSV. One or two entries can be recorded based on the setting.</Text>
                      <View style={{ position: "absolute", right: 85, bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{
                    flex: 2,
                    flexDirection: 'column',
                    // justifyContent: 'flex-end',
                    // alignItems: 'flex-end',
                  }}>
                    <View style={{ flexDirection: 'row', width: '228px' }}>
                      <Text style={{ fontWeight: "bold", fontSize: 15, flex: 2, padding: 10, color: '#fff', marginLeft: -10 }}>
                        Record Serial / Lot / Batch
                      </Text>
                      <TouchableOpacity style={{ marginTop: 4 }} onPress={() => this.toolTipFun("2")}>
                        <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />
                      </TouchableOpacity>
                    </View>
                    <View style={{ width: '200px', marginTop: -10 }}>
                      {/* <Image style={{ position: "absolute", bottom: 3, right: 18, height: 22, width: 19 }} source={arrowDown} /> */}
                      <Picker
                        mode="dropdown"
                        selectedValue={basicinfo.type_scan_enabled}
                        style={{ height: 25, width: "180px", background: 'transparent', borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderBottomColor: '#fff', color: '#fff' }}
                        onValueChange={(itemValue, itemIndex) => this.props.productInfoHandleChange(itemValue, "type_scan_enabled")}
                      >
                        {
                          this.props.pickerSelectedRecordOptions.map((option, index) => {
                            return (
                              <Picker.Item label={option.label} value={option.value} color='#000' />
                            )
                          })
                        }
                      </Picker>
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: 15 }}>
                  {
                    this.state.quickScanToolTip3 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999, marginBottom: 5 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>Intangible items, also known as transactional items, are non-physical items that do not need to be packed. Examples are Coupon Codes, Insurance, Same day handling charges etc. Many times these items import along with regular order items. To avoid having these items prompted during Scan and Pack you can enable this option to designate them as intangible.If you have intangible items that are dynamically generated but always contain a specific string you can have GroovePacker automatically set these items to intangible. You'll find this option in the Scan and Pack settings.</Text>
                      <View style={{ position: "absolute", right: 120, bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{
                    flex: 2, flexDirection: "row",

                  }}>
                    {/* <Switch
                      trackColor={{ false: "#767577", true: "#81b0ff" }}
                      thumbColor={basicinfo.is_intangible ? "#f5dd4b" : "#f4f3f4"}
                      backgroundColor="#3e3e3e"
                      style={{ borderWidth: 1, borderRadius: 20 }}
                      onValueChange={(value, name) => this.props.productInfoHandleChange(value, "is_intangible")}
                      value={basicinfo.is_intangible}
                    /> */}
                    <Switch
                      checked={basicinfo.is_intangible}
                      onChange={(value, name) => this.props.productInfoHandleChange(value, "is_intangible")}
                      offColor="#5c6e79"
                      onColor="#65baea"
                      offHandleColor="#969696"
                      onHandleColor="#fbfdfe"
                      handleDiameter={22}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                    />
                    <Text style={{ marginLeft: 10, color: '#fff', fontWeight: "bold", fontSize: 15 }}>Intangible Item</Text>
                    <TouchableOpacity onPress={() => this.toolTipFun("3")}>
                      <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, top: -5 }} />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={{ marginTop: 15 }}>
                  {
                    this.state.quickScanToolTip4 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999, marginBottom: 5 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>When this option is enabled for a product you can add it to any order you're packing just by scanning it. This is great for tracking the inventory of boxes or bags that are decided on at the time of packing. Catalogs, coupons, freebie items can be scanned and tracked too. Items added will be saved with the order record in GroovePacker and included on packing slips printed after packing is complete.</Text>
                      <View style={{ position: "absolute", right: 110, bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{
                    flex: 2, flexDirection: "row",
                    // justifyContent: 'center',
                    // alignItems: 'center'
                  }}>
                    {/* <Switch
                      trackColor={{ false: "#767577", true: "#81b0ff" }}
                      thumbColor={basicinfo.add_to_any_order ? "#f5dd4b" : "#f4f3f4"}
                      backgroundColor="#3e3e3e"
                      style={{ borderWidth: 1, borderRadius: 20 }}
                      onValueChange={(value, name) => this.props.productInfoHandleChange(value, "add_to_any_order")}
                      value={basicinfo.add_to_any_order}
                    /> */}
                    <Switch
                      checked={basicinfo.add_to_any_order}
                      onChange={(value, name) => this.props.productInfoHandleChange(value, "add_to_any_order")}
                      offColor="#5c6e79"
                      onColor="#65baea"
                      offHandleColor="#969696"
                      onHandleColor="#fbfdfe"
                      handleDiameter={22}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                    />
                    <Text style={{ marginLeft: 10, color: '#fff', fontWeight: "bold", fontSize: 15 }}>Add to any order</Text>
                    <TouchableOpacity onPress={() => this.toolTipFun("4")}>
                      <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, top: -5 }} />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={{ marginTop: 15 }}>
                  {
                    this.state.quickScanToolTip5 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999, marginBottom: 5 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>Unlike the commonly used "Pass" option the Skippable option is usually enabled only for non-essential items, like free-bees or coupon cards which are normally scanned but which can be skipped when stock is out. The SKIP action barcdoe is scanned to skip an item. Skipped items may be removed from the order or may remain depending on the global setting found in the Scan and Pack options with the SKIP action barcode.</Text>
                      <View style={{ position: "absolute", right: 45, bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{
                    flex: 2, flexDirection: "row",
                    // justifyContent: 'flex-end'
                  }}>
                    {/* <Switch
                      trackColor={{ false: "#767577", true: '#f3f9e3' }}
                      thumbColor={basicinfo.is_skippable ? "#fff" : "#f4f3f4"}
                      backgroundColor="#3e3e3e"
                      style={{ borderWidth: 1, borderRadius: 20}}
                      onValueChange={(value, name) => this.props.productInfoHandleChange(value, "is_skippable")}
                      value={basicinfo.is_skippable}
                    /> */}
                    <Switch
                      checked={basicinfo.is_skippable}
                      onChange={(value, name) => this.props.productInfoHandleChange(value, "is_skippable")}
                      offColor="#5c6e79"
                      onColor="#65baea"
                      offHandleColor="#969696"
                      onHandleColor="#fbfdfe"
                      handleDiameter={22}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={40}
                    />
                    <Text style={{ marginLeft: 10, color: '#fff', fontWeight: "bold", fontSize: 15 }}>Optional Item / skippable</Text>
                    <TouchableOpacity onPress={() => this.toolTipFun("5")}>
                      <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, top: -5 }} />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={{ marginTop: 15 }}>
                  {
                    this.state.quickScanToolTip6 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>The packing instructions text here will be shown every time this item is suggested for scanning. In addition to adding instructions here they can also be imported in bulk using the CSV product importer.</Text>
                      <View style={{ position: "absolute", right: 155, bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{ flexDirection: 'row', marginBottom: -10 }}>
                    <View
                      style={{ flexDirection: 'row', alignSelf: "flex-start", justifyContent: 'center', alignItems: 'flex-start', bottom: -8 }}
                    >
                      <Text style={{ color: '#fff', fontWeight: "bold", fontSize: 15 }}>Packing Instructions</Text>
                      <TouchableOpacity onPress={() => this.toolTipFun("6")}>
                        <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, top: -5 }} />
                      </TouchableOpacity>
                    </View>
                    <View style={{ flex: 2, alignSelf: "flex-end", justifyContent: 'center', alignItems: 'flex-end' }}>
                      <TouchableOpacity onPress={() => this.showEditorFun()}>
                        <Text style={{ color: '#fff', fontWeight: "bold", fontSize: 15 }}>HTML</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View>
                    {
                      this.state.showEditor ?
                        <View style={{ marginTop: 10 }}>
                          <Editor
                            editorState={this.state.editorState}
                            initialContentState={basicinfo.packing_instructions}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(text, name) => this.onEditorStateChange(text)}
                            onContentStateChange={(text, name) => this.props.productInfoHandleChange(text.blocks[0].text, "packing_instructions")}
                          />
                        </View>
                        :
                        <TextInput
                          multiline={true}
                          numberOfLines={4}
                          style={{
                            margin: 10,
                            // borderWidth: 1,
                            borderRadius: 15,
                            padding: 10,
                            backgroundColor: "#dbdada",
                          }}
                          value={basicinfo.packing_instructions}
                          onChangeText={(text, name) => this.props.productInfoHandleChange(text, "packing_instructions")}
                        />
                    }
                  </View>
                </View>
                <View>
                  {
                    this.state.quickScanToolTip7 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>The suggested scanning sequence is determined automatically. Items are sorted alphabetically by the Primary bin location. If no primary location is saved the SKU will be used to sort the items. If you wish to Override this sorting sequence and force some items to display before or after other items this setting can be used. ie. If the item should be picked or scanned before all others, or after all others you can adjust this value. By default, all items have a value of 50. Items with override values closer to 1 will be sorted before those with values closer to 99 regardless of the SKU or Location saved. The override value can also be set in bulk using a CSV file.</Text>
                      <View style={{ position: "absolute", right: 1, bottom: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderTopWidth: 10, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: 50, height: 30, borderRadius: 5, backgroundColor: "#dbdada", justifyContent: 'center', alignItems: 'center', marginTop: 7 }}>
                      <Text>{this.state.scanSeqcount}</Text>
                    </View>
                    <View style={{ flexDirection: 'column', marginTop: 5, marginLeft: 5, marginRight: 5 }}>
                      <TouchableOpacity style={{ marginBottom: -5 }} onPress={() => this.countIncDec("Inc")}>
                        <Image style={{ width: 15, height: 20 }} source={arrowUp} />
                      </TouchableOpacity>
                      <TouchableOpacity style={{ marginTop: -5 }} onPress={() => this.countIncDec("Dec")}>
                        <Image style={{ width: 15, height: 20 }} source={arrowDown} />
                      </TouchableOpacity>
                    </View>
                    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <Text style={{ color: '#fff', fontWeight: "bold", fontSize: 15 }}>Scanning Sequence Override</Text>
                    </View>
                    <View>
                      <TouchableOpacity onPress={() => this.toolTipFun("7")}>
                        <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30, bottom: -7 }} />
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: 15 }}>
                  {
                    this.state.quickScanToolTip8 &&
                    <LinearGradient
                      locations={[0, 1]}
                      colors={["#8a9daf", "#d7e1e9"]}
                      style={{ padding: 10, marginLeft: this.state.windowWidth < 330 ? "0%" : this.state.windowWidth < 430 ? "10%" : this.state.windowWidth < 550 ? "26%" : "40%", position: "relative", marginTop: 12, borderRadius: 5, width: 299, alignSelf: "flex-start", zIndex: 999 }}>
                      <Text style={{ fontSize: 20, color: "#030303" }}>Custom product data can be entered here or imported using the product CSV importer. Each field can optionally be displayed when the item is suggested for scanning. The is very useful when variant information like size or color is not easily readable in the SKU or product name but is required by the picker or packer.</Text>
                      <View style={{ position: "absolute", right: 4, bottom: -14, borderLeftWidth: 14, borderLeftColor: "transparent", borderRightWidth: 14, borderRightColor: "transparent", borderTopWidth: 14, borderTopColor: "#8d9fb1" }} />
                    </LinearGradient>
                  }
                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexDirection: 'column', width: '70%' }}>
                      <Text style={{ color: '#fff' }}>Custom Product 1</Text>
                      <TextInput
                        // multiline={true}
                        // numberOfLines={4}
                        style={{
                          // margin: 10,
                          // borderWidth: 1,
                          borderRadius: 10,
                          padding: 10,
                          backgroundColor: "#dbdada",
                        }}
                        value={basicinfo.custom_product_1}
                        onChangeText={(text, name) => this.props.productInfoHandleChange(text, "custom_product_1")}
                      />
                    </View>
                    <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                      <Text style={{ color: '#fff' }}>Display</Text>
                      {/* <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={basicinfo.custom_product_display_1 ? "#f5dd4b" : "#f4f3f4"}
                        backgroundColor="#3e3e3e"
                        style={{ borderWidth: 1, borderRadius: 20, marginTop: 5 }}
                        onValueChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_1")}
                        value={basicinfo.custom_product_display_1}
                      /> */}
                      <View style={{ marginTop: 7 }}>
                        <Switch
                          checked={basicinfo.custom_product_display_1}
                          onChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_1")}
                          offColor="#5c6e79"
                          onColor="#65baea"
                          offHandleColor="#969696"
                          onHandleColor="#fbfdfe"
                          handleDiameter={22}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        />
                      </View>
                    </View>
                    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                      <TouchableOpacity style={{ bottom: -8 }} onPress={() => this.toolTipFun("8")}>
                        <Image source={require('././../../../assets/info.png')} style={{ height: 30, width: 30 }} />
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: 15 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexDirection: 'column', width: '70%' }}>
                      <Text style={{ color: '#fff' }}>Custom Product 2</Text>
                      <TextInput
                        // multiline={true}
                        // numberOfLines={4}
                        style={{
                          // margin: 10,
                          // borderWidth: 1,
                          borderRadius: 10,
                          padding: 10,
                          backgroundColor: "#dbdada",
                        }}
                        value={basicinfo.custom_product_2}
                        onChangeText={(text, name) => this.props.productInfoHandleChange(text, "custom_product_2")}
                      />
                    </View>
                    <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                      <Text style={{ color: '#fff' }}>Display</Text>
                      {/* <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={basicinfo.custom_product_display_2 ? "#f5dd4b" : "#f4f3f4"}
                        backgroundColor="#3e3e3e"
                        style={{ borderWidth: 1, borderRadius: 20, marginTop: 5 }}
                        onValueChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_2")}
                        value={basicinfo.custom_product_display_2}
                      /> */}
                      <View style={{ marginTop: 7 }}>
                        <Switch
                          checked={basicinfo.custom_product_display_2}
                          onChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_2")}
                          offColor="#5c6e79"
                          onColor="#65baea"
                          offHandleColor="#969696"
                          onHandleColor="#fbfdfe"
                          handleDiameter={22}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: 15 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexDirection: 'column', width: '70%', }}>
                      <Text style={{ color: '#fff' }}>Custom Product 3</Text>
                      <TextInput
                        // multiline={true}
                        // numberOfLines={4}
                        style={{
                          // margin: 10,
                          // borderWidth: 1,

                          borderRadius: 10,
                          padding: 10,
                          backgroundColor: "#dbdada",
                        }}
                        value={basicinfo.custom_product_3}
                        onChangeText={(text, name) => this.props.productInfoHandleChange(text, "custom_product_3")}
                      />
                    </View>
                    <View style={{ flexDirection: 'column', marginLeft: 10 }}>
                      <Text style={{ color: '#fff' }}>Display</Text>
                      {/* <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={basicinfo.custom_product_display_3 ? "#f5dd4b" : "#f4f3f4"}
                        backgroundColor="#3e3e3e"
                        style={{ borderWidth: 1, borderRadius: 20, marginTop: 5 }}
                        onValueChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_3")}
                        value={basicinfo.custom_product_display_3}
                      /> */}
                      <View style={{ marginTop: 7 }}>
                        <Switch
                          checked={basicinfo.custom_product_display_3}
                          onChange={(value, name) => this.props.productInfoHandleChange(value, "custom_product_display_3")}
                          offColor="#5c6e79"
                          onColor="#65baea"
                          offHandleColor="#969696"
                          onHandleColor="#fbfdfe"
                          handleDiameter={22}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={40}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </>
        }

        {/* for save and close button */}
      </View>


    );

  }
}
