import React, { useEffect, useRef, useState } from "react";
import Main from "./src/components/main";
import { Provider } from "react-redux";
import configureStore from "./src/store/configureStore";
import { Text } from "react-native";
import {
  useFonts,
  Poppins_100Thin,
  Poppins_200ExtraLight,
  Poppins_300Light,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
  Poppins_800ExtraBold,
  Poppins_900Black,
} from "@expo-google-fonts/poppins";
import io from "socket.io-client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Fingerprint from "fingerprintjs";

const store = configureStore();

// const socket = io.connect("ttp://wss://0x8bxj5tpa.execute-api.us-east-1.amazonaws.com/production" , { transports: ['websocket'], jsonp: false })

export default function App() {
  let [fontsLoaded] = useFonts({
    Poppins_100Thin,
    Poppins_200ExtraLight,
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    Poppins_800ExtraBold,
    Poppins_900Black,
  });

  // useEffect(async () => {
  //   const access_token = await AsyncStorage.getItem("access_token");

  //   // console.log(access_token);
  //   const socket = io("https://split1socket.groovepacker.com/socket.io/", {
  //     query:
  //       "fingerprint=" +
  //       new Fingerprint({
  //         canvas: true,
  //         screen_resolution: true,
  //         transports: ["websocket", "polling"],
  //       }).get() +
  //       "&token=" +
  //       access_token,
  //   });
  //   // const socket = io("http://localhost:7000")
  //   console.log("socket", socket)

  //   socket.on("connect", () => {
  //     console.log("socket connected");
  //     // socket.emit('YOUR EVENT TO SERVER', {})
  //     // socket.on('EVENT YOU WANNA LISTEN', (r) => {
  //     // })
  //   });

  //   socket.on("connect_error", (err) => {
  //     console.log("this error: ",err);
  //   });

  //   socket.on("disconnect", () => {
  //     console.log("Disconnected Socket!");
  //   });
  // }, []);

  if (!fontsLoaded) {
    return <Text>Loading!</Text>;
  } else {
    return (
      <Provider store={store}>
        
        <Main />
      </Provider>
    );
  }
}
