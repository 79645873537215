import React, { Component } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  Platform,
  Alert,
  Dimensions,
  BackHandler,
  TextInput,
  Image,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { connect } from "react-redux";
import { GetItem, SetItem } from "../../actions/updateAsyncAction";

import {
  GetOrderDetail,
  UpdateOrderQuantity,
  RemoveOrderItems,
  UpdateOrderInfo,
} from "../../actions/orderActions";
import { OrderBackButtonClick } from "../../actions/headerActions";
import ItemsDetail from "./items_detail";
import NotesDetail from "./notes_detail";
import { SubmitLog } from "../../actions/saveLogAction";
import ActivitiesExceptionDetail from "./activities_exception_detail";
import MultiBoxDetail from "./multi_box_detail";
import InformationDetail from "./information_detail";
import globalStyles from "../../style/global";
import styles from "../../style/orderdetail";
import { LinearGradient } from "expo-linear-gradient";
import { GetBothSettings } from "../../actions/userAction";

import toggle from "../../../assets/toggle1.png";
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from "react-native-responsive-screen";

let saveData = false;
let productID = 0;
let removeOrder = true;
let updateOrder = true;
let fetchData = true;
let updateOrderSuccess = true;
const windowHeight = Dimensions.get("window").height;

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        filter: "awaiting",
        order: "DESC",
        limit: "1",
        offset: "0",
      },
      dataList: {
        filter: "awaiting",
        order: "DESC",
        limit: "1",
        offset: "0",
        app: "app",
      },
      visible: false,
      itemActive: true,
      notesActive: false,
      informationActive: false,
      activitiesActive: false,
      multiBoxActive: false,
      items: [],
      removeIds: [],
      order: [],
      windowWidth: 0,
    };
    window.addEventListener("resize", this.update);
  }

  componentDidMount() {
    // let productID = this.props && this.props.route.params.item && this.props.route.params.item.id
    // this.props.GetItem("storeOrder")
    // debugger
    this.props.GetBothSettings()
    let id =
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.item &&
      this.props.route.params.item.id;

    this.setState({ id: id });
    if (id) {
      this.apiCall(id);
      this.multipleTime();
    }

    // this.props.navigation.goBack(null)
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.backAction
    );
    this.update();
  }
  update = () => {
    // console.log("avc", this.state.windowWidth)
    this.setState({
      windowWidth: window.innerWidth,
    });
  };
  backAction = () => {
    Alert.alert("Hold on!", "Are you sure you want to go back?", [
      {
        text: "Cancel",
        onPress: () => null,
        style: "cancel",
      },
      { text: "YES", onPress: () => this.updateBasicInfo() },
    ]);
    return true;
  };

  multipleTime = async () => {
    let getItem = await AsyncStorage.getItem("logsArray");
    let convert = getItem && JSON.parse(getItem);
    this.props.SubmitLog(convert);
  };

  componentDidUpdate() {
    
    if (this.props.orderDetail !== this.state.order) {
      this.setState({ order: this.props.orderDetail });
    }
    if (removeOrder && this.props.removeOrderItems) {
      this.apiCall(this.state.id);
      removeOrder = false;
    }
    if (
      updateOrder &&
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.from === "UpdateOrder"
    ) {
      let id = this.props.route.params.data;
      this.apiCall(id);
      updateOrder = false;
    }
    if (
      updateOrderSuccess &&
      this.props.updateOrderSuccess &&
      this.props.updateOrderSuccess.scan_pack_data &&
      this.props.updateOrderSuccess.scan_pack_data[0] &&
      this.props.updateOrderSuccess.scan_pack_data[0].scan_hash.data
    ) {
      // this.updateOrderList(this.props.updateOrderSuccess.scan_pack_data[0])
      this.setState({ visible: false });
      this.props.navigation.navigate("ScanPackItem", {
        data: {
          from: "orderDetailPage",
          order:
            this.props.updateOrderSuccess.scan_pack_data[0].scan_hash.data
              .order,
        },
      });
      updateOrderSuccess = false;
    }
    // if(this.state.items !== this.props.items){
    //   this.setState({items: this.props.items})
    // }
  }

  // updateOrderList = async(updateOrder) => {
  //   let localOrderList = await AsyncStorage.getItem("storeOrder")
  //   let searchOrderNumber = updateOrder.id
  //   let orderFound = false
  //   if(localOrderList){
  //     let unString = localOrderList && JSON.parse(localOrderList)
  //     if(unString.orders && unString.orders.length > 0){
  //       unString.orders && unString.orders.map((orders ) => {
  //         if(orders.id === searchOrderNumber){
  //           orders = updateOrder
  //           orderFound = true
  //         }
  //       })
  //     }
  //     if(orderFound === false){
  //       let updatedList = unString.orders && unString.orders.push(updateOrder)
  //     }
  //     let finalList = JSON.stringify(unString)
  //     this.props.SetItem( "storeOrder" , finalList)
  //   }
  // }

  apiCall = (id) => {
    // debugger
    // let id = this.props && this.props.route.params.item && this.props.route.params.item.id
    let detail = {
      id: id,
      orderDetail: this.state.data,
    };
    if (id !== null) {
      this.props.GetOrderDetail(detail);
    }
  };

  redirect(item) {
    this.props.navigation.navigate("ProductDetail", {
      data: item,
      token: this.state.token,
      url: this.state.url,
    });
  }

  changeState = (state) => {
    if (state === "itemActive") {
      this.setState({
        itemActive: true,
        notesActive: false,
        informationActive: false,
        activitiesActive: false,
        multiBoxActive: false,
      });
    }
    if (state === "notesActive") {
      this.setState({
        itemActive: false,
        notesActive: true,
        informationActive: false,
        activitiesActive: false,
        multiBoxActive: false,
      });
    }
    if (state === "informationActive") {
      this.setState({
        itemActive: false,
        notesActive: false,
        informationActive: true,
        activitiesActive: false,
        multiBoxActive: false,
      });
    }
    if (state === "activitiesActive") {
      this.setState({
        itemActive: false,
        notesActive: false,
        informationActive: false,
        activitiesActive: true,
        multiBoxActive: false,
      });
    }
    if (state === "multiBoxActive") {
      this.setState({
        itemActive: false,
        notesActive: false,
        informationActive: false,
        activitiesActive: false,
        multiBoxActive: true,
      });
    }
  };

  updateQuantity = async (item) => {
    if (item) {
      let data = {
        orderitem: item.iteminfo.id,
        qty: item.iteminfo.qty && parseInt(item.iteminfo.qty),
      };
      await this.props.UpdateOrderQuantity(data);
    }
  };

  addOrderItem(items) {
    // console.log("..........", items)
    // this.props.navigation.navigate("Select Product to add to Order", items);
    this.changeState.bind(this, "itemActive");
    updateOrder = true;
  }

  removeOrderItem(ids) {
    // console.log("id remover", ids);
    let removeIds = this.state.removeIds;
    var check = removeIds.includes(ids.iteminfo.id);
    if (check) {
      const index = removeIds.findIndex(
        (removeIds) => removeIds === ids.iteminfo.id
      );
      removeIds.splice(index, 1);
    } else {
      removeIds.push(ids.iteminfo.id);
    }
    this.setState({ removeIds });
  }

  handleChange(text, item) {
    let qtyValue = item.iteminfo;
    qtyValue["qty"] = text && JSON.parse(text);
    this.setState({ qtyValue });
  }

  removeItems = async () => {
    let removeIds = this.state.removeIds;
    let data = {
      orderitem: removeIds,
    };
    if (this.state.removeIds && this.state.removeIds.length > 0) {
      await this.props.RemoveOrderItems(data);
      removeOrder = true;
    }
  };

  handleBasicInfo(name, value) {
    let tempInfo = this.state.order && this.state.order.basicinfo;
    tempInfo[name] = value;
    this.setState({ tempInfo });
  }

  updateBasicInfo = async () => {
    this.setState({ visible: true });
    let basicInfo = this.state.order && this.state.order.basicinfo;
    if (basicInfo) {
      basicInfo["app"] = "app";
      let id = basicInfo.id;
      let data = basicInfo;
      await this.props.UpdateOrderInfo(id, data);
      updateOrderSuccess = true;
      // this.props.navigation.navigate("ScanPack" , {data: {from: "orderDetailPage" , id: this.state.order}})
    }
  };

  // handleBackButtonClick(){
  //   this.props.navigation.goBack(null);
  //   return true;
  //   this.updateBasicInfo()
  // }

  componentWillUnmount() {
    this.backHandler.remove();
  }

  render() {
    let order = this.props && this.state.order;
    return (
      <React.Fragment>
        {this.state.visible ? (
          <View style={{ flex: 1, justifyContent: "center" }}>
            <ActivityIndicator size="large" />
          </View>
        ) : (
          <LinearGradient
            colors={[
              "#000",
              "#000",
              "#5c778f",
              "#5c778f",
              "#253c57",
              "#253c57",
              "#000",
            ]}
            start={[-0.5, -1]}
            style={{
              flex: 1,
              // overflow: "scroll",
              // paddingHorizontal: 15,
              // width: windowWidth,
              // height: windowHeight,
              paddingBottom: 15,
            }}
          >
            {/* <View style={{ height: "6%", borderWidth: 1 }}>
              <LinearGradient
                colors={["#2E4452", "#14222F"]}
                style={styles.headerGradient}
              >
                <TouchableOpacity
                  onPress={() => {}}
                  style={styles.drawerBtn}
                >
                  <Image
                    style={styles.drawerImg}
                    source={toggle ? toggle : ""}
                  />
                </TouchableOpacity>
                {console.log()}
              </LinearGradient>
            </View> */}
            {/* <View style={{width: windowWidth, borderWidth: 1, borderColor: 'black'}}></View> */}
            <View style={{ height: "100%", width: "100%" }}>
              {order && (
                <View style={{ width: "100%" }}>
                  <ScrollView
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    style={{
                      width: "100%",
                      borderWidth: 1,
                    }}
                  >
                    <LinearGradient
                      colors={["#2E3D46", "#587284"]}
                      style={{
                        width: this.state.windowWidth,
                        flexDirection: "row",
                        paddingTop: 5,
                        paddingLeft: "2%",
                        minWidth: 710,
                      }}
                    >
                      <TouchableOpacity
                        onPress={this.changeState.bind(this, "itemActive")}
                        style={[
                          {
                            backgroundColor: this.state.itemActive
                              ? "#000"
                              : "",
                            // top: this.state.itemActive ? 5 : "",
                            // borderWidth: this.state.itemActive ? 1 : "",
                            // borderColor: 'white'
                          },
                          styles.tabBtn,
                        ]}
                      >
                        <Text
                          style={[
                            styles.tabLabel,
                            // { color: this.state.itemActive ? "white" : "black" },
                          ]}
                        >
                          Order Items
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={this.changeState.bind(this, "notesActive")}
                        // style={[
                        //   globalStyles.flex2,
                        //   globalStyles.justifyCenter,
                        //   {
                        //     backgroundColor: this.state.notesActive ? "#000" : "",
                        //   },
                        // ]}
                        style={[
                          {
                            backgroundColor: this.state.notesActive
                              ? "#000"
                              : "",
                            // top: this.state.notesActive ? 5 : "",
                          },
                          styles.tabBtn,
                        ]}
                      >
                        <Text style={[styles.tabLabel]}>Notes</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={this.changeState.bind(
                          this,
                          "informationActive"
                        )}
                        style={[
                          {
                            backgroundColor: this.state.informationActive
                              ? "#000"
                              : "",
                            // top: this.state.informationActive ? 5 : "",
                          },
                          styles.tabBtn,
                        ]}
                      >
                        <Text
                          style={[
                            styles.tabLabel,
                            // {
                            //   color: this.state.informationActive
                            //     ? "black"
                            //     : "white",
                            // },
                          ]}
                        >
                          Order Info
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={this.changeState.bind(
                          this,
                          "activitiesActive"
                        )}
                        style={[
                          {
                            backgroundColor: this.state.activitiesActive
                              ? "#000"
                              : "",
                            // top: this.state.activitiesActive ? 5 : "",
                          },
                          styles.tabBtn,
                        ]}
                      >
                        <Text style={[styles.tabLabel]}>Activity</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={this.changeState.bind(this, "multiBoxActive")}
                        style={[
                          {
                            backgroundColor: this.state.multiBoxActive
                              ? "#000"
                              : "",
                            // top: this.state.multiBoxActive ? 5 : "",
                          },
                          styles.tabBtn,
                        ]}
                      >
                        <Text style={[styles.tabLabel]}>Multi-Box Info</Text>
                      </TouchableOpacity>
                    </LinearGradient>
                  </ScrollView>
                  {this.state.itemActive && (
                    <ItemsDetail
                      items={order}
                      token={this.state.token}
                      url={this.state.url}
                      redirect={this.redirect.bind(this)}
                      navigation={this.props.navigation}
                      updateQuantity={(e) => this.updateQuantity(e)}
                      addOrderItem={(e) => this.addOrderItem(e)}
                      handleChange={(e, item) => this.handleChange(e, item)}
                      removeOrderItem={(ids) => this.removeOrderItem(ids)}
                      removeIds={this.state.removeIds}
                      removeItems={() => this.removeItems()}
                      updateBasicInfo={() => this.updateBasicInfo()}
                      back={() => this.props.navigation.goBack()}
                    />
                  )}
                  {this.state.notesActive && (
                    <NotesDetail
                      basicinfo={order.basicinfo}
                      navigation={this.props.navigation}
                      handleChange={(Name, Value) =>
                        this.handleBasicInfo(Name, Value)
                      }
                      updateBasicInfo={() => this.updateBasicInfo()}
                      back={() => this.props.navigation.goBack()}
                      bothSettings={this.props.bothSettings}

                    />
                  )}
                  {this.state.informationActive && (
                    <InformationDetail
                      basicinfo={order.basicinfo}
                      navigation={this.props.navigation}
                      handleChange={(Name, Value) =>
                        this.handleBasicInfo(Name, Value)
                      }
                      updateBasicInfo={() => this.updateBasicInfo()}
                      back={() => this.props.navigation.goBack()}
                      bothSettings={this.props.bothSettings}
                    />
                  )}
                  {this.state.activitiesActive && (
                    <ActivitiesExceptionDetail
                      activities={order.activities}
                      exception={order.exception}
                      navigation={this.props.navigation}
                    />
                  )}
                  {this.state.multiBoxActive && (
                    <MultiBoxDetail navigation={this.props.navigation} />
                  )}
                </View>
              )}
            </View>
          </LinearGradient>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // debugger
  return {
    orderDetail: state.order.order,
    saveData: state.updateAsync.retriveData,
    removeOrderItems: state.order.removeOrderItems,
    updateOrderSuccess: state.order.updateOrder,
    bothSettings: state.user.bothSettings,
  };
};

const mapDispatchToProps = {
  GetOrderDetail,
  GetItem,
  UpdateOrderQuantity,
  RemoveOrderItems,
  UpdateOrderInfo,
  SubmitLog,
  SetItem,
  OrderBackButtonClick,
  GetBothSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
