import React, { Component } from "react";
import { View, Text, TextInput, TouchableOpacity, ScrollView, Image, Picker, TouchableHighlight } from "react-native";
import { connect } from "react-redux";
import globalStyles from "../../style/global";
import styles from "../../style/productdetail";
import AddProduct from "./addproduct";
import ProductInfo from "./product_info";
//import DropDownPicker from 'react-native-dropdown-picker';
// import DropDownPicker from "react-native-custom-dropdown";
import { GETAllKITProduct, RemoveKITProduct, updateProductList } from "../../actions/productAction";
// import Tooltip from 'react-native-walkthrough-tooltip';
import { LinearGradient } from "expo-linear-gradient";

import { fontFamily } from "../../helpers/fontFamily";
// import Draggable from "react-native-draggable";

let updatecall = false;

class InventoryKitOptions extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      kits: [],
      itemadd: false,
      selectedcat: "",
      toolTipVisible: false,
      quickScanToolTip3: false,
      removeIds: [],
      kits: [],
      kitselected: [],
      ChangeKitToProduct: true,
      quickScanToolTip3: false,
      addflag: true,
      editable: Number,
      pickerSelectedOptions: [
        {
          label: "Pre-Packed Kits Are Scanned as One item",
          value: "single",
        },
        {
          label: "Kit Part items are each scanned individually",
          value: "individual",
        },
        {
          label: " Kits are usually Pre-packed",
          value: "depends",
        },
      ],
      quickScanToolTip: false,
    };
    window.addEventListener("resize", this.update);
  }
  componentDidMount() {
    // console.log("componentmount", this.props);
    this.update();
    this.setState({ kits: [this?.props?.product?.productkitskus] });

    let productID = this.props?.product?.basicinfo.id;
    this.props.GETAllKITProduct(productID);
  }
  ToolTipset = () => {
    this.setState({
      quickScanToolTip: !this.state.quickScanToolTip,
    });
  };
  componentDidUpdate() {
    if (updatecall == true) {
      // console.log("product id here hhhh update call=========>", this.props?.product?.productkitskus)
      updatecall = false;
      let productID = this.props?.product?.basicinfo.id;
      this.props.GETAllKITProduct(productID);
    }
  }
  AddItemToInventory = () => {
    this.setState({ itemadd: false });
    let productID = this.props?.product?.basicinfo.id;
    this.props.GETAllKITProduct(productID);
  };

  kitinfo = () => {
    this.setState({ kits: this.props.productskit });
  };
  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  update = () => {
    // console.log("avc")
    this.setState({
      windowWidth: window.innerWidth,
    });
  };
  async onValueChangeCat(value) {
    this.setState({ selectedcat: value });
  }

  removeKitUpdate = () => {
    let productID = this.props?.product?.basicinfo.id;
    this.props.GETAllKITProduct(productID);
  };
  removeKitItem(id) {
    let prodId = this.props?.product?.basicinfo?.id;
    let aa = [];
    aa.push(id);
    let data = {
      kit_products: aa,
    };
    // let removeIds = this.state.removeIds;
    // var check = removeIds.includes(index);
    // if (check) {
    //   const index = removeIds.findIndex(
    //     (removeIds) => removeIds === index
    //   );
    //   removeIds.splice(index, 1);
    // } else {
    //   removeIds.push();
    // }
    // this.setState({ removeIds });

    this.props.RemoveKITProduct(prodId, data);
    // this.setState({})
    updatecall = true;
    this.removeKitUpdate();
  }

  handleChange(text, item) {
    // console.log("name & value", item, text);
    let qtyValue = item;
    // console.log("order qty", qtyValue);
    qtyValue["qty"] = text && JSON.parse(text);
    this.setState({ qtyValue });
    let Product = this.props.product;
    // console.log("12313313", Product.productkitskus);
    Product.productkitskus.map((sku, index) => {
      if (item.option_product_id === sku.option_product_id) {
        Product.productkitskus.splice(index, 1, qtyValue);
      }
    });
    this.props.inventoryWarehousesHandleChange(Product.productkitskus, "productkitskus");
  }

  removeItems = async () => {
    let removeIds = this.state.removeIds;
    let data = {
      orderitem: removeIds,
    };
    if (this.state.removeIds && this.state.removeIds.length > 0) {
      await this.props.RemoveKITProduct(data);
      removeOrder = true;
    }
  };

  changekittoproduct = () => {
    let productID = this.props.product?.basicinfo?.id;
    let kitselected1 = [];
    this.props.productskit.productskit.map((item, index) => {
      kitselected1.push(item.option_product_id);
    });
    let data = {
      kit_products: kitselected1,
    };
    this.props.RemoveKITProduct(productID, data);
    // let productID = this.props?.product?.basicinfo.id;
    setTimeout(() => {
      this.props.GETAllKITProduct(productID);
      this.props.changeState("productInfoActive");
    }, 1000);
  };
  updateQuantity = (item) => {
    if (item) {
      let data = {
        productskit: item.option_product_id,
        qty: item.qty && parseInt(item.qty),
      };

      this.props.updateProductList(data);
    }
  };

  render() {
    let productskit = this.props.productskit.productskit;
    return (
      <View
        style={{
          // overflow: "scroll",
          paddingHorizontal: "1%",
          backgroundColor: "rgba(104, 135, 156, 0.4)",
          borderRadius: 10,
          marginHorizontal: "1%",
          marginTop: 20,
          marginBottom: 10,
          // borderWidth: 1,
          // borderColor: "#50708b",
          shadowColor: "#50708b",
          shadowOffset: { width: 1, height: 1 },
          shadowRadius: 10,
        }}
      >
        {!this.state.itemadd ? (
          <>
            {this.state.windowWidth >= 900 ? (
              <>
                <View
                  style={{
                    alignSelf: "flex-center",
                    blocksize: "inherit",
                    width: "100%",
                    flexDirection: "row",
                    // borderWidth: 1
                  }}
                >
                  {productskit?.length >= 1 ? (
                    <View style={{ alignItems: "center", justifyContent: "center", flexDirection: "row", paddingRight: 696 }}>
                      <Text style={{ color: "white", paddingLeft: 35 }}> Kit Scanning Options</Text>
                    </View>
                  ) : (
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        paddingRight: 696,
                        paddingBottom: 30,
                      }}
                    >
                      <Text style={{ color: "white", paddingLeft: 35 }}> Kit Scanning Options</Text>
                    </View>
                  )}
                  {productskit?.length >= 1 ? (
                    <View>
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ itemadd: true });
                        }}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingRight: 30,
                          paddingVertical: 20,
                          marginLeft: 230,
                        }}
                      >
                        <Image
                          style={{ width: 40, height: 40, resizeMode: "contain" }}
                          source={require("../../../assets/plus.png")}
                        />
                        <Text
                          style={{
                            fontFamily: "Poppins_600SemiBold",
                            // fontWeight: "bold",
                            color: "white",
                            fontSize: 16,
                            textShadowColor: "#000",
                            textShadowOffset: { width: 1, height: 1 },
                            textShadowRadius: 5,

                            elevation: 5,
                          }}
                        >
                          Add Items
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View style={{ marginLeft: 45, marginTop: -27 }}>
                  <View style={{ flexDirection: "row" }}>
                    <Picker
                      mode="dropdown"
                      // selectedValue={basicinfo.type_scan_enabled}
                      style={{
                        height: 30,
                        width: 375,
                        background: "transparent",
                        borderTopWidth: 0,
                        borderbottomcolor: "rgba(255, 255, 255)",
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        color: "#fff",
                        fontSize: 18,
                        borderColor: "white",
                      }}
                      onValueChange={(itemValue, itemIndex) => this.props.productInfoHandleChange(itemValue, "kit_parsing")}
                    >
                      {this.state.pickerSelectedOptions.map((option, index) => {
                        return <Picker.Item label={option.label} value={option.value} color="#000" />;
                      })}
                    </Picker>
                    <TouchableOpacity
                      onPress={() =>
                        this.setState({
                          quickScanToolTip3: !this.state.quickScanToolTip3,
                        })
                      }
                    >
                      <Image source={require("././../../../assets/info.png")} style={{ height: 28, width: 25 }} />
                    </TouchableOpacity>
                  </View>
                </View>
                {this.state.quickScanToolTip3 && (
                  <LinearGradient
                    locations={[0, 1]}
                    colors={["#8a9daf", "#d7e1e9"]}
                    style={{
                      padding: 10,
                      position: "absolute",
                      marginTop: 10,
                      borderRadius: 5,
                      width: 300,
                      zIndex: 99,
                      marginTop: 82,
                      marginLeft: 176,
                    }}
                  >
                    <Text style={{ fontSize: 20, color: "#030303" }}>
                      Pre-packed kits are scanned as one item - One barcode is scanned which represents the all kit items. This
                      permits inventory tracking for the kit parts.Kit parts items are each scanned individually - The barcodes of
                      the individual items in the kit are always scanned.Kits are usually Pre-packed - A single kit barcdoe is
                      scanned unless prepacked kits are unavailable and kit part are barcodes will need to be scanned.
                    </Text>
                    <View
                      style={{
                        position: "absolute",
                        right: "13%",
                        top: -10,
                        borderLeftWidth: 10,
                        borderLeftColor: "transparent",
                        borderRightWidth: 10,
                        borderRightColor: "transparent",
                        borderBottomWidth: 10,
                        borderBottomColor: "#8d9fb1",
                      }}
                    />
                  </LinearGradient>
                )}
                <View
                  style={{
                    marginTop: 0,
                    flexDirection: "row",
                    alignContent: "flex-end",
                    alignSelf: "flex-end",
                    marginTop: -26,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.changekittoproduct();
                      this.setState({ ChangeKitToProduct: false });
                    }}
                    style={{
                      flexDirection: "row",
                      // paddingRight: 40,
                      // paddingVertical: 20,
                      // borderWidth: 1
                    }}
                  >
                    <Image
                      style={{
                        width: 35,
                        height: 28,
                        marginTop: 10,
                        marginRight: -7,
                        resizeMode: "contain",
                        marginBottom: 0,
                      }}
                      source={require("../../../assets/cross.png")}
                    />
                    <Text
                      style={{
                        fontFamily: "Poppins_600SemiBold",
                      
                        fontSize: 16,
                        color: "white",
                        marginLeft: 5,
                        marginTop: 14,
                        //  textShadowColor: "#000",
                        textShadowOffset: { width: 1, height: 1 },
                        textShadowRadius: 5,

                        elevation: 5,
                      }}
                    >
                      Change this kit back to regular product
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    // overflow: "scroll",
                    flex: 1,
                    // marginHorizontal: 10,
                    backgroundColor: "rgba(15, 39, 58, 0.4)",
                    borderRadius: 15,
                    marginBottom: 50,
                    // opacity: 0.5
                    // width: "100%",
                    height: 100,
                    borderRadius: 2,
                    boxSixing: "inherit",
                  }}
                >
                  <View style={[styles.itemContainer]}>
                    <View
                      style={[
                        styles.itemView,
                        {
                          width: "2%",
                          marginHorizontal: "1%",
                          paddingVertical: 15,
                        },
                      ]}
                    ></View>

                    <View
                      style={{
                        flexDirection: "row",
                        // borderBottomWidth: 0.7,
                        // borderColor: "rgba(250, 250, 250, .4)",
                        paddingLeft: "1%",
                        paddingRight: 0,
                        width: "96%",
                        justifyContent: "space-around",
                        // backgroundColor: 'aqua',
                        flexWrap: "wrap",
                      }}
                    >
                      <View
                        style={[
                          styles.itemView,
                          {
                            width: "10%",
                          },
                        ]}
                      ></View>
                      <View style={[styles.gridHeader, { width: "18%" }]}>
                        <Text style={{ fontFamily:"Poppins_600SemiBold", fontSize: 19, color: "white", marginRight: -12 }}>Product Name</Text>
                      </View>

                      <View style={[styles.gridHeader, { width: "15%" }]}>
                        <Text style={{ fontFamily:"Poppins_600SemiBold", fontSize: 19, color: "white", marginLeft: -59 }}>SKU</Text>
                      </View>

                      <View style={[styles.gridHeader, { width: "8%" }]}>
                        <Text style={{ fontFamily:"Poppins_600SemiBold", color: "white", fontSize: 19, marginLeft: -22 }}>Status</Text>
                      </View>

                      <View
                        style={[
                          styles.gridHeader,
                          {
                            width: "10%",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                        ]}
                      >
                        <Text style={{ fontFamily:"Poppins_600SemiBold", fontSize: 19, color: "white", marginRight: -14 }}>QTY/KIT</Text>
                      </View>

                      <View
                        style={[
                          styles.gridHeader,
                          {
                            width: "15%",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                        ]}
                      >
                        <Text style={{fontFamily:"Poppins_600SemiBold", fontSize: 19, color: "white" }}>Location l</Text>
                      </View>
                      <View
                        style={[
                          styles.gridHeader,
                          {
                            width: "4%",
                            justifyContent: "center",
                            alignItems: "center",
                          },
                        ]}
                      >
                        <Text style={{fontFamily:"Poppins_600SemiBold", fontSize: 19, color: "white" }}>QOH</Text>
                      </View>
                    </View>
                  </View>
                  <View>
                    {productskit &&
                      productskit.map((item, index) => {
                        return (
                          <View style={[styles.itemContainer]}>
                            <View
                              style={{
                                flexDirection: "row",
                                borderBottomWidth: 0.7,
                                borderColor: "rgba(250, 250, 250, .4)",
                                paddingHorizontal: "1%",
                                width: "97%",
                                justifyContent: "space-around",
                                // backgroundColor: 'aqua',
                                flexWrap: "wrap",
                                marginTop: 24,
                              }}
                            >
                              <View
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TouchableOpacity
                                  onPress={() => {
                                    this.removeKitItem(item.option_product_id);
                                  }}
                                  style={[
                                    styles.itemView,
                                    {
                                      width: 35,
                                      height: 35,
                                      marginRight: "-2%",
                                      marginLeft: "0%",
                                    },
                                  ]}
                                >
                                  <Image
                                    style={{
                                      width: 35,
                                      height: 35,
                                      marginLeft: -20,
                                      resizeMode: "stretch",
                                    }}
                                    source={require("../../../assets/cross.png")}
                                  />
                                </TouchableOpacity>
                              </View>
                              <View
                                style={[
                                  styles.itemView,
                                  {
                                    width: "8%",
                                    marginVertical: 5,
                                  },
                                ]}
                              >
                                <Image
                                  style={{
                                    width: 70,
                                    height: 70,
                                    borderRadius: 5,
                                    resizeMode: "contain",
                                  }}
                                  source={{ uri: item?.images[0]?.image }}
                                />
                              </View>
                              <View style={[styles.itemView, { width: "11%" }]}>
                                <Text style={[styles.itemName, { color: "#aad9fc", fontSize: 19 }]}>{item.name}</Text>
                              </View>
                              <View style={[styles.itemView, { width: "13%", marginRight: 17 }]}>
                                <Text style={(styles.itemName, { marginLeft: 65, color: "white", fontSize: 19 })}>
                                  {item.sku}
                                </Text>
                              </View>
                              <View style={[styles.itemView, { width: "10%" }]}>
                                {item.product_status === "active" ? (
                                  <Text style={styles.statusActive}>{this.Capitalize(item.product_status)}</Text>
                                ) : (
                                  // <Text>{this.Capitalize(this.state.title)} </Text>
                                  <Text style={styles.statusNew}>{this.Capitalize(item.product_status)}</Text>
                                )}
                              </View>
                              {/* {item.barcode.length >=13 ? ( */}
                              <View style={[styles.itemView, { width: "15%" }]}>
                                <Text ellipsizeMode="tail" numberOfLines={1} style={[styles.itemName]}>
                                  {/* {item.barcode} */}
                                </Text>
                              </View>

                              <View
                                style={[
                                  styles.itemView,
                                  {
                                    width: "10  %",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft: -153,
                                    marginTop: -60,
                                  },
                                ]}
                              >
                                {this.state.editable == index ? (
                                  <TextInput
                                    // placeholder={item.iteminfo.qty}
                                    placeholderTextColor="white"
                                    // onChangeText={() => {}}
                                    keyboardType="numeric"
                                    autoFocus={true}
                                    placeholder={item.qty}
                                    //onChangeText={(text) => this.handleChange(text, "qty")}
                                    onChangeText={(text) => this.handleChange(text, item)}
                                    onBlur={() => {
                                      {
                                        item.qty > 0
                                          ? (this.updateQuantity(item), this.setState({ editable: -1 }))
                                          : (this.props.removeIds.includes(item.option_product_id),
                                            this.props.removeOrderItem(item),
                                            this.props.removeItems(),
                                            this.setState({ editable: -1 }));
                                        // this.state.items.splice(index, 1)
                                      }
                                    }}
                                    style={{
                                      width: 25,
                                      height: 25,
                                      fontSize: 19,
                                      color: "white",
                                    }}
                                  />
                                ) : (
                                  <Text style={(styles.itemName, { color: "white", fontSize: 19 })}>{item.qty}</Text>
                                )}
                                <TouchableOpacity
                                  onPress={() => {
                                    this.setState({ editable: index });
                                    // this.changeEdit();
                                  }}
                                >
                                  <Image
                                    style={{
                                      width: 20,
                                      height: 20,
                                      resizeMode: "contain",
                                    }}
                                    source={require("../../../assets/edit.png")}
                                  />
                                </TouchableOpacity>
                              </View>

                              <View
                                style={[
                                  styles.itemView,
                                  {
                                    width: "16%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  },
                                ]}
                              >
                                <Text style={styles.itemName}>{item.primary_location}</Text>
                              </View>
                              <View
                                style={[
                                  styles.itemView,
                                  {
                                    width: "4%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: -60,
                                  },
                                ]}
                              >
                                <Text style={(styles.itemName, { color: "white", fontSize: 19 })}>{item.qty_on_hand}</Text>
                              </View>
                              {/* {this.state.instruction ? (
                  <View
                    style={[
                      styles.itemView,
                      {
                        width: 130,
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <Text style={styles.itemName}>
                      {item.available_inv}
                    </Text>
                  </View>
                ) : (
                  <View></View>
                )} */}
                            </View>
                          </View>
                        );
                      })}
                  </View>
                </View>
              </>
            ) : (
              <View
                style={{
                  // overflow: "scroll",
                  flex: 1,
                  marginHorizontal: 10,
                  backgroundColor: "rgba(15, 39, 58, 0.4)",
                  // backgroundColor: "teal",
                  borderRadius: 15,
                  marginBottom: 50,
                  // paddingLeft: 5,
                  // opacity: 0.5
                  // width: "100%",
                  // height: 100,
                  paddingVertical: 15,
                  paddingLeft: 10,
                  flexWrap: "wrap",
                }}
              >
                <View>
                  {!this.state.itemadd ? (
                    <View style={{}}>
                      <View
                        style={{
                          marginTop: 0,
                          flexDirection: "row",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            // this.props.addOrderItem(this.state.items);
                            // this.setState({ itemadd: true });
                            this.changekittoproduct();
                          }}
                          style={{
                            flexDirection: "row",
                            paddingRight: 40,
                            // paddingVertical: 20,
                            paddingBottom: 20,
                            // borderWidth: 1
                          }}
                        >
                          <Image
                            style={{ width: 40, height: 40, resizeMode: "contain" }}
                            source={require("../../../assets/back.png")}
                          />
                          <Text
                            style={{
                              fontFamily: "Poppins_600SemiBold",
                              fontWeight: "bold",
                              fontSize: 16,
                              color: "white",
                              marginLeft: 5,
                              marginTop: 14,
                              //  textShadowColor: "#000",
                              textShadowOffset: { width: 1, height: 1 },
                              textShadowRadius: 5,

                              elevation: 5,
                            }}
                          >
                            Change this kit back to product
                          </Text>
                        </TouchableOpacity>
                      </View>

                      {/* <View style={{
                    margin: 120,
                    marginLeft: 0,
                    marginTop: 32,
                    flexDirection: "row",
                    //paddingHorizontal: "-14%",
                    paddingRight: 122,
                    width: "100%",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    alignItems: "flex-start"

                  }}>


                    <View style={[
                      styles.itemView,
                      {
                        width: "35%",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 30
                      },
                    ]}>
                      <Text style={[styles.gridHeader, { fontWeight: "bold", fontSize: 16, color: 'white' }]} >QTY in Kit</Text>
                    </View>

                    <View style={[
                      styles.itemView,
                      {
                        width: "40%",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,


                      },
                    ]}>
                      <Text style={[styles.gridHeader, { fontWeight: "bold", fontSize: 16, color: 'white' }]}>Kit Part SKU</Text>
                    </View>
                  </View> */}
                      <View
                        style={{
                          margin: 120,
                          marginTop: 39,
                          marginLeft: 0,
                          flexDirection: "row",
                          paddingHorizontal: "1%",
                          width: "96%",
                          justifyContent: "space-around",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <View *
                                   style={{
                                     flexDirection: "row",
                                     // borderBottomWidth: 0.7,
                                     // borderColor: "rgba(250, 250, 250, .4)",
                                     // paddingHorizontal: "1%",
                                     // width: "96%",
                                     // justifyContent: "space-around",
                                     // flexWrap: "wrap",
                                   }}
                                 > */}
                        {/* <View
                                     style={[
                                       styles.itemView,
                                       {
                                         width: "10%",
                                       },
                                     ]}
                                   ></View> */}

                        <View
                          style={[
                            styles.itemView,
                            {
                              width: "auto",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: 30,
                            },
                          ]}
                        >
                          <Text
                            style={[
                              styles.gridHeader,
                              {
                                fontFamily: "Poppins_600SemiBold",
                                fontSize: 16,
                                color: "white",
                                paddingRight: 26,
                              },
                            ]}
                          >
                            QTY in Kit
                          </Text>
                        </View>

                        <View
                          style={[
                            styles.itemView,
                            {
                              width: "auto",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,
                            },
                          ]}
                        >
                          <Text style={[styles.gridHeader, { fontFamily: "Poppins_600SemiBold", fontSize: 16, color: "white" }]}>
                            Kit Part SKU
                          </Text>
                        </View>
                        {this.state.quickScanToolTip && (
                          <LinearGradient
                            locations={[0, 1]}
                            colors={["#8a9daf", "#d7e1e9"]}
                            style={{
                              padding: 10,
                              position: "absolute",
                              right: -10,
                              // marginRight: 50,
                              marginBottom: 115,
                              alignSelf: "flex-end",
                              borderRadius: 5,
                              width: 314,
                              flexWrap: "wrap",
                              zIndex: "999",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: fontFamily.font400,
                                fontSize: 14,
                                color: "#030303",
                              }}
                            >
                              Add items to the kit and control the quantity of each item here. Kits need to have at least one item
                              and each of the items in the kit will need to have a barcode assigned in order for the kit to be
                              active.
                            </Text>
                            <View
                              style={{
                                position: "absolute",
                                right: 0,
                                bottom: -10,
                                borderLeftWidth: 10,
                                borderLeftColor: "transparent",
                                borderRightWidth: 10,
                                borderRightColor: "transparent",
                                borderTopWidth: 10,
                                borderTopColor: "#8d9fb1",
                              }}
                            />
                          </LinearGradient>
                        )}
                        <View style={{ position: "absolute", right: -20, top: 40 }}>
                          {productskit && productskit.length > 0 && (
                            <TouchableOpacity onPress={() => this.ToolTipset()}>
                              <Image style={{ width: 25, height: 25 }} source={require("../../../assets/info.png")} />
                            </TouchableOpacity>
                          )}
                        </View>
                        {productskit &&
                          productskit.map((item, index) => {
                            // console.log("££££££££££££££££££££££££££", item.productskit)
                            return (
                              <View
                                style={{
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: 18,
                                  backgroundColor: "rgba(104, 135, 156, 0.7)",
                                }}
                              >
                                <View
                                  style={{
                                    marginLeft: this.state.windowWidth < 600 ? -40 : -180,
                                  }}
                                >
                                  <TouchableOpacity
                                    onPress={() => {
                                      // this.removeIds.includes(

                                      // );
                                      this.removeKitItem(item.option_product_id);
                                      // this.removeItems();
                                    }}
                                    style={[
                                      styles.itemView,
                                      {
                                        width: "5%",
                                        marginRight: this.state.windowWidth < 600 ? 20 : 50,
                                      },
                                    ]}
                                  >
                                    <Image
                                      style={{
                                        width: 30,
                                        height: 30,
                                        resizeMode: "contain",
                                        marginLeft: 0,
                                      }}
                                      source={require("../../../assets/close-arrow.png")}
                                    />
                                  </TouchableOpacity>
                                </View>

                                <View
                                  style={[
                                    styles.itemView,
                                    {
                                      width: 70,
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      paddingLeft: this.state.windowWidth < 600 ? 0 : 110,
                                    },
                                  ]}
                                >
                                  <Text style={[styles.itemName2, { fontSize: 16, color: "white" }]}>{item.qty}</Text>
                                </View>
                                <View
                                  style={[
                                    styles.itemView,
                                    {
                                      width: 110,
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      paddingLeft: this.state.windowWidth < 600 ? 100 : 160,
                                    },
                                  ]}
                                >
                                  <Text style={[styles.itemName2, { fontSize: 16, color: "white" }]}>{item.sku}</Text>
                                </View>
                              </View>
                            );
                          })}
                      </View>

                      <View
                        style={{
                          marginTop: 0,
                          alignSelf: "center",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            //this.props.addOrderItem(this.state.items);
                            this.setState({ itemadd: true });
                          }}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            paddingRight: 30,
                            paddingVertical: 20,
                            // borderWidth: 1
                          }}
                        >
                          <Image
                            style={{ width: 60, height: 60, resizeMode: "contain" }}
                            source={require("../../../assets/plus-white-thickness.png")}
                          />
                          <Text
                            style={{
                              fontFamily: "Poppins_600SemiBold",
                              // fontWeight: "bold",
                              color: "white",
                              fontSize: 16,
                              textShadowColor: "#000",
                              // textShadowOffset: { width: 1, height: 1 },
                              textShadowRadius: 5,

                              elevation: 5,
                            }}
                          >
                            Add Items
                          </Text>
                        </TouchableOpacity>
                      </View>

                      <View style={{ flexDirection: "row" }}>
                        <Text style={{ marginLeft: 35, color: "white" }}> Kit Scanning Options</Text>
                        <TouchableOpacity
                          onPress={() =>
                            this.setState({
                              quickScanToolTip3: !this.state.quickScanToolTip3,
                            })
                          }
                        >
                          <Image
                            source={require("././../../../assets/info.png")}
                            style={{ height: 28, width: 25, marginTop: -4 }}
                          />
                        </TouchableOpacity>
                      </View>
                      {this.state.quickScanToolTip3 && (
                        <LinearGradient
                          locations={[0, 1]}
                          colors={["#8a9daf", "#d7e1e9"]}
                          style={{
                            padding: 10,
                            position: "relative",
                            marginTop: 10,
                            borderRadius: 5,
                          }}
                        >
                          <Text style={{ fontSize: 10, color: "#030303" }}>
                            Pre-packed kits are scanned as one item - One barcode is scanned which represents the all kit items.
                            This permits inventory tracking for the kit parts.Kit parts items are each scanned individually - The
                            barcodes of the individual items in the kit are always scanned.Kits are usually Pre-packed - A single
                            kit barcdoe is scanned unless prepacked kits are unavailable and kit part are barcodes will need to be
                            scanned.
                          </Text>
                          <View
                            style={{
                              position: "absolute",
                              right: "34%",
                              top: -10,
                              borderLeftWidth: 10,
                              borderLeftColor: "transparent",
                              borderRightWidth: 10,
                              borderRightColor: "transparent",
                              borderBottomWidth: 10,
                              borderBottomColor: "#8d9fb1",
                            }}
                          />
                        </LinearGradient>
                      )}
                      {/* <View style={{ flexDirection: 'row' }}>
                    <Text style={{ marginLeft: 45, color: 'white' }}>Kit part items are each scanned individually</Text>
                    <TouchableOpacity
                      onPress={() => this.toolTipFun("3")}>
                      <Image source={require('././../../../assets/arrow-down-product.png')} style={{ height: 28, width: 25, marginTop: -4 }} />

                    </TouchableOpacity>
                    <View style={{ borderBottomColor: 'white', borderBottomWidth: 1, }} />
                  </View> */}
                      <View style={{ marginLeft: 45 }}>
                        <Picker
                          mode="dropdown"
                          // selectedValue={basicinfo.type_scan_enabled}
                          style={{
                            height: 30,
                            width: 287,
                            background: "transparent",
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            color: "#fff",
                            fontSize: 14,
                            borderColor: "rgba(132, 163, 165, -0.3)",
                          }}
                          onValueChange={(itemValue, itemIndex) => this.props.productInfoHandleChange(itemValue, "kit_parsing")}
                        >
                          {this.state.pickerSelectedOptions.map((option, index) => {
                            return <Picker.Item label={option.label} value={option.value} color="#000" />;
                          })}
                        </Picker>
                      </View>
                      <View></View>
                    </View>
                  ) : (
                    <View>
                      <AddProduct
                        onchange={() => this.setState({ itemadd: !this.state.itemadd })}
                        AddItemToInventory={() => this.AddItemToInventory()}
                        hideSaveFun={() => this.props.hideSaveFun()}
                        {...this.props}
                      />
                    </View>
                  )}
                </View>
              </View>
            )}
            {productskit <= 1 && (
              <View
                style={{
                  marginTop: 0,
                  alignSelf: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    //this.props.addOrderItem(this.state.items);
                    this.setState({ itemadd: true });
                  }}
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: 30,
                    paddingVertical: 20,
                    // borderWidth: 1
                  }}
                >
                  <Image
                    style={{ width: 60, height: 60, resizeMode: "contain" }}
                    source={require("../../../assets/plus-white-thickness.png")}
                  />
                  <Text
                    style={{
                      fontFamily: "Poppins_600SemiBold",
                      // fontWeight: "bold",
                      color: "white",
                      fontSize: 16,
                      textShadowColor: "#000",
                      // textShadowOffset: { width: 1, height: 1 },
                      textShadowRadius: 5,

                      elevation: 5,
                    }}
                  >
                    Add Items
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </>
        ) : (
          <>
            <View>
              <AddProduct
                onchange={() => this.setState({ itemadd: !this.state.itemadd })}
                AddItemToInventory={() => this.AddItemToInventory()}
                hideSaveFun={() => this.props.hideSaveFun()}
                {...this.props}
              />
            </View>
          </>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allProduct: state.product.allProduct,
    productskit: state.product,
  };
};

const mapDispatchToProps = {
  GETAllKITProduct,
  RemoveKITProduct,
  updateProductList,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryKitOptions);
