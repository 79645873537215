import { IMPORT_ORDER_SUCCESS, IMPORT_ORDER_FAIL, IMPORT_ORDER_STATUS_SUCCESS, IMPORT_ORDER_STATUS_FAIL } from '../constants'
const initialState = {};

const importorderReducer = (state = initialState, action) => {
    switch (action.type) {
        case IMPORT_ORDER_SUCCESS:
            return { ...state, importdata: action.payload, time: new Date() };
        case IMPORT_ORDER_FAIL:
            return { ...state, importdataError: action.payload };
        case IMPORT_ORDER_STATUS_SUCCESS:
            return {...state, importstatusData: action.payload};
        case IMPORT_ORDER_STATUS_FAIL:
            return {...state, importstatusError: action.payload}
        default:
            return state;
    }
}

export default importorderReducer;