import axios from 'axios';
import { SUBMIT_LOG, SCANPACK_BUG_REPORT } from '../constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
let throttling = false
const delayApi = async (dispatch) => {
  // const access_token = await AsyncStorage.getItem("access_token")
  // const url = await AsyncStorage.getItem("url")

  // let getItem = await AsyncStorage.getItem("logsArray")
  // let data = getItem && JSON.parse(getItem)
  // console.log("-->>>>>>>", data)


  if (!throttling) {
    throttling = true
    dataCall(dispatch)
    apiInterval = true
    // console.log("hiiiii")
    // clearTimeout(pausedTime)
    // console.log("clear time out ",clearTimeout(pausedTime))
    // --------
    // axios({
    //   method: 'post',
    //   url: `${url}/scan_pack/scan_pack_v2`,
    //   headers: { 'Authorization': `Bearer ${access_token}` },
    //   data: { data, app: "app" }
    // })
    //   .then(async (res) => {
    //     console.log("submit+++++ log", res)
    //     dispatch({
    //       type: SUBMIT_LOG,
    //       payload: res.data,
    //       time: new Date()
    //     })
    //     if (res.status === 200) {
    //       console.log("response savlog status", res.status)
    //       await AsyncStorage.removeItem("logsArray")
    //       data = []
    //       // delay = 7000
    //       // throttling = false
    //       // let getItem = await AsyncStorage.getItem("logsArray")
    //       // console.log("rrrrrrrr---->>",getItem)
    //       setTimeout(() => {
    //         console.log("throttling out", throttling)
    //         throttling = false
    //       }, 5000)

    //     }
    //   })
    //   .catch((error) => {
    //   })
    // ------

  }
  // else {
  //   console.log("byeeee")
  //   clearInterval(delay)
  // }
  // }
  // , 10000)

}

// let pausedTime;
// let delay = 8000
let apiInterval = false

let count = 0
let ss
const dataCall = async (dispatch) => {
  const ss = setInterval(async () => {
    // console.log("count------>>>>>>>>!",count)
    if (count <= 2 && count >= 0) {
      count = count + 1
    }
    else {

      const access_token = await AsyncStorage.getItem("access_token")
      const url = await AsyncStorage.getItem("url")
      let getItem = await AsyncStorage.getItem("logsArray")
      let data = getItem && JSON.parse(getItem)
      // console.log("set interval out", ss)
      await AsyncStorage.removeItem("logsArray")
      clearInterval(ss)
      throttling = false
      count = 0
      if (data?.length > 0) {
        axios({
          method: 'post',
          url: `${url}/scan_pack/scan_pack_v2`,
          headers: { 'Authorization': `Bearer ${access_token}` },
          data: { data, app: "app" }
        })
          .then(async (res) => {
            dispatch({
              type: SUBMIT_LOG,
              payload: res.data,
              time: new Date()
            })
            if (res.status === 200) {
              data = []
              // console.log("current time interval",new Date(),res)

              // apiInterval = false

              // clearInterval(ss)
              count = 0
            }
          })
          .catch((error) => {
          })
      }
    }
  }, 1000)
}
export function SubmitLog(submitLog) {

  // console.log("---submit log ", submitLog)
  return async (dispatch) => {
    // const access_token = await AsyncStorage.getItem("access_token")
    // const url = await AsyncStorage.getItem("url")
    // let getItem = await AsyncStorage.getItem("logsArray")
    // // console.log("--",getItem)
    // let data = getItem && JSON.parse(getItem)
    // await AsyncStorage.getItem('logsArray').then((log) => {
    // if (data !== null) {

    // }
    // })
    // console.log("array -------->", data)
    // if(!throttling){
    //   clearTimeout(pausedTime)
    // }
    // let pausedTime = setTimeout(async () => {
    //   const access_token = await AsyncStorage.getItem("access_token")
    //   const url = await AsyncStorage.getItem("url")
    //   let getItem = await AsyncStorage.getItem("logsArray")
    //   // console.log("--",getItem)
    //   let data = getItem && JSON.parse(getItem)
    //   // return async(dispatch) => {
    //     // const access_token = await AsyncStorage.getItem("access_token")
    //     // const url = await AsyncStorage.getItem("url")
    //     // await AsyncStorage.getItem('logsArray').then((log) => {
    //     if (data?.length > 0) {
    //     console.log("delay function call after 2000 ms")
    //     axios({
    //       method: 'post',
    //       url: `${url}/scan_pack/scan_pack_v2`,
    //       headers: { 'Authorization': `Bearer ${access_token}` },
    //       data: { data, app: "app" }
    //     })
    //       .then(async (res) => {
    //         dispatch({
    //           type: SUBMIT_LOG,
    //           payload: res.data,
    //           time: new Date()
    //         })
    //         if (res.status === 200) {
    //           await AsyncStorage.removeItem("logsArray")
    //           data = []
    //         }
    //       })
    //       .catch((error) => {
    //       })
    //   }
    // }, delay)
    // const access_token = await AsyncStorage.getItem("access_token")
    // const url = await AsyncStorage.getItem("url")
    // let getItem = await AsyncStorage.getItem("logsArray")
    // let data = getItem && JSON.parse(getItem)
    // if(apiInterval){
    //   console.log("rohit-------->>>")
    // let setval = setTimeout(()=>{

    // console.log("count of interval here ",count)
    // if(count == 4){
    //   apiInterval = false
    //   clearInterval(setval)
    // }
    //     const ss = setInterval(async()=>{
    //       console.log("count------>>>>>>>>!",count)
    //       if(count <= 2 && count >= 0){
    //         count = count + 1
    //       }
    //       else{
    //         console.log("set interval out",ss)
    //         clearInterval(ss)
    //         count = 0
    //         if(data?.length > 0){
    //           axios({
    //             method: 'post',
    //             url: `${url}/scan_pack/scan_pack_v2`,
    //             headers: { 'Authorization': `Bearer ${access_token}` },
    //             data: { data, app: "app" }
    //           })
    //             .then(async (res) => {
    //               dispatch({
    //                 type: SUBMIT_LOG,
    //                 payload: res.data,
    //                 time: new Date()
    //               })
    //               if (res.status === 200) {
    //                 console.log("current time interval",new Date())
    //                 await AsyncStorage.removeItem("logsArray")
    //                 data = []
    //                 // apiInterval = false

    //                 // clearInterval(ss)
    //                 // count = 0
    //               }
    //             })
    //             .catch((error) => {
    //             })
    //         }
    //       }
    // },1000)
    // },4000)
    // }
    if (submitLog === "submitLog") {
      // console.log(":-))")
      const access_token = await AsyncStorage.getItem("access_token")
      const url = await AsyncStorage.getItem("url")
      let getItem = await AsyncStorage.getItem("logsArray")
      // console.log("--",getItem)
      let data = getItem && JSON.parse(getItem)
      // return async(dispatch) => {
      // const access_token = await AsyncStorage.getItem("access_token")
      // const url = await AsyncStorage.getItem("url")
      // await AsyncStorage.getItem('logsArray').then((log) => {
      if (data?.length > 0) { 
        axios({
          method: 'post',
          url: `${url}/scan_pack/scan_pack_v2`,
          headers: { 'Authorization': `Bearer ${access_token}` },
          data: { data, app: "app" }
        })
          .then(async (res) => {
            dispatch({
              type: SUBMIT_LOG,
              payload: res.data,
              time: new Date()
            })
            if (res.status === 200) {
              await AsyncStorage.removeItem("logsArray")
              data = []
            }
          })
          .catch((error) => {
          })
      }
      // })
      // }
    } else {
      delayApi(dispatch)
    }
  }
}

export function ScanPackBugReport(data) {
  return async (dispatch) => {
    const access_token = await AsyncStorage.getItem("access_token")
    const url = await AsyncStorage.getItem("url")
    axios({
      method: 'post',
      url: `${url}/scan_pack/scan_pack_bug_report.json`,
      headers: { 'Authorization': `Bearer ${access_token}` },
      data: data
    })
      .then(async (res) => {
        dispatch({
          type: SCANPACK_BUG_REPORT,
          payload: {
            data: res.data,
            time: new Date()
          }
        })
        if (res.status === 200) {
          await AsyncStorage.removeItem("Logging")
        }
      })
      .catch((error) => {
      })
  }
}