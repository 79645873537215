//Asyncstorage
export const SET_ITEM_SUCCESS = "SET_ITEM_SUCCESS";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";
export const GET_ITEM_FAIL = "GET_ITEM_FAIL";

//user
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GENERAL_SETTINGS = "GENERAL_SETTINGS";
export const SCANPACK_SETTINGS = "SCANPACK_SETTINGS";
export const GET_BOTH_SETTINGS = "GET_BOTH_SETTINGS";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_SUCCESS_FAIL = "USER_INFO_SUCCESS_FAIL";

//url
export const UPDATE_URL = "UPDATE_URL";
export const GET_URL = "GET_URL";

//scanpack
export const CLICK_SCAN = "CLICK_SCAN";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const SEARCH_SCANPACK_ORDER = "SEARCH_SCANPACK_ORDER";
export const SUBMIT_LOG = "SUBMIT_LOG";
export const SCANPACK_BUG_REPORT = "SCANPACK_BUG_REPORT";
export const SEARCH_SCANPACK_ORDER_FAIL = "SEARCH_SCANPACK_ORDER_FAIL";

//product
export const PRODUCT_DETAIL = "PRODUCT_DETAIL";
export const PRODUCT_DETAIL_FAIL = "PRODUCT_DETAIL_FAIL";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_SEARCH_PRODUCT = "GET_SEARCH_PRODUCT";
export const UPDATE_PRODUCT_INFO = "UPDATE_PRODUCT_INFO";
export const UPDATE_PRODUCT_ALIAS = "UPDATE_PRODUCT_ALIAS";
export const UPDATE_PRODUCT_INFO_FAIL = "UPDATE_PRODUCT_INFO_FAIL";
export const UPDATE_PRODUCT_LIST = "UPDATE_PRODUCT_LIST";
export const UPDATE_PRODUCT_LIST_FAIL = "UPDATE_PRODUCT_LIST_FAIL";

//headers
export const ORDER_BACK_BUTTON = "ORDER_BACK_BUTTON";

//order
export const ORDER_LIST = "ORDER_LIST";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";
export const ORDER_DETAIL = 'ORDER_DETAIL';
export const SEARCH_ORDER = "SEARCH_ORDER";
export const UPDATE_ORDER_QUANTITY = "UPDATE_ORDER_QUANTITY";
export const ADD_ORDER_ITEMS = "ADD_ORDER_ITEMS";
export const REMOVE_ORDER_ITEMS = "REMOVE_ORDER_ITEMS";
export const UPDATE_ORDER_INFO = "UPDATE_ORDER_INFO";


//import order
export const IMPORT_ORDER_FAIL = "IMPORT_ORDER_FAIL";
export const IMPORT_ORDER_SUCCESS = "IMPORT_ORDER_SUCCESS";
export const IMPORT_ORDER_STATUS_SUCCESS = "IMPORT_ORDER_STATUS_SUCCESS";
export const IMPORT_ORDER_STATUS_FAIL = "IMPORT_ORDER_STATUS_FAIL";

export const ALL_KIT_PRODUCT = "ALL_PRODUCT";
export const GET_KIT_PRODUCT = "GET_KIT_PRODUCT";
export const REMOVE_KIT_PRODUCT = "REMOVE_KIT_PRODUCT";

export const ADD_IMAGE = "ADD_IMAGE";