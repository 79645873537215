import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, ScrollView, Platform, Image, ImageBackground, Dimensions } from 'react-native';
import { connect } from 'react-redux';
import { UpdateUrl } from '../../actions/updateURL';
import { Login, UserInfo } from "../../actions/userAction";
import NetInfo from "@react-native-community/netinfo";
import styles from '../../style/signin';
import { LinearGradient } from 'expo-linear-gradient';
import { fontFamily } from '../../helpers/fontFamily';
import AsyncStorage from '@react-native-async-storage/async-storage'
import Constants from "expo-constants";
import PopUpModel from '../scanpack/pop_up_model';
import {signEnterNameMsg, signSavedMsg} from "../notification";

let loginSuccess = false;
let loginFail = false;
let userInfoCheck = false;

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: Dimensions.get("window").width,
      data: {
        "grant_type": "password",
        "username": "",
        "password": ""
      },
      tenent: "",
      url: "",
      tenentSuffix: ".groovepacker.com",
      error_show: false,
      errorMessage: "",
      hidePassword: true,
      toggle: false,
      modalVisible: true,
      errorMessageShow: false,
      isUsernameSaved: false,
      accountToolTip: false,
      usernameToolTip: false,
      tenentFocused: false,
      usernameFocused: false,
      passwordFocused: false,
      ModalShow:false,
      showMessage : ''
    };

    this.onSubmit = this.onSubmit.bind(this)
    this.onUsernameSaved = this.onUsernameSaved.bind(this)

    this.tenent = React.createRef();
    this.username = React.createRef();
    this.password = React.createRef();
  }

  toggleModal(visible) {
    this.setState({ modalVisible: visible });
  }
  //////////////////For Validation 
  validation = () => {
    let data = this.state.data
    let tenent = this.state.tenent
    if (data.username === "" || data.password === "" || tenent === "") {
      if (tenent === "") {
        this.setState({ tenent_validate: true })
      }
      if (data.username === "") {
        this.setState({ username_validate: true })
      }
      if (data.password === "") {
        this.setState({ password_validate: true })
      }
    }
    else {
      return true;
    }
  }
  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth
    })
  }
  keyDownPressed = (event) => {
    if (event.key === 'Tab' || event.code === 'Tab') {
      event.preventDefault()
      if (this.state.tenentFocused || this.state.usernameFocused || this.state.passwordFocused) {
        if (this.state.tenentFocused) {
          this.username.current.focus()
        }
        else if (this.state.usernameFocused) {
          this.password.current.focus()
        }
        else if (this.state.passwordFocused) {
          this.tenent.current.focus()
        }
      }
    }
  }
  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions)
    document.addEventListener('keydown', this.keyDownPressed)
    this.tenent.current.focus()
    const tenent = await AsyncStorage.getItem('tenent')
    if (tenent) {
      this.setState({ tenent })
    }
    const username = await AsyncStorage.getItem('username')
    if (username) {
      this.setState({
        data: {
          ...this.state.data,
          username
        },
        tenent,
        isUsernameSaved: true
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  componentDidUpdate() {
    if (this.state.clickTime < this.props.login.time) {
      if (loginSuccess === false && this.props.status === 200 && this.props.login.login === true) {
        loginSuccess = true
        this.props.UserInfo()
        userInfoCheck = true
      }
      if (loginFail === false && this.props.status === "" && this.props.login.login === false) {
        loginFail = true
        this.setState({ errorMessageShow: true, errorMessage: "Unable to log in. Please check your username and password" })
      }
    }

    if (userInfoCheck === true && this.props && this.props.userInfo && this.props.userInfo !== this.state.userInfo) {
      userInfoCheck = false
      if (this.props.userInfo.is_active === false) {
        this.setState({ errorMessageShow: true, errorMessage: "The username is no longer active. Please contact your SuperAdmin." })
      } else {
        this.props.navigation.navigate("ScanPack")
      }
      this.props.UserInfo(false)
    }
  }

  ////////// Check the internet connection 
  InternetCheck = async (data) => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      this.props.Login(data)
      this.setState({ clickTime: new Date() })
      loginSuccess = false
      loginFail = false
    } else {
      this.setState({ errorMessageShow: true, errorMessage: "Please check your connection" })
    }
  }
  //////////////////// Login function 
  onSubmit = () => {
    if (this.validation() === true) {
      this.setState({
        data: {
          ...this.state.data,
          username: this.state.data.username.trim(),
          password: this.state.data.password.trim(),
        },
        tenent: this.state.tenent.trim()
      }, () => {
        if (Platform.OS === "web") {
          AsyncStorage.setItem('tenent', this.state.tenent)
          this.props.Login(this.state.data)
          this.setState({ clickTime: new Date() })
          loginSuccess = false
          loginFail = false
        } else {
          this.InternetCheck(this.state.data)
        }
      })
    }
  }
  onUsernameSaved = () => {
    if (this.state.data.username) {
      if (this.state.isUsernameSaved){
        AsyncStorage.removeItem('username').then(() => {
        })
        this.setState({ isUsernameSaved: false })
      }
      else{
        AsyncStorage.setItem(
          'username', this.state.data.username).then(() => {
          });
          this.setState({showMessage : signSavedMsg})
          this.setState({ModalShow : true})
          this.setState({ isUsernameSaved: true })
        }
    } else {
      this.setState({showMessage : signEnterNameMsg})
      this.setState({ModalShow : true})
    }

  };

  onChange = (name, value) => {
    let data = this.state.data;
    let tenent = this.state.tenent;
    let suffix = this.state.tenentSuffix
    if (name === "username") {
      data["username"] = value
      this.setState({ username_validate: false, isUsernameSaved: false })
    }
    if (name === "password") {
      data["password"] = value
      this.setState({ password_validate: false })
    }
    if (name === "tenent") {
      this.setState({ tenent: value, tenent_validate: false })
      tenent = value;
    }
    let urlDomain = suffix === ".groovepacker.com" ? ".groovepackerapi.com" : suffix
    let url = "https://" + tenent.trim() + urlDomain
    // "https://ed16-2409-4053-96-bba6-764c-e598-98a7-b2b3.ngrok.io"
    this.props.UpdateUrl(url)
    this.setState({ data, errorMessageShow: false })
  }

  changeTenant = () => {
    let toggle = this.state.toggle;
    let tenentSuffix;
    this.setState({ toggle: !this.state.toggle });
    if (toggle === true) {
      tenentSuffix = ".groovepacker.com";
    } else {
      tenentSuffix = ".dreadheadsports.com";
    }
    this.setState({ tenentSuffix });
    let urlDomain = tenentSuffix === ".groovepacker.com" ? ".groovepackerapi.com" : tenentSuffix
    let url = "https://" + this.state.tenent + urlDomain;
    // "https://ed16-2409-4053-96-bba6-764c-e598-98a7-b2b3.ngrok.io"
    this.props.UpdateUrl(url);
  }

  closeAlert = () => {
    this.setState({
      errorMessageShow: false
    })
  }

  render() {
    return (
      <ImageBackground style={{ flex: 1 }} source={require('../../../assets/signinpagebackground.png')}>
        <ScrollView contentContainerStyle={{ flex: 1, alignItems: "center" }} >
          <View style={{ height: "100%", width: this.state.windowWidth >= 900 ? "40%" : "80%" }}>
            {
              this.state.errorMessageShow &&
              <PopUpModel
                closeAlert={this.closeAlert.bind(this)}
                message={this.state.errorMessage}
                messageTypeSuccess={false} />
            }
            {this.state.ModalShow ?
          (<View style={[styles.alertContainer]}>
            <LinearGradient
              locations={[0,1]}
              colors={["#ffcc","#ffcc"]}
              style={{ flexDirection: "row", justifyContent: "space-between", backgroundColor: "#b7b7b7", padding: 5, borderRadius: 10, width: "80%", marginLeft: "auto", marginRight: "auto", padding: 10, shadowColor: "#000", shadowOffset: { width: 0, height: 0 }, shadowOpacity: 10, shadowRadius: 10 }}>
              <Text style={{ marginLeft: 5, marginRight: 5,fontWeight: "bold", fontSize: 16, color: "#1a73e8" }}>
                {this.state.showMessage}
              </Text>
              <TouchableOpacity style={{height:25,width:35,borderRadius:1,backgroundColor:'#1a73e8',padding:5,borderRadius:10}}onPress={()=>{this.setState({ModalShow : false })}}>
                {/* <Image source={closeButton ? closeButton : ""}
                  style={{ height: 20, width: 20 }} /> */}
                  <Text style={{color:'#fff',fontSize:14,fontWeight:'bold'}}>OK</Text>
              </TouchableOpacity>
            </LinearGradient>
          </View>)
          :
          <View></View>
        }
            <View style={{ alignItems: "center", marginTop: 30 }}>
              <Image style={{ height: 120, width: 120 }} source={require('../.././../assets/groveicon.png')} />
            </View>
            <View style={{ marginTop: 20, alignItems: "center", marginLeft: 40 }}>
              <View>
                <Text style={[styles.labelText], { color: '#839bb0', fontSize: 14,fontFamily:'Poppins_600SemiBold' }}>Account Name</Text>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    value={this.state.tenent}
                    name="tenent"
                    placeholderTextColor="#cfe3f8"
                    ref={this.tenent}
                    autoFocus={true}
                    onChangeText={(text) => { this.onChange("tenent", text) }}
                    style={[styles.inputBox]}
                    autoCapitalize='none'
                    onSubmitEditing={() => { this.username.current.focus() }}
                    returnKeyType="next"
                    onFocus={() => this.setState({ tenentFocused: true })}
                    onBlur={() => this.setState({ tenentFocused: false })}
                  />
                  <TouchableOpacity
                    onPress={() => this.setState({
                      accountToolTip: !this.state.accountToolTip
                    })}
                    style={styles.imagestyle}>
                    <Image source={require('../../../assets/notificationicon.png')} style={{ height: 20, width: 20 }} />
                  </TouchableOpacity>
                </View>
                {
                  this.state.tenent_validate &&
                  <Text style={styles.inputError}>
                    Field are require to submit
                  </Text>
                }
              </View>
              {this.state.accountToolTip &&
                <LinearGradient
                  locations={[0, 1]}
                  colors={["#8a9daf", "#d7e1e9"]}
                  style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 335 }}>
                  <Text style={{ fontFamily: fontFamily.font400, fontSize: 14, color: "#030303" }}>Your account name is chosen when your GroovePacker account is created. Often it is the same as your web domain. (If your main web site is groovy.com your account name will likely be "groovy". If this does not work please reach out to your administrator.</Text>
                  <View style={{ position: "absolute", right: "8%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                </LinearGradient>
              }
            </View>
            <View style={{ marginTop: 20, alignItems: "center" }}>
              <View>
                <Text style={[styles.labelText], {color: '#839bb0', fontSize: 14, marginLeft: 35,fontFamily: "Poppins_600SemiBold"} }>Username</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <TouchableOpacity onPress={() => this.onUsernameSaved()} style={{ justifyContent: "center", alignItems: "center", marginRight: 15, marginTop: 10, width: 22 }}>
                    <Image source={require('../../../assets/saved1.png')} style={{ height: 28, width: 20 }} />
                    <Text style={{ color: '#b6c7da', fontSize: 10 }}>{this.state.isUsernameSaved ? 'SAVED' : 'SAVE'}</Text>
                  </TouchableOpacity>
                  <TextInput
                    value={this.state.data.username}
                    name="username"
                    placeholderTextColor="#cfe3f8"
                    ref={this.username}
                    onChangeText={(text) => { this.onChange("username", text) }}
                    style={styles.inputBox}
                    autoCapitalize='none'
                    onSubmitEditing={() => { this.password.current.focus() }}
                    returnKeyType="next"
                    onFocus={() => this.setState({ usernameFocused: true })}
                    onBlur={() => this.setState({ usernameFocused: false })}
                  />
                  <TouchableOpacity
                    onPress={() => this.setState({
                      usernameToolTip: !this.state.usernameToolTip
                    })}
                    style={styles.imagestyle}>
                    <Image source={require('../../../assets/notificationicon.png')} style={{ height: 20, width: 20, }} />
                  </TouchableOpacity>
                </View>
                {
                  this.state.username_validate &&
                  <Text style={[styles.inputError, { marginLeft: 35 }]}>
                    Field are require to submit
                  </Text>
                }
              </View>
              {this.state.usernameToolTip &&
                <LinearGradient
                  locations={[0, 1]}
                  colors={["#8a9daf", "#d7e1e9"]}
                  style={{ padding: 10, position: "relative", marginTop: 10, borderRadius: 5, width: 335 }}>
                  <Text style={{ fontFamily: fontFamily.font400, fontSize: 14, color: "#030303" }}>If you are regularly logging in on the same device you can save your username using the Save icon.</Text>
                  <View style={{ position: "absolute", right: "2%", top: -10, borderLeftWidth: 10, borderLeftColor: "transparent", borderRightWidth: 10, borderRightColor: "transparent", borderBottomWidth: 10, borderBottomColor: "#8d9fb1" }} />
                </LinearGradient>
              }
            </View>
            <View style={{ marginTop: 20, alignItems: "center", marginLeft: 40 }}>
              <View>
                <Text style={[styles.labelText], { color: '#839bb0', fontSize: 14,fontFamily:"Poppins_600SemiBold" }}>Password</Text>
                <View style={{ flexDirection: "row" }}>
                  <TextInput
                    name="Password"
                    ref={this.password}
                    secureTextEntry={this.state.hidePassword}
                    placeholderTextColor="#cfe3f8"
                    autoFocus={true}
                    onChangeText={(text) => { this.onChange("password", text) }}
                    style={[styles.inputBox]}
                    autoCapitalize='none'
                    onSubmitEditing={this.onSubmit}
                    returnKeyType="next"
                    onFocus={() => this.setState({ passwordFocused: true })}
                    onBlur={() => this.setState({ passwordFocused: false })}
                  />
                  <TouchableOpacity onPress={() => this.setState({
                    hidePassword: !this.state.hidePassword
                  })}
                    style={styles.imagestyle}>
                    <Image source={this.state.hidePassword ? require('../../../assets/Eyeshow.png') : require('../../../assets/eye.png')} style={{ height: 18, width: 25 }} />
                  </TouchableOpacity>
                </View>
                {
                  this.state.password_validate &&
                  <Text style={styles.inputError}>
                    Field are require to submit
                  </Text>
                }
              </View>
            </View>
            <View style={{ marginTop: 20, flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <TouchableOpacity onPress={() => this.setState({ modalVisible: true })} style={styles.imagestylerefresh}>
                <Image source={require('../../../assets/refresh.png')} style={{ height: 25, width: 25 }} />
              </TouchableOpacity>
              <Text style={[styles.fontSize, { color: '#839bb0', fontFamily: 'Poppins_600SemiBold', marginTop: 5, marginLeft: 5 }]} > Reset Your Password</Text>
            </View>
            <View style={{ marginTop: 30, alignItems: "center" }}>
              <LinearGradient
                colors={['#95abbf', '#516b83']}
                style={{ borderRadius: 5, width: "40%", justifyContent: "center", alignItems: "center" }}>
                <TouchableOpacity onPress={() => this.onSubmit()}>
                  <Text style={{ fontFamily: 'Poppins_600SemiBold', color: '#cfe3f8', fontSize: 20 }}>LOG IN</Text>
                </TouchableOpacity>
              </LinearGradient>
            </View>
            <View style={{ marginTop: 40, alignItems: "center" }}>
              <Text style={{ color: '#839bb0', fontSize: 22, fontFamily: 'Poppins_600SemiBold' }}>GroovePacker EX</Text>
              <Text style={{ color: '#839bb0', fontSize: 22, fontFamily:'Poppins_600SemiBold'}}>Expo  v {Constants.expoVersion}</Text>
              <Text style={{ color: '#839bb0', fontSize: 16, fontFamily: 'Poppins_600SemiBold' }}>GroovePacker.com</Text>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    updateUrl: state.updateUrl.url,
    status: state.user.status,
    login: state.user,
    userInfo: state.user.userInfo
  }
};
const mapDispatchToProps = {
  UpdateUrl,
  Login,
  UserInfo
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
