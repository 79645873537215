import { StyleSheet } from 'react-native';
import { fontFamily } from "../helpers/fontFamily";

const styles = StyleSheet.create({
    //inventory style start//
    inventoryContainer:{
      flex:1 ,
      margin: 10 ,
      padding: 10 ,
      backgroundColor: "#dbdada" ,
      borderWidth: 1 ,
      borderRadius: 30
    },
    inventoryHeading: {
      fontWeight: "bold" ,
      fontSize: 18 ,
      margin: 10
    },
    inventoryColumnView: {
      flexDirection: "row",
      alignItems:"center",
      // borderBottomWidth: 1 ,
      borderBottomColor: "#000"
    },
    inventoryColumnViewLabel: {
      flex: 2,
      fontSize: 16,
      fontWeight: "bold",
      backgroundColor: "rgb(51, 101, 153)",
      color:"#fff",
      width: "100%",
      textAlign:"center",
      padding: 10
    },
    inventoryColumnViewValue: {
      flex: 6,
      padding: 10,
      borderBottomColor: "black",
      borderBottomWidth: 1,
    },
    inventoryColumnMainView:{
      margin: 5,
      backgroundColor: "#ffff" ,
      // borderRadius: 20 ,
      borderWidth: 1,
      borderColor: "black"
      // padding: 10
    },
    //inventory style end//

    // product activity logs start //
    productActivityLogContainer: {
      flex:1 ,
      padding: 10 ,
      margin: 10 ,
      backgroundColor: "#dbdada" ,
      borderWidth: 1 ,
      borderRadius: 30
    },
    productActivityLogHeading:{
      fontWeight: "bold" ,
      fontSize: 18 ,
      margin: 10
    },
    productActivityLogMainView:{
      flexDirection: "row" ,
      margin: 5 ,
      backgroundColor: "#ffff" ,
      borderRadius: 30 ,
      padding: 10
    },
    productActivityLogIndex:{
      flex: 2 ,
      margin: 2 ,
      textAlign: "center"
    },
    productActivityLogView:{
      flex: 19 ,
      margin: 2
    },
    productActivityLogViewActionUser:{
      flex:1 ,
      flexWrap: 'wrap' ,
      marginRight: 10
    },
    productActivityLogViewTime:{
      paddingBottom: 5 ,
      marginRight: 4 ,
      marginRight: 4
    },
    tabBtn: {
      // justifyContent: "center",
      paddingHorizontal: 5,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      marginHorizontal: 10,
    },
    tabLabel: {
      paddingHorizontal: 5,
      textAlign: "center",
      // fontWeight: "bold",
      color: "white",
      fontFamily:'Poppins_700Bold',
      fontSize: 19,
      // borderWidth: 1
    },
    statusActive: {
      fontSize: 19,
      color: "#7bfac3",
      // fontStyle: "Poppins_800ExtraBold",
      fontWeight: "bold",
      alignSelf: "center",
      textAlign: "center",
    },
    statusNew: {
      fontSize: 19,
      color: "#eb53fa",
      // fontStyle: "Poppins_800ExtraBold",
      alignSelf: "center",
      textAlign: "center",
      fontWeight: "bold",
    },
   
    gridHeader: {
      fontSize: 19,
      color: "#fff",
      fontFamily: fontFamily.font800,
      opacity: 0.5,
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "center",
      textAlign: "center",
      // borderWidth: 0.5
    },
    // product activity logs end //

    //product detail start //

    //product detail end //

})
export default styles;
